// react 
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// ..........

// scss file
import './solution.scss'
// ..........

// package components
import { Button, Checkbox, Form, Input, Modal, Spin, Tag } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import Table from 'antd/es/table'
import { TextField } from '@mui/material'
// ..........

// child components
import { AppContext } from '../../core/utils/Context'
import { useGlobalState } from '../../Statecontext'
import { NotifyAlert } from '../../components/notify_alert'
import { ZTable2 } from '../../components/table2'
import { serviceConfig } from '../../core/services'
import Breadcrumbs from '../../shared/Breadcrumb'
// ..........

// icons
import attachIcon from '../../assets/images/attach_icon.svg'
import expandIcon from '../../assets/images/expandIcon.svg'
import expandIcon1 from '../../assets/images/expandIcon2.svg'
import filterIcon from '../../assets/images/filter.svg'
import active_filtericon from '../../assets/images/filter_active_icon.svg';
import closeIcon from '../../assets/register/close.png'
import ArticleIcon from '@mui/icons-material/Article';
// ..........

export const SolutionDashboard = () => {

	// form values states
	const [selectedOrgFiles, setSelectedOrgFiles] = useState<any>()
	const [selectedFiles, setSelectedFiles] = useState<any>([])
	const inputFileElement = useRef<any>(null);
	const { formValues, handleChange, handleInitial } = useContext(AppContext)
	const { typeOfSolution, ticDescription, ticContactNumber } = formValues;
	// ..........

	// alerts and modal states
	const [modal2Open, setModal2Open] = useState(false);
	const [notifyOpen, setNotifyOpen] = useState(false)
	const [notifyType, setNotifyType] = useState<any>()
	const [notifyMessage, setNotifyMessage] = useState('')
	var [tableHeader, setTableHeader] = useState<any>()
	// ..........

	// loading state
	const [articlesload, setArtcliesload] = useState(false)
	const [loading, setLoading] = useState(false)
	// ..........

	// limit & filter states
	const [x, setX] = useState(0)
	const [y, setY] = useState(0)
	const { setDashbordarticle, setBreadcrumbPath, filterQuery, page, totalpages, setTotalpages, rowsPerPage, masterData, setFilterQuery } = useGlobalState();
	const [caseIdFilter, setCaseIdFilter] = useState<any>([])
	const [arnFilter, setArnFilter] = useState<any>([])
	const [sessionFilter, setSessionFilter] = useState<any>([])
	const [secStateFilter, setSecStateFilter] = useState<any>({ state: [], arn_nos: [], case_ids: [], section: [] })
	const [filter, setFilter] = useState(false)
	const solutionTableElement = useRef<any>(null);
	const [secondaryFilter, setSecondaryFilter] = useState<any>([])
	const [secFilterCase, setSecFilterCase] = useState('')
	const [checkedItems, setCheckedItems] = useState<string[]>([]);
	const [checkedArnItems, setCheckedArnItems] = useState<string[]>([])
	const [checkedCaseIdItems, setCheckedCaseIdItems] = useState<string[]>([])
	const [checkedSessionItems, setCheckedSessionItems] = useState<string[]>([])
	// ..........

	//  datas states
	const [selarticle, setSelatricle] = useState<any>({})
	const [articles, setArticles] = useState<any>([])
	const [noticeData, setNoticeData] = useState<any>([])
	const [expanded, setExpanded] = useState<boolean>(false)
	const [userTradeMaster, setUserTradeMaster] = useState<any>([])
	const [userStateMaster, setUserStateMaster] = useState<any>([])
	const [userGSTMaster, setUserGSTMaster] = useState<any>([])
	const TypeOfSolution = [
		{ id: 1, value: 'Reply to Notice' },
		{ id: 2, value: 'Personal Hearing' },
		{ id: 3, value: 'Expert Opinion' },
		{ id: 4, value: 'Support Mail' },
		{ id: 5, value: 'Personal Representation before Authorities' },
		{ id: 6, value: 'Drafting of Appeal' },
		{ id: 7, value: 'Assistance for Departmental Audit' },
		{ id: 8, value: 'Others' }
	]
	// ..........

	// reference states
	const navigate = useNavigate()
	// .........

	// filter and header function

	let filConfig: any = {
		"StateFilter": masterData.states,
		"CaseidFilter": masterData.caseIdFilter,
		"ArnFilter": masterData.arnFilter,
		"SessionFilter": masterData.sessionFilter
	}

	var dv = document.createElement("div");
	dv.className = 'secondary_filter'
	dv.style['height'] = '50px';
	dv.style['width'] = '60vw';
	dv.style['background'] = '#ecedf1';
	dv.style['position'] = 'absolute';
	dv.style['zIndex'] = '1000';
	dv.style['display'] = 'flex';
	dv.style['alignItems'] = 'center';
	dv.style['justifyContent'] = 'space-evenly';
	dv.style['padding'] = '0 2%';
	dv.innerHTML = '<span style="border-right:2px solid #d7d7d7;color: var(--text-black, #393938);font-family: Inter;font-size: 2vmin;font-weight: 500 !important;width: 12%;display:flex;justifyContent:center;">State <img class="filterIconQuery" id="StateFilter" style="margin-left:5%;cursor:pointer;" src=' + filterIcon + ' alt="filter icon" /></span><span style="border-right:2px solid #d7d7d7;color: var(--text-black, #393938);font-family: Inter;font-size: 2vmin;font-weight: 500 !important;width: 12%;display:flex;justifyContent:center;">Case ID <img class="filterIconQuery" id="CaseidFilter" style="margin-left:5%;cursor:pointer;" src=' + filterIcon + ' alt="filter icon" /></span><span style="border-right:2px solid #d7d7d7;color: var(--text-black, #393938);font-family: Inter;font-size: 2vmin;font-weight: 500 !important;width: 12%;display:flex;justifyContent:center;">Ref ID <img class="filterIconQuery" id="ArnFilter" style="margin-left:5%;cursor:pointer;" src=' + filterIcon + ' alt="filter icon" /></span><span style="border-right:2px solid #d7d7d7;color: var(--text-black, #393938);font-family: Inter;font-size: 2vmin;font-weight: 500 !important;width: 12%;display:flex;justifyContent:center;">Section <img class="filterIconQuery" id="SessionFilter" style="margin-left:5%;cursor:pointer;" src=' + filterIcon + ' alt="filter icon" /></span>'

	const handlePopupFilter = () => {
		setExpanded(!expanded)
		if (!expanded) {
			tableHeader.appendChild(dv)
			let fi_icons = dv.getElementsByClassName('filterIconQuery')
			for (let ind = 0; ind < fi_icons.length; ind++) {
				fi_icons[ind].addEventListener('click', function (e: any) {
					setX(e.clientX)
					setY(e.clientY)
					setSecondaryFilter(filConfig[e.target.id])
					setSecFilterCase(e.target.id);
					setFilter(true)
				});
			}
		} else {
			let fil = tableHeader.getElementsByClassName('secondary_filter')[0]
			tableHeader.removeChild(fil)
		}
	}

	const filterSolutionDashboard = (storedQuery?: any, masterData?: any) => {
		var solutionfilter: any = [];
		let filterQuerySolution = Object.keys(filterQuery).length > 0 ? filterQuery : storedQuery;

		// Ensure solutionfilter is an array
		solutionfilter = masterData && Array.isArray(masterData)
			? masterData
			: Array.isArray(noticeData)
				? noticeData
				: [];

		if (filterQuerySolution && Object.keys(filterQuerySolution).length > 0) {
			solutionfilter = solutionfilter.filter((notice: any) => {
				let matches = true;

				if (filterQuerySolution.trade_name?.length > 0) {
					matches = matches && filterQuerySolution.trade_name.includes(notice.custom_fields.cf_trade_name);
				}
				if (filterQuerySolution.gst_no?.length > 0) {
					matches = matches && filterQuerySolution.gst_no.includes(notice.custom_fields.cf_gstin);
				}
				if (filterQuerySolution.tax_period?.length > 0) {
					matches = matches && filterQuerySolution.tax_period.includes(notice.custom_fields.cf_tax_period);
				}
				if (filterQuerySolution.status?.length > 0) {
					matches = matches && filterQuerySolution.status.includes(notice.status);
				}
				if (filterQuerySolution.type?.length > 0) {
					matches = matches && filterQuerySolution.type.includes(notice.type);
				}

				return matches;
			});
		}


		if (!filterQuerySolution.gst_no && (!filterQuerySolution.status) && !filterQuerySolution.tax_period && !filterQuerySolution.trade_name && (!filterQuerySolution.type)) {
			setNoticeData(noticeData.length > 0 ? noticeData : masterData)
			solutionfilter = masterData || noticeData;
		}
		setNoticeData(solutionfilter)
		setLoading(false);
	}

	const secFilterChange = () => {
		solutionTableElement.current.setTableQuery(secStateFilter)
		handlePopupFilter()
	}

	const handlecheck = (item: string, secFilter: any) => {

		if (secFilterCase === 'StateFilter') {

			if (checkedItems.includes(item)) {
				setCheckedItems(checkedItems.filter((checkedItem) => checkedItem !== item));
				// setSecStateFilter(secStateFilter.filter((newItem: any) => newItem !== item));
				secStateFilter.state = secStateFilter.state.filter((newItem: any) => newItem !== item)
			} else {
				setCheckedItems([...checkedItems, item]);
				// setSecStateFilter([...secStateFilter, item])
				secStateFilter.state = [...secStateFilter.state, item]
			}

		} else if (secFilterCase === 'ArnFilter') {
			if (checkedArnItems.includes(item)) {
				setCheckedArnItems(checkedArnItems.filter((checkedArnItem) => checkedArnItem !== item));
				secStateFilter.arn_nos = secStateFilter.arn_nos.filter((newItem: any) => newItem !== item)
			} else {
				setCheckedArnItems([...checkedArnItems, item]);
				// secStateFilter.arm_nos = checkedArnItems.concat(',',item)
				secStateFilter.arn_nos = [...secStateFilter.arn_nos, item]
			}

		} else if (secFilterCase === 'CaseidFilter') {
			if (checkedCaseIdItems.includes(item)) {
				setCheckedCaseIdItems(checkedCaseIdItems.filter((checkedCaseIdItem) => checkedCaseIdItem !== item));
				secStateFilter.case_ids = secStateFilter.case_ids.filter((newItem: any) => newItem !== item)
			} else {
				setCheckedCaseIdItems([...checkedCaseIdItems, item]);
				// secStateFilter.case_ids = checkedCaseIdItems.concat(',',item)
				secStateFilter.case_ids = [...secStateFilter.case_ids, item]
			}

		} else if (secFilterCase === 'SessionFilter') {
			if (checkedSessionItems.includes(item)) {
				setCheckedSessionItems(checkedSessionItems.filter((checkedSessionItem) => checkedSessionItem !== item));
				secStateFilter.section = secStateFilter.section.filter((newItem: any) => newItem)
			} else {
				setCheckedSessionItems([...checkedSessionItems, item]);
				// secStateFilter.section = checkedSessionItems.concat(',',item)
				secStateFilter.section = [...secStateFilter.section, item];
			}
		}
	}

	const expandHeader = (ev: any) => {
		ev.preventDefault()
		tableHeader = ev.target.parentElement.parentElement.parentElement
		setTableHeader(tableHeader)
		handlePopupFilter()
	}
	// ...............

	// data get function
	let user_data = JSON.parse(localStorage.getItem('userData') || '')
	const loadGNotices = useCallback((search_data?: any) => {
		setLoading(true)

		let query: any = {}

		// if (filterQuery) {
		// 	filterSolutionDashboard(filterQuery)
		// }
		if (search_data?.reset) {
			setFilterQuery({})
		}

		setBreadcrumbPath('home/services/solutions')
		serviceConfig.get(user_data.role.role_name === "Super Admin" ? "overall_ticket" : "ticket_list", true, query, null).then((data: any) => {
			setNoticeData(data.data.tickets || data.data)
			// setTotalpages(data.count)
			// // Apply filters if they exist
			if (user_data.role.role_name !== "Super Admin" && filterQuery && Object.keys(filterQuery).length > 0) {
				filterSolutionDashboard(filterQuery, data.data);
			} else {
				setLoading(false);
			}
			setLoading(false);

		}, (err: any) => {
			setLoading(false)
			if (err.message === "Request failed with status code 401") {
				navigate('/login')
				localStorage.clear()
				window.location.reload();
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage, filterQuery])



	const get_article = useCallback((id?: any) => {
		setArtcliesload(true)
		const idToUse = '84000349791';
		serviceConfig.get("knowledge_category_article", true, null, { id: idToUse }, false)
			.then((data: any) => {
				setArtcliesload(false)
				setArticles(data.articles)
			}, (err: any) => {
				setArtcliesload(false)
				setNotifyType("error")
				setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
				setNotifyOpen(true)
			})
	}, [])

	useEffect(() => {
		loadGNotices()
		get_article()
	}, [loadGNotices])


	// ................. create task fileupload
	const handleselectedFile = (event: any) => {
		setSelectedOrgFiles(event.target.files)
		let oldFiles = [...selectedFiles]
		Array.from(event.target.files).forEach(fi => {
			oldFiles.push(fi)
		});
		setSelectedFiles(oldFiles)

	};

	const removeFile = (ind: any) => {
		let oldFiles = [...selectedFiles]
		oldFiles.splice(ind, 1)
		setSelectedFiles(oldFiles)
	}
	// ..............

	// Create task manually ....
	const [form] = Form.useForm();


	// Check if all values are not empty and if there are some errors
	const isError = useCallback(
		() =>
			Object.keys({ typeOfSolution, ticDescription, ticContactNumber }).some(
				(name) => (formValues[name].required && !formValues[name].value) || formValues[name].error
			),
		[formValues, typeOfSolution, ticDescription, ticContactNumber]
	)

	const createTaskManually = () => {
		if (!loading) {

			setLoading(true)
			let req_body: any;
			if (selectedFiles.length > 0) {
				req_body = new FormData()
				selectedFiles.forEach((file: any, index: any) => {
					req_body.append('file' + (index + 1), file)
				});
				req_body.append('file_count', selectedFiles.length)
				req_body.append('type', typeOfSolution.value)
				req_body.append('description', ticDescription.value)
				req_body.append('contact_no', ticContactNumber.value)
			} else {
				req_body = {
					'file_count': 0,
					"type": typeOfSolution.value,
					"description": ticDescription.value,
					"contact_no": ticContactNumber.value,
				}
			}

			serviceConfig.post("create_ticket", true, req_body, null, null, selectedFiles.length > 0 ? false : true).then((data: any) => {
				setModal2Open(false)
				setLoading(false)
				loadGNotices()
				handleInitial()
				setSelectedFiles([])
				setNotifyType("success");
				setNotifyMessage("Ticket Created Successfully!");
				setNotifyOpen(true);
			}, (err: any) => {
				setLoading(false)
				setNotifyType("error");
				setNotifyMessage("Something went wrong!");
				setNotifyOpen(true);
			})

		}

	}

	const openpopup = () => {
		setModal2Open(true)
	}
	// .............

	// capitalize convert
	const capitalizeFirstLetter = (str: string) => {
		if (!str) return '';
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	};
	// .............

	// button details props
	const buttondetails = [
		{
			theme: 'theme1',
			type: 'add',
			name: 'Create Task Manually',
			conditions: [],
			function: openpopup
		}
	]
	// ...............

	// table columns and filter requirement props
	const columns = [
		{
			title: <>
				{expanded ?
					<img alt='expand icon' src={expandIcon1} onClick={e => expandHeader(e)} style={{ cursor: "pointer", position: 'relative', left: '-74%', width: '3vmin' }} />
					:
					<img alt='expang icon' src={expandIcon} onClick={e => expandHeader(e)} style={{ cursor: "pointer", position: 'relative', left: '-74%', width: '3vmin' }} />
				}
				<span style={{ position: 'relative', top: '-10%' }}>Task ID</span></>,
			dataIndex: 'id',
			width: 110,
			render(value: any, record: any, index: any) {
				// return value.gst_no
				if (record.tags.length > 0) {
					let link = "/home/" + (record.tags[0]) + "/view-solution-details";
					return <Link onClick={() => { localStorage.setItem('taskCreatedDate', record.created_at) }} to={link}>{value}</Link>
				} else {
					return value
				}

			},
			filterSearch: true,
		},
		{
			title: 'Trade Name',
			dataIndex: 'custom_fields',
			maxWidth: 120,
			key: 'trade_name',
			filters: masterData.trades
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return ({
						text: item,
						value: item,
					})
				}),
			filterIcon: () => filterQuery &&
				filterQuery.trade_name ?
				<img src={active_filtericon} alt='filter_active_icon' /> :
				<img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				return (
					<span
						style={{ whiteSpace: 'pre-wrap' }}>
						{value.cf_trade_name || '--'}
					</span>
				)
			},
			filterSearch: true,
		},
		{
			title: 'GSTIN',
			dataIndex: 'custom_fields',
			maxWidth: 170,
			key: 'gst_no',
			filters: masterData.gst
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return ({
						text: item,
						value: item,
					})
				}),
			filterIcon: () => filterQuery && filterQuery.gst_no ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				// return value.gst_no
				if (value.cf_gstin && record.tags.length > 0) {
					let link = "/home/" + record.tags[0] + "/view-gst-details"
					return <span style={{ whiteSpace: 'pre-wrap' }}>{value.cf_gstin || '--'}</span>
				} else {
					return value.cf_gstin || '--'
				}

				// filterSearch: true,

			},
			filterSearch: true,
		},
		// {
		// 	title: 'Tax Period',
		// 	dataIndex: 'custom_fields',
		// 	key: 'tax_period',
		// 	// filters: [
		// 	// 	{
		// 	// 		text: 'Jul 2017 - Mar 2018',
		// 	// 		value: '2017-18',

		// 	// 	},
		// 	// 	{
		// 	// 		text: 'Apr 2018 - Mar 2019',
		// 	// 		value: '2018-19',

		// 	// 	},
		// 	// 	{
		// 	// 		text: 'Apr 2019 - Mar 2020',
		// 	// 		value: '2019-20',

		// 	// 	},
		// 	// 	{
		// 	// 		text: 'Apr 2020 - Mar 2021',
		// 	// 		value: '2020-21',

		// 	// 	},
		// 	// 	{
		// 	// 		text: 'Apr 2021 - Mar 2022',
		// 	// 		value: '2021-22',

		// 	// 	},
		// 	// 	{
		// 	// 		text: 'Apr 2022 - Mar 2023',
		// 	// 		value: 'Apr 2022 - Mar 2023',

		// 	// 	}
		// 	// ],
		// 	// filterIcon: () => <img src={filterIcon} alt='filter icon' />,
		// 	render(value: any, record: any, index: any) {
		// 		return value ? value.cf_tax_period : '--'
		// 	},
		// 	// filteredValue: loadedValues.tax_period || null,
		// },
		// {
		// 	title: 'Notice Date',
		// 	dataIndex: 'custom_fields',
		// 	// filterDropdown: () => (
		// 	// 	<div style={{ display: 'flex', flexDirection: "column", padding: 10, gap: "10%" }}>
		// 	// 		<DatePicker onChange={(date: any) => setFromDate(date)} style={{ marginBottom: 10 }} placeholder='From Date' />
		// 	// 		<DatePicker onChange={(date: any) => setToDate(date)} placeholder="To Date" /><div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 10 }}>
		// 	// 			<Button className='filter-secondary-btn' style={{ backgroundColor: "none", border: "none" }}>Reset</Button>
		// 	// 			<Button className='filter-primary-btn'>Ok</Button>

		// 	// 		</div>
		// 	// 	</div>
		// 	// ),
		// 	// filterIcon: () => <img src={filterIcon} alt='filter icon' />,
		// 	render(value: any, record: any, index: any) {
		// 		return value.cf_notice_date || '--'
		// 	}
		// },
		// {
		// 	title: 'Due Date',
		// 	dataIndex: 'custom_fields',
		// 	// filterDropdown: () => (
		// 	// 	<div style={{ display: 'flex', flexDirection: "column", padding: 10, gap: "10%" }}>
		// 	// 		<DatePicker onChange={(date: any) => setFromDate(date)} style={{ marginBottom: 10 }} placeholder='From Date' />
		// 	// 		<DatePicker onChange={(date: any) => setToDate(date)} placeholder="To Date" /><div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 10 }}>
		// 	// 			<Button className='filter-secondary-btn' style={{ backgroundColor: "none", border: "none" }}>Reset</Button>
		// 	// 			<Button className='filter-primary-btn'>Ok</Button>

		// 	// 		</div>
		// 	// 	</div>
		// 	// ),
		// 	// filterIcon: () => <img src={filterIcon} alt='filter icon' />,
		// 	render(value: any, record: any, index: any) {
		// 		return value.cf_due_date_to_reply_to_notice || '--'

		// 	}
		// },
		// {
		// 	title: 'Description',
		// 	dataIndex: 'description_text',
		// 	maxWidth: 100,
		// 	render(value: any, record: any, index: any) {
		// 		// function extractContent(s: any) {
		// 		// 	var span = document.createElement('span');
		// 		// 	span.innerHTML = s;
		// 		// 	return span.textContent || span.innerText;
		// 		// };
		// 		// function formatDesc(descs: any) {
		// 		// 	let desc_data: any = []
		// 		// 	descs.forEach((des: any) => {
		// 		// 		desc_data.push(des.split(' ').pop())
		// 		// 	});
		// 		// 	return desc_data.join(', ')
		// 		// }
		// 		if (value) {
		// 			// return extractContent(value)
		// 			return (
		// 				<><span style={{ whiteSpace: 'break-spaces' }}>{value}</span></>
		// 			)
		// 		} else {
		// 			return '--'
		// 		}

		// 	},
		// },
		{
			title: 'Type',
			dataIndex: 'type',
			filters: [
				{
					text: 'Reply to Notice',
					value: 'Reply to Notice',
				},
				{
					text: 'Personal Hearing',
					value: 'Personal Hearing',
				},
				{
					text: 'Expert Opinion',
					value: 'Expert Opinion',
				},
				{
					text: 'Support Mail',
					value: 'Support Mail',
				},
				{
					text: 'Personal Representation before Authorities',
					value: 'Personal Representation before Authorities',
				},
				{
					text: 'Drafting of Appeal',
					value: 'Drafting of Appeal',
				},
				{
					text: 'Assistance for Departmental Audit',
					value: 'Assistance for Departmental Audit',
				},
				{
					text: 'Others',
					value: 'Others',
				}
			],
			filterIcon: () => filterQuery && filterQuery.type ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			filterSearch: true,
			// filteredValue: loadedValues.type || null,

		},
		{
			title: 'Status',
			dataIndex: 'status',
			width: 150,
			filters: [
				{
					text: 'Open',
					value: 2,
				},
				{
					text: 'Pending',
					value: 3,
				},
				{
					text: 'Resolved',
					value: 4,
				},
				{
					text: 'Closed',
					value: 5,
				},
				{
					text: 'Primary Analysis Done',
					value: 6,
				},
				{
					text: 'Success Analysis Matrix Done',
					value: 7,
				},
				{
					text: 'Draft Ready - Payment Pending',
					value: 8,
				},
				{
					text: 'Proforma Invoice - Payment Pending',
					value: 9,
				},
				{
					text: 'Draft Ready for Download',
					value: 10,
				},
				{
					text: 'Pending with department',
					value: 11,
				}
			],
			filterIcon: () => filterQuery && filterQuery.status ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				if (value === 2) {
					return <Tag color='error' style={{ borderRadius: "25px", padding: '3px 10px' }} >{'Open'}</Tag>
				}
				if (value === 3) {
					return <Tag color='warning' style={{ borderRadius: "25px", padding: '3px 10px' }} >{'Pending'}</Tag>
				}
				if (value === 6) {
					return <Tag color='warning' style={{ borderRadius: "25px", padding: '3px 10px' }} >{'Primary Analysis Done'}</Tag>
				}
				if (value === 7) {
					return <Tag color='warning' style={{ borderRadius: "25px", padding: '3px 10px' }} >{'Success Analysis Matrix Done'}</Tag>
				}
				if (value === 8) {
					return <Tag color='warning' style={{ borderRadius: "25px", padding: '3px 10px' }} >{'Draft Ready - Payment Pending'}</Tag>
				}
				if (value === 9) {
					return <Tag color='warning' style={{ borderRadius: "25px", padding: '3px 10px' }} >{'Proforma Invoice - Payment Pending'}</Tag>
				}
				if (value === 10) {
					return <Tag color='warning' style={{ borderRadius: "25px", padding: '3px 10px' }} >{'Draft Ready for Download'}</Tag>
				}
				if (value === 11) {
					return <Tag color='warning' style={{ borderRadius: "25px", padding: '3px 10px' }} >{'Pending with department'}</Tag>
				}
				if (value === 4 || value === 5) {
					return <Tag color='success' style={{ borderRadius: "25px", padding: '3px 10px' }} >{value === 4 ? 'Resolved' : 'Closed'}</Tag>
				}
				return '--'
			},
			filterSearch: true,
			// filteredValue: loadedValues.status || null,
		},
		Table.SELECTION_COLUMN,

	];
	// .............

	return (
		<div id='solution-dashboard-container' className="gst-container">
			<NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>

			<Modal maskStyle={{ padding: '8px !important' }} bodyStyle={{ maxHeight: '120px', overflowY: 'auto' }} closeIcon={false} open={filter} onOk={() => { secFilterChange(); setFilter(false) }} onCancel={() => setFilter(false)} cancelText="Reset" style={{ top: y, left: x, height: 220, maxHeight: 320, width: 'max-content', position: "absolute" }} width={'15%'} >
				{secondaryFilter.map((item: any, index: any) => (
					item && <div key={item}>
						<Checkbox value={item} onChange={() => { handlecheck(item, secondaryFilter) }}
							checked={checkedItems.includes(item) || checkedArnItems.includes(item) || checkedCaseIdItems.includes(item) || checkedSessionItems.includes(item)}
						/>
						<span style={{ marginLeft: 10 }} >{item}</span>
					</div>
				))}

			</Modal>
			<Modal
				title="Create task manually"
				onCancel={() => { setModal2Open(false); handleInitial(); setSelectedFiles([]) }}
				width={'35%'}
				className='action-popup'
				centered
				style={{ color: "#031742" }}
				open={modal2Open}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<span className='type-field-lable' style={{ fontSize: '2vmin ' }}>Type of Solution Sought</span>
				<TextField
					fullWidth
					focused
					select
					SelectProps={{ native: true, }}
					variant='outlined'
					name="typeOfSolution"
					value={typeOfSolution.value}
					onBlur={handleChange}
					onChange={handleChange}
					defaultValue='Reply to Notice'
					autoComplete='off'
					style={{ margin: '2% 0' }}
				>
					<option value='' disabled>...</option>
					{TypeOfSolution.map((type: any) =>
						<option key={type.id} value={type.value}>{type.value}</option>
					)}
				</TextField>
				<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
					<Form.Item
						name="ticDescription"
						label="Description"
						style={{ minHeight: 200 }}
						className='form_item one'
					>
						{ticDescription.error && <span className="gst-err-msg">{ticDescription.error}</span>}
						<div style={{ border: "1px solid #00000021", padding: 10 }}>
							<TextArea
								maxLength={100}
								style={{
									height: 120,
									resize: 'none',
									padding: 0,
									border: "none"
								}}
								bordered={false}
								name="ticDescription"
								value={ticDescription.value}
								onChange={handleChange}
								onBlur={handleChange}
							/>
							<hr />
							<input
								ref={inputFileElement}
								onChange={handleselectedFile}
								multiple
								type="file"
								style={{ display: 'none' }} />
							<span
								onClick={() => inputFileElement.current.click()}
								className='attach_file_line'
								style={{
									color: '#4472C4',
									fontSize: '1.8vmin',
									fontWeight: 400,
									textDecoration: 'underline',
									cursor: 'pointer'
								}}>
								<img
									src={attachIcon}
									className='attach_file_img'
									style={{
										marginRight: '5px'
									}}
									height={'13vmin'}
									alt='attach' />
								Attach files (Optional)
							</span>
							<div
								style={{
									display: 'flex',
									marginTop: '10px',
									width: '100%',
									flexWrap: 'wrap'
								}}>
								{selectedFiles.map((file: any,
									index: any) =>
									<span
										key={file.name}
										style={{
											width: '27.9%',
											padding: '1% 2%',
											backgroundColor: '#FCE7D9',
											border: '0.5px solid #E96919',
											marginRight: '1%',
											marginBottom: '1%',
											justifyContent: 'space-between',
											wordBreak: 'break-all',
											borderRadius: '5px',
											display: 'flex',
											alignItems: 'center'
										}}>{file.name}
										<img
											alt="close-icon"
											onClick={() => removeFile(index)}
											src={closeIcon}
											style={{
												marginLeft: '5px',
												cursor: 'pointer'
											}}
											height={'10vmin'} />
									</span>)}
							</div>
						</div>
					</Form.Item>
					<Form.Item
						name="ticContactNumber"
						messageVariables={{ name: 'name' }}
						label="Contact mobile num"
						className='form_item two'
					>
						{ticContactNumber.error && <span className="gst-err-msg">{ticContactNumber.error}</span>}
						<Input type='text'
							name="ticContactNumber"
							value={ticContactNumber.value}
							onChange={handleChange}
							onBlur={handleChange}
							style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0 }} />
					</Form.Item>
					<Form.Item className='form_item'>
						<Button
							htmlType="submit"
							id='popup-submit-btn'
							className='create-popup-btn'
							disabled={isError()}
							loading={loading}
							style={{
								background: "#424B70",
								color: "white",
								border: "none",
								width: "70%",
								height: "6vh",
								marginLeft: '15%',
								fontSize: '2.3vmin'
							}}
							onClick={() => createTaskManually()}>
							{!loading && <>Create Task</>}
							{loading && <>Loading...</>}
						</Button>
					</Form.Item>
				</Form>
			</Modal>
			<div className='crumbs_wrap' style={{ marginTop: '1%' }}>
				<Breadcrumbs />
			</div>
			<div className='sol_table_wrap'>
				<ZTable2
					label={'Solution Dashboard'}
					button={buttondetails}
					columns={columns}
					expand={true}
					hideSelect={true}
					ref={solutionTableElement}
					tabledata={noticeData}
					loading={loading}
				/>
			</div>
			<div className='success_stories_wrap'>
				<div className='updates_head_wrap'>
					<span># Success Stories</span>
				</div>
				<div className='updates_content_wrap'>
					{loading ?
						<Spin spinning={true} size='default' style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
						</Spin> :
						<>
							{articles?.length !== 0 ? articles?.map((item: any, index: any) => (
								<div className='content_row' key={item.subject}
									onClick={() => {
										navigate('/home/latestupdates')
										setDashbordarticle(item)
									}}>
									<span className='content_subject'>
										<ArticleIcon className='icon' />
									</span>
									<span className='content_desc'>{capitalizeFirstLetter(item.title)}
									</span>
								</div>)) :
								<span style={{ color: 'rgba(0, 0, 0, 0.45)', marginTop: '8vh' }}>No data</span>
							}
						</>
					}
				</div>
			</div>
		</div >
	)
}