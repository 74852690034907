// scss file
import './latest_search.scss';
// .........

// package components
import {  useEffect, useRef, useState } from 'react';
// .........

// child components 
import {  Spin } from 'antd';
import { NotifyAlert } from '../../components/notify_alert';
// .......

// icons 
import ArticleIcon from '@mui/icons-material/Article';
// .....

export const Searchdata = ({ anchorEl, setAnchorEl, searchdetails, searchloading, Selatricle }: any) => {


    // reference states
    const searchRef = useRef<any>(null);
    // .........



    const capitalizeSubstring = (str: string) => {
        if (!str) return '';
        const capitalized = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        return capitalized.length > 54 ? capitalized.substring(0, 54) + '...' : capitalized;
    };

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (searchRef.current && !searchRef.current?.contains(event.target)) {
                setTimeout(() => {
                }, 500);
                setAnchorEl(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchRef]);

    return (
        <>
            {anchorEl && <div className='latest_search_cover fade-out' ref={searchRef}>
                <div className='head_box'>
                    <span className='head_line'>Search Suggestions</span>
                </div>
                <div className='notify_content_cover'>
                    {searchloading &&
                        <Spin spinning={true} size='default' style={{ maxWidth: '100%', position: 'absolute', display: 'flex', marginLeft: '14vw', marginTop: '20vh' }}>
                        </Spin>
                    }
                    {searchdetails?.length !== 0 ? searchdetails?.map((item: any, index: any) => (
                        <div className='content_row' key={item.subject} onClick={() => { Selatricle(item) }}>
                            <span className='content_subject'>
                                <ArticleIcon className='icon' />
                            </span>
                            <span className='content_desc'>{capitalizeSubstring(item.title)}</span>
                        </div>)) :
                        <span style={{ color: 'rgba(0, 0, 0, 0.45)', margin: 'auto' }}>No results found!</span>
                    }
                </div>
            </div >}
        </>
    )
}