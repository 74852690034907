// react 
import { useNavigate } from "react-router-dom";
// ..........

// scss file
import "./index.scss"
// ..........

// package components
import { Typography } from '@mui/material';
import { ArrowLeft } from '@mui/icons-material'
// ..........

// icons
import zenLogo from '../../../assets/images/ZCWhiteLogo.jpg'
// ..........

export const PrivacyPolicy = () => {

    // reference states
    const navigate = useNavigate();
    // ..........

    return (
        <div id="policy_container">
            <div className="header">
                <div className="logobox">
                    <img alt="logo" src={zenLogo} style={{ height: '100px', width: '200px' }} />
                </div>
                <div className="linkbox">
                    <a href="https://www.zentaxclinic.com/" target="_blank" > www.zentaxclinic.com </a>
                </div>
            </div>
            <div className='body'>
                <div className='body_header'>
                    <span onClick={() => navigate(-1)}><ArrowLeft /> <p style={{ fontSize: '2vmin' }}>Back</p></span>
                    <p className='headline'>Privacy Policy</p>
                </div>
                {/* <Typography className='policy_data'>
                    Access to the website https://Zen Tax Clinic.com/ is provided by Zen Tax Clinic Technologies Pvt Ltd. Users and the Website have a legal agreement that is reflected in these Terms of Use .In the context of these Terms of Use, “You,” “Your,” or “User” shall refer to any natural person who uses or visits the Website to obtain products, engage in transactions, or seek out information and/or who has consented to use the Website by providing registration information during registration as a Registered User through the use of digital platforms. Without registering on the website, the User can browseZen Tax Clinic. “Our,” “We,” and “Us” refer to Zen Tax Clinic. Any of the services provided by Us will be bound by the rules, guidelines, policies, terms, and conditions associated with such Services, and they shall abide by these Terms of Use if You use the Website to make reservations for services, purchases of services, information requests, sales and purchases of any personal, posting of questions and answers and related content on theZen Tax Clinic page, or otherwise.
                </Typography>
                <Typography className='policy_data'>
                    Parts of these Terms of Use may be changed, modified, added, or removed at any time without prior notice, at our sole discretion. You are responsible for routinely reviewing these Terms of Use for any modifications or changes. Users making a booking on the Website shall be referred to as the “potential prospects”.
                </Typography>
                <Typography className='policy_data'>
                    You will be required to submit facts about yourself, such as your name, contact information, and personal information. You should make sure that the data you provide is accurate, comprehensive, and truthful. In order for us to deliver and offer you with the services effectively, you should also promptly notify us of any changes to the data that you have provided by updating your information.
                </Typography>
                <Typography className='policy_data'>
                    It is crucial to keep in mind that any remarks made in our public areas or forums may be archived on our website. As a result, it is crucial that you exercise care and common sense when disseminating personal information about yourself and others. In particular, you must not disseminate Sensitive, proprietary, or classified information in your comments to our public sections/forums.
                </Typography>
                <Typography className='policy_data'>
                    PLEASE READ THE TERMS OF USE CAREFULLY PRIOR TO PROCEEDING AS YOUR ACCESS, BROWSING, OR USE OF THE SITE SIGNIFIES YOUR ACCEPTANCE OF ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE.
                    <br />PRIVACY POLICY
                </Typography>
                <Typography className='policy_data'>
                    You agree to the gathering and use of information pertaining to this policy if you want to use our Service. The Personal Data we gather is used to deliver and enhance the Service. Your data may be used or shared by us with anyone. Cookies are a little portion of data that a web server stores on a web browser in order to translate it for subsequent logins from that browser. Depending on the options requested, Zen Tax Clinicuses cookie and capturing technology. Cookies and other capturing technologies won’t be used to gather any personal information.
                </Typography> */}

                <Typography className='policy_data'>
                    At Zen Tax Clinic, we are committed to protecting your personal data and respecting your privacy. This Privacy Policy ("Policy") is a legally binding document outlining the practices and principles governing the collection, use, and safeguarding of your personal and confidential information when you use the Zen Tax Clinic online portal ("Portal"). The Portal is owned and operated by Sherlock Holmes Tax Solutions Private Limited ("Company"). By registering and using our Portal, you confirm your understanding and agreement to this Policy.
                </Typography>
                <p className='sub-heading'>
                    1. Personal Information Collected
                </p>
                <p className='sub-heading'>
                    1.1. User-Provided Information:
                </p>
                <Typography className="policy_data">
                    To provide you with tax-related services, the Portal may request and collect specific personal and confidential information, including but not limited to your name, contact details, Tax Identification Number (TIN), GST Portal Username, and Password.
                </Typography>
                <p className='sub-heading'>1.2. IP Address and Cookies:</p>
                <Typography className='policy_data'>
                    We may collect your IP address for the safe use of the site. Additionally, we may use cookies for ease of use and to enhance your user experience. You can manage your cookie preferences through your browser settings.
                </Typography>
                <p className='sub-heading'>2. Use of Personal Information</p>
                <p className='sub-heading'>2.1. Tax Litigation Solutions:</p>
                <Typography className='policy_data'>
                    The primary purpose of collecting your personal information is to provide you with tax litigation solutions, including drafting replies to notices received from tax authorities and assisting you in managing your GST Notices through the Portal.
                </Typography>
                <p className='sub-heading'>2.2. Third-Party Service Providers:</p>
                <Typography className='policy_data'>
                    We may utilize third-party service providers, including but not limited to Google, Google Maps, AWS, Freshworks, WhatsApp, Zoho, and RazorPay, to facilitate various aspects of our services. These third parties may collect and process your personal information as necessary to provide their services.
                </Typography>
                <p className='sub-heading'>3. Limitations and Disclaimers</p>
                <p className='sub-heading'>3.1. Limitations on Data Retrieval:</p>
                <Typography className='policy_data'>
                    Please note that data retrieval from the GST portal may be subject to connectivity issues at the time of retrieval, and the Portal or Company is not responsible for any data that cannot be retrieved.
                </Typography>
                <p className='sub-heading'>3.2. Liability and User Responsibility:</p>
                <Typography className='policy_data'>
                    You understand and agree that the services provided by the Portal are rendered by experts, professionals, and consultants affiliated with the Company. Any action taken based on the information and guidance provided by these professionals is your responsibility.
                </Typography>
                <p className='sub-heading'>3.3. Tax Representation</p>
                <Typography className='policy_data'>
                    The Portal may facilitate the appointment of a Tax Representation Professional for your convenience. Please be aware that the services provided by the Tax Representation Professional are in their personal capacity, and the Company is not liable for their actions.
                </Typography>
                <p className='sub-heading'>3.4. Confidentiality:</p>
                <Typography className='policy_data'>
                    While using the Portal, you may enter confidential information, which may not be exclusively yours. By using the Portal, you confirm that you have the proper authority to disclose such information, and any shared confidential information is not the responsibility of the Portal or the Company.
                </Typography>
                <p className='sub-heading'>4. Consent and Registration</p>
                <p className='sub-heading'>4.1. Consent:</p>

                <Typography className='policy_data'>
                    By registering and using the Portal, you expressly consent to the collection, use, and processing of your personal and confidential information as outlined in this Privacy Policy.
                </Typography>
                <p className='sub-heading'>4.2. Confirmation:</p>
                <Typography className='policy_data'>
                    You confirm that you have read and understood this Privacy Policy and agree to abide by its terms and conditions.
                </Typography>
                <p className='sub-heading'>5. Legal Compliance and Governing Law</p>
                <Typography className='policy_data'>
                    This Policy is governed by and construed in accordance with the laws of India. Any disputes arising under or in connection with this Policy shall be subject to the exclusive jurisdiction of the courts in Coimbatore Jurisdiction, India, irrespective of the location of the user or taxpayer.
                </Typography>
                <p className='sub-heading'>6. Contact Us</p>
                <Typography className='policy_data'>
                    If you have any questions, concerns, or requests related to this Privacy Policy or your personal information, please contact us at solutions@zentaxclinic.com.
                </Typography>
                <Typography className='policy_data'>
                    This Privacy Policy is subject to periodic updates, and any changes will be communicated to you through the Portal. It is your responsibility to review and stay informed about the latest version of this Policy.
                </Typography>
                <Typography className='policy_data'>
                    Last Updated: 25th September, 2023
                </Typography>
                <Typography className='policy_data' style={{ paddingTop: '5%' }}>
                    Sherlock Holmes Tax Solutions Private Limited
                </Typography>
                <Typography className='policy_data'>
                    42, Shanmuga Nagar, Ponnaiyah Raja Puram, Coimbatore – 641 001.
                </Typography>

            </div>
        </div>
    )
}