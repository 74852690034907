// react 
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// ..........

// scss file
import '../login/login.scss';
// ..........

// package components
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
// ..........

// child components
import { useGlobalState } from '../../../Statecontext';
import { NotifyAlert } from '../../../components/notify_alert';
// ..........

// icons
// ..........

interface Proptype {
    data: any;
    setState: any;
}

export const Logintype: React.FC<Proptype> = ({ data, setState }) => {

    // form values states
    const [usertype, setUsertype] = useState<string>('Certified Partner');
    // ..........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    // ..........

    // loading state
    // ..........

    // data states
    // ..........

    // reference states
    const { setVideopopup, setUserdata, } = useGlobalState();
    const navigate = useNavigate()
    // ..........

    useEffect(() => {

        if (localStorage.getItem('authKey') !== null) {
            navigate('/home/gst-dashboard');
        }

    }, []);

    // login submission function
    const userType: any = [
        'Certified Partner',
        'Ph Partner',
    ]

    const handlechange = (event: any) => {
        const { value } = event.target;
        setUsertype(value)
    };


    const typesubmit = (type: any) => {
        if (type === 'Certified Partner') {
            localStorage.setItem('ph_login', JSON.stringify(false))
            localStorage.setItem('authKey', data.auth_key)
            setUserdata(data.user_data)
            localStorage.setItem('userData', JSON.stringify(data.user_data))
            const is_cf = data?.user_data?.is_cf ?? false; // or any default value
            localStorage.setItem('is_cf', JSON.stringify(is_cf));
            const cf_code = data?.user_data?.cf_code ?? ''; // or any default value
            localStorage.setItem('cf_code', JSON.stringify(cf_code))
            localStorage.setItem('userRole', data.user_data.role.role_name)
            localStorage.setItem('syncedCount', JSON.stringify(data.user_data.sync_count))
            localStorage.setItem('ph_request', JSON.stringify(data.user_data.ph_request))

            if (data.is_gst_available === true) {
                navigate('/home/gst-dashboard');
            } else {
                navigate('/home/allgstin');
                if (data.user_data.first_login === true) {
                    setVideopopup(true)
                }
            }
        } else if (type === 'Ph Partner') {
            localStorage.setItem('ph_login', JSON.stringify(true))
            localStorage.setItem('authKey', data.auth_key)
            setUserdata(data.user_data)
            localStorage.setItem('userData', JSON.stringify(data.user_data))
            localStorage.setItem('is_cf', JSON.stringify(data.user_data.is_cf))
            localStorage.setItem('cf_code', JSON.stringify(data.user_data.cf_code))
            localStorage.setItem('userRole', data.user_data.role.role_name)
            localStorage.setItem('syncedCount', JSON.stringify(data.user_data.sync_count))
            localStorage.setItem('ph_request', JSON.stringify(data.user_data.ph_request))

            if (data.is_gst_available === true) {
                navigate('/home/phdashboard');
            }
        }
    }
    // ..........

    return (
        <>
            <div className="container-login role-contain">
                <h4 className="terminal-window-mainline">Login as</h4>
                <div className="fields">
                    <div className="row">

                        <div className="col-sm-12 radio-group" >
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={userType[0]} // Set the default value to the first item in the array
                                name="radio-buttons-group"
                                value={usertype}
                                onChange={handlechange}
                            >
                                {userType.map((type: any) => (
                                    <FormControlLabel
                                        key={type}
                                        value={type}
                                        control={<Radio
                                            sx={{
                                                color: '#424B70',
                                                '&.Mui-checked': {
                                                    color: '#424B70',
                                                },
                                            }} />}
                                        label={type}
                                        sx={{
                                            color: '#000', // Change label color here
                                        }}
                                    />
                                ))}
                            </RadioGroup>
                        </div>

                        <div className="col-sm-12  forgot-link">
                            <Link className="terminal-link" to='/login' onClick={() => setState(false)}>Back</Link>
                        </div>

                        <div className="col-sm-12 input-group">
                            <button type='submit' className="teminal-primary-btn" onClick={() => { typesubmit(usertype) }}>Continue</button>
                        </div>
                    </div>
                </div>

            </div >

            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}