// react 
import * as React from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// ..........

// scss file
import './user-profile.scss';
// ..........

// package components
import { Dialog, DialogContent, DialogTitle, FormControlLabel, RadioGroup, StepLabel, TextField, FormHelperText, InputAdornment, OutlinedInput, ThemeProvider, Avatar, } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Step from '@mui/material/Step';
import { StepIconProps } from '@mui/material/StepIcon';
import Stepper from '@mui/material/Stepper';
import { pink } from '@mui/material/colors'
import { alpha, createTheme, styled } from '@mui/material/styles';
import { Button, Form, Menu, Modal, Radio, Spin, Tag, Tooltip } from 'antd';
import copy from 'clipboard-copy';
import Switch from '@mui/material/Switch'
import { Dropdown } from 'antd';
// ..........

// child components
import { NotifyAlert } from '../../components/notify_alert';
import { serviceConfig } from '../../core/services';
import { useGlobalState } from '../../Statecontext';
import { format } from 'date-fns';
import Table from 'antd/es/table';
// ..........

// icons 
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import primary from '../../assets/images/primary.png';
import secondary from '../../assets/images/secondary.png';
import { AppContext } from '../../core/utils/Context';
import SendIcon from '@mui/icons-material/Send';
import Breadcrumbs from '../Breadcrumb';
import EditIcon from '@mui/icons-material/Edit';
import { Utils } from '../../core/utils/utils';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
// ..........

const steps = ['Primary', 'Secondary'];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


// custom theming function
const inputtheme = createTheme({
    palette: {
        primary: {
            main: '#9a9999',
        }
    },
});
// ..........


export const UserProfile = ({ open, setOpen, refresh }: any) => {

    // form values states
    const [form] = Form.useForm();

    const mobile_verfy = localStorage.getItem('')
    const [pincode, setPincode] = useState('')
    const [pinchanged, setpinChanged] = useState(false)
    const [user_name, setUsername] = useState('')
    const [user_email, setUserEmail] = useState('')
    const [user_mobile, setUserMobile] = useState('')
    const [user_id, setUserId] = useState('')
    const [new_password, setPassword] = useState('**********')

    const [sec_email, setSecEmail] = useState<any>('')
    const [sec_mobile, setSecMobile] = useState('')
    const [err_pincode, setErrPincode] = useState('')
    const [err_user_name, setErrUserName] = useState('')
    const [err_user_mobile, setErrUserMobile] = useState('')
    const [err_user_email, setErruserEmail] = useState('')

    const [err_sec_email, setErrSecEmail] = useState('')
    const [err_sec_mobile, setErrSecMobile] = useState('')

    const [cf_code, setCf_code] = useState('')

    const [cgstcheck, setCgstcheck] = useState('Daily');
    const [sgstcheck, setSgstcheck] = useState('Daily');
    const [gstcheck, setGstcheck] = useState('Fresher');

    const [location, setLocation] = useState<any>([]);
    const [locationdetails, setLocationdetails] = useState<any>(null)
    const [showaddress, setShowaddress] = useState(false);

    const [selectedValue, setSelectedValue] = React.useState('Monday,Wednesday,Friday');

    const [popuptype, setPopuptype] = useState('')
    const [usertype, setUsertype] = useState<string>('Taxpayer');
    const [tempUsertype, setTempUsertype] = useState<string>('Taxpayer');

    const { formValues, handleChange, handleInitial } = useContext(AppContext);
    const [otp, setOtp] = useState<string[]>(['', '', '', '']);
    const [err_otp, setErr_otp] = useState('');

    const { fullname, email, experience, address, ticContactNumber, organisation_name, qualification, team_size, pin_code, city, alt_contactnumber } = formValues;
    // ..........

    // alerts and modal states
    const [detailopen, setDetailopen] = useState(false);

    const [selectedrow, setSelectedrows] = useState([])

    const [phpopup, setPhpopup] = useState(false)
    const [cfpopup, setCfpopup] = useState(false)

    const [modaltype, setModaltype] = useState('Role')
    const [fieldType, setFieldType] = useState<any>()
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('');

    const [otpmodal, setOtpmodal] = useState(false);

    const [profilemodal, setProfilemodal] = useState(false);
    // ..........

    // loading state    
    const [loading, setLoading] = useState(false)
    const [phloading, setPhloading] = useState(false)
    const [cfloading, setCfloading] = useState(false)
    const [clearloading, setClearloading] = useState(false);
    const [detailoading, setDetailoading] = useState(false)
    const [syncloading, setSyncloading] = useState(false)
    const [detailloading, setDetailloading] = useState(false);
    // ....

    // reference states 
    const [checked, setChecked] = React.useState(true);
    const { userdata, setUserdata, setBreadcrumbPath, setGstSyncModal, setProgres, progres, setSyncDetails, reportmodal, setreportModal, syncDetails, setPasswordopen } = useGlobalState();
    const [sourcecf, setSourceCf] = useState(false)
    const [accept_cf, setCf] = useState(false)
    const [activeStep, setActiveStep] = React.useState(0);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});
    const navigate = useNavigate()
    const { getSyncCount, handlesync } = Utils();
    const userData = JSON.parse(localStorage.getItem('userData') || '{}')
    const ph_request_str = localStorage.getItem('ph_request');
    const ph_request = ph_request_str && ph_request_str !== 'undefined' ? JSON.parse(ph_request_str) : {};
    // ..........

    // data states
    let [syncId, setSyncId] = useState<any>(null)
    const phlogin = JSON.parse(localStorage.getItem('ph_login') || '{}')
    let user_data = JSON.parse(localStorage.getItem('userData') || '')
    const [detailSource, setDetailsource] = useState<any>([]);
    const [userdetails, setUserdetails] = useState<any>({})
    const user = [
        {
            groupname: 'Account details',
            fields: [
                {
                    label: 'Account created',
                    value: userdetails.created_at ?? '--'
                },
                {
                    label: 'Last sync',
                    value: userdetails.last_sync ?? '--'
                },
                {
                    label: 'User type',
                    value: userdetails.user_type,
                },
                {
                    label: 'Mail frequent',
                    value: userdetails.email_option === false ? 'Monday,Wednesday,Friday' : 'Daily',
                },
                {
                    label: 'Mail verification',
                    value: userdetails.mail_verified ? 'Verified' : 'Not verified'
                },
                {
                    label: 'Mobile verification',
                    value: userdetails.mobile_verified ? 'Verified' : 'Not verified'
                },
                {
                    label: 'Password verification',
                    value: userdetails.password_status ? 'Verified' : 'Not verified'
                },
                {
                    label: 'Sync all while daily login',
                    value: userdetails.daily_login_sync
                },
            ]
        },
        {
            groupname: 'Personal details',
            fields: [
                {
                    label: 'User name',
                    value: userdetails.user_name,
                },
                {
                    label: 'Email',
                    value: userdetails.email,
                },
                {
                    label: 'Mobile number',
                    value: userdetails.contact_number,
                },
                {
                    label: 'City',
                    value: userdetails?.address?.city,
                },
                {
                    label: 'District',
                    value: userdetails?.address?.district,
                },
                {
                    label: 'State',
                    value: userdetails?.address?.state,
                },
                {
                    label: 'Postal code',
                    value: userdetails?.address?.postal_code,
                }
            ]
        },
        {
            groupname: 'Secondary details',
            fields: [
                {
                    label: 'Email',
                    value: userdetails.secondary_email ?? '--',
                },
                {
                    label: 'Mobile number',
                    value: userdetails.secondary_contact_number ?? '--',
                },
            ]
        }
    ]
    // .....

    // data get function
    useEffect(() => {
        profile_details()
        setSourceCf(user_data.is_cf)
        setUserId(user_data.id)
        setBreadcrumbPath('/home/profile/')
    }, [])

    useEffect(() => {
        let user_data = JSON.parse(localStorage.getItem('userData') || '')
        setCf_code(user_data.cf_code)
        // setPh_code(user_data.ph_code)
        setSourceCf(user_data.is_cf)
        if (user_data.is_cf) {
            setShowaddress(true)
            setPincode(user_data.address.postal_code || '')
            setLocationdetails(user_data.address || '')
        } else {
            setShowaddress(false)
        }
        setCf(user_data.is_cf)
        setUserId(user_data.id)
        setUserEmail(user_data.email)
        setUsername(user_data.user_name)
        setUserMobile(user_data.contact_number)
        setPincode(user_data.address.postal_code ?? '')
        setSecEmail(user_data.secondary_email)
        setSecMobile(user_data.secondary_contact_number)
        setUsertype(user_data.user_type)
        setTempUsertype(user_data.user_type)
        setSelectedValue(user_data.email_option === false ? 'Monday,Wednesday,Friday' : 'Daily')
    }, [profilemodal, cfpopup]);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)'); // Adjust the max-width according to your mobile breakpoint
        setIsMobile(mediaQuery.matches);

        const handleResize = () => setIsMobile(mediaQuery.matches);

        mediaQuery.addListener(handleResize);

        return () => {
            mediaQuery.removeListener(handleResize);
        };

    }, []);

    const formatDateTime = (dateString: string) => {
        return format(new Date(dateString), 'hh:mm a dd-MM-yyyy');
    };
    // ..........


    const profile_details = () => {
        let body: any = {
            id: user_data.id,
        }
        setDetailoading(true)
        serviceConfig.get('profile_user', true, body, null).then((res: any) => {
            console.log(res[0])
            setUserdetails(res[0])
            setChecked(res[0].daily_login_sync)
            setDetailoading(false)
        }, (err: any) => {
            setNotifyType("error")
            setNotifyMessage(err)
            setNotifyOpen(true)
            setPhloading(false)
        })
    }

    // username availability check function
    const checkUserName = () => {
        let req_body = {
            "name": user_name
        }
        serviceConfig.get('check_user', true, req_body, null).then((data: any) => {
            setErrUserName("");
        },
            (err: any) => {
                setErrUserName(err.response.status === 400 ? '' : "This user name is already in use")
            })

    }
    // ..........

    // form validation and field Onchange functions

    const validatePincode = (number: any) => {
        const pattern = /^[1-9][0-9]{5}$/;
        return pattern.test(number);
    };
    const handlePincodeChange = (e: any) => {
        const newval = e.target.value;
        setPincode(newval)
        setErrPincode(validatePincode(newval) ? '' : '*Invalid pincode');
        setpinChanged(true)
        if (newval.length === 6) {
            postpincode(newval)
        }
    }

    const validateMobileNumber = (number: any) => {
        const pattern = /^[0-9]{10}$/;
        return pattern.test(number);
    };
    const handleMobileChange = (e: any, mob: string) => {
        const newval = e.target.value;
        if (mob === 'primary') {
            setUserMobile(newval);
            setErrUserMobile(validateMobileNumber(newval) ? '' : '*Invalid mobile number');
        } else {
            setSecMobile(newval);
            setErrSecMobile(validateMobileNumber(newval) ? '' : '*Invalid mobile number');
        }
    }

    const validateEmail = (email: any) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email);
    }
    const handleEmailChange = (e: any, mail: any) => {
        const newval = e.target.value;
        if (mail === 'primary') {
            setUserEmail(newval);
            setErruserEmail(validateEmail(newval) ? '' : '*Invalid E-Mail id')
        } else {
            setSecEmail(newval);
            setErrSecEmail(validateEmail(newval) ? '' : '*Invalid E-Mail id')
        }
    }

    // ..........

    // pincode  verification
    const postpincode = (code: string) => {
        setLoading(true)
        let query: any = {}
        query['pincode'] = code;
        serviceConfig.get('checkpin', true, query, null).then((res: any) => {
            setLocationdetails(res.data)
            setShowaddress(true)
            setLoading(false)
            setpinChanged(false)
            setNotifyType("success")
            setNotifyMessage('Landmark details received, kindly save your profile!')
            setNotifyOpen(true)
        },
            (err: any) => {
                setNotifyType("error")
                setNotifyMessage('Kindly Check your Pincode')
                setNotifyOpen(true)
                setLoading(false)
                setShowaddress(false)
            }
        )
    }
    // ..........

    // phpartneregister

    // ..........

    // Check if all values are not empty and if there are some errors
    const isError = useCallback(
        () =>
            Object.keys({ fullname, email, experience, address, ticContactNumber, organisation_name, qualification, team_size, pin_code, city, alt_contactnumber }).some(
                (name) => (formValues[name].required && !formValues[name].value) || formValues[name].error
            ),
        [formValues, fullname, email, experience, address, ticContactNumber, organisation_name, qualification, team_size, pin_code, city, alt_contactnumber]
    )
    // ..........

    // user update function Phregister
    const profileupdate = () => {
        if (!sourcecf) {
            if (!locationdetails) {
                setNotifyOpen(true)
                setNotifyType('info')
                setNotifyMessage('Kindly submit your Area Pincode!')
            } else {
                setOpenDialog(true);
                setPopuptype('CF')
            }
        } else if (sourcecf && pinchanged) {
            setNotifyOpen(true)
            setNotifyType('info')
            setNotifyMessage('Kindly submit your Area Pincode!')
        }
        else {
            Cfregister()
        }
    }

    const updateUser = () => {
        if (pinchanged) {
            setNotifyOpen(true)
            setNotifyType('info')
            setNotifyMessage('Kindly submit your Area Pincode!')
        } else {
            setLoading(true)
            let body: any = {
                id: user_id,
                user_name: user_name,
                email: user_email.toLowerCase(),
                contact_number: user_mobile,
                secondary_email: sec_email ? sec_email.toLowerCase() : null,
                secondary_contact_number: sec_mobile,
                page: 'self',
                address: locationdetails,
                user_type: usertype,
                email_option: selectedValue === 'Monday,Wednesday,Friday' ? false : true
            }
            if (new_password !== '**********') {
                body['password'] = new_password
            }
            serviceConfig.put('profile_user', true, body, null).then((res: any) => {
                profile_details()
                { userdata?.mobile_verified && !res.mobile_verified && handleSubmitnumber('mobile') }

                localStorage.setItem('is_cf', JSON.stringify(res.is_cf))
                localStorage.setItem('cf_code', JSON.stringify(res.cf_code))
                // localStorage.setItem('ph_code', JSON.stringify(res.ph_code))
                setUserdata(res)
                // if (!res.is_cf) {
                //     navigate('/home/gst-dashboard');
                // }
                setProfilemodal(false)
                localStorage.setItem('userData', JSON.stringify(res))
                // window.location.reload();
                setLoading(false)
                setCf_code(res.cf_code)
                // setPh_code(res.ph_code)
                setSourceCf(res.is_cf)
                if (res.is_cf) {
                    setShowaddress(true)
                } else {
                    setShowaddress(false)
                }
                setCf(res.is_cf)
            },
                (err: any) => {
                    setNotifyType("error")
                    setNotifyMessage(err.response.data.detail || 'Something went wrong!')
                    setNotifyOpen(true)
                    setLoading(false)
                }
            )
        }
    }

    const Cfregister = () => {
        setLoading(true)
        let body: any = {
            id: user_id,
            user_name: user_name,
            email: user_email.toLowerCase(),
            contact_number: user_mobile,
            secondary_email: sec_email ? sec_email.toLowerCase() : null,
            secondary_contact_number: sec_mobile,
            page: 'self',
            is_cf: true,
            user_type: usertype,
            email_option: selectedValue === 'Monday,Wednesday,Friday' ? false : true,
            address: userData.address === null ? locationdetails : userData.address
        }
        serviceConfig.put('profile_user', true, body, null).then((res: any) => {
            setNotifyType("success")
            setNotifyMessage('CF partner registration completed.')
            setNotifyOpen(true)
            profile_details()
            { userdata?.mobile_verified && !res.mobile_verified && handleSubmitnumber('mobile') }

            localStorage.setItem('is_cf', JSON.stringify(res.is_cf))
            localStorage.setItem('cf_code', JSON.stringify(res.cf_code))
            // localStorage.setItem('ph_code', JSON.stringify(res.ph_code))
            setUserdata(res)
            // if (!res.is_cf) {
            //     navigate('/home/gst-dashboard');
            // }
            setCfpopup(false)
            setProfilemodal(false)
            localStorage.setItem('userData', JSON.stringify(res))
            // window.location.reload();
            setLoading(false)
            setCf_code(res.cf_code)
            // setPh_code(res.ph_code)
            setSourceCf(res.is_cf)
            if (res.is_cf) {
                setShowaddress(true)
            } else {
                setShowaddress(false)
            }
            setCf(res.is_cf)
        },
            (err: any) => {
                setNotifyType("error")
                setNotifyMessage(err.response.data.detail || 'Something went wrong!')
                setNotifyOpen(true)
                setLoading(false)
            }
        )
    }
    // ..........



    // modal close function
    const handleClose = () => {
        setCfpopup(false)
        setCf(false)
        setLocationdetails(null)
        setErrPincode('')
        setPincode('')
        setShowaddress(false)
        setUsertype('Taxpayer')
        setTempUsertype('Taxpayer')
        setGstcheck('Fresher')
        setSgstcheck('Daily')
        setCgstcheck('Daily')
        setChips([])
        setProfilemodal(false)
        setActiveStep(0)
    };
    // ..........

    //  function Phregister submit
    const Phregister = () => {
        setPhloading(true)
        let body: any = {
            full_name: fullname.value,
            email: email.value.toLowerCase(),
            experience: experience.value,
            address: address.value,
            phone_no: ticContactNumber.value,
            org_name: organisation_name.value,
            qualification: qualification.value,
            team_size: team_size.value,
            pin_code: pin_code.value,
            city: city.value,
            alt_phone_no: alt_contactnumber.value,
            depth_gst: gstcheck,
            exist_fre_visit_sgst: sgstcheck,
            exist_fre_visit_cgst: cgstcheck,
            area_of_practices: chips,
            approval_status: 'pending',
            user: userData.id,
            ph_request: true
        }
        serviceConfig.post('ph_register', true, body, null).then((res: any) => {
            if (res.id) {
                setPhpopup(false)
            }
            let requestbody: any = {
                id: user_id,
                'ph_request': true
            }
            serviceConfig.put('profile_user', true, requestbody, null).then((res: any) => {
            })
            localStorage.setItem('ph_request', JSON.stringify(res.ph_request))

            setPhloading(false)
            handleClose()

            setNotifyOpen(true)
            setNotifyMessage('Ph registration request submitted successfully.')
            setNotifyType('success')

        },
            (err: any) => {
                setNotifyType("error")
                setNotifyMessage(err)
                setNotifyOpen(true)
                setPhloading(false)
            }
        )
    }
    // ..........

    //checkbox
    const checkboxData = [
        { label: 'Fresher' },
        { label: 'Return Filing' },
        { label: 'Expert' },
        { label: 'Basic Litigation Handling' }
    ];
    const checkboxData1 = [
        { label: 'Daily' },
        { label: 'At least once a week' },
        { label: 'At least once a month' },
    ];
    // ..........

    const [chips, setChips] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            const trimmedInput = inputValue.trim();
            if (!chips.includes(trimmedInput)) {
                setChips([...chips, trimmedInput]);
            }
            setInputValue('');
        }
    };

    const handleChipDelete = (chipIndex: number) => {
        const newChips = chips.filter((_, index) => index !== chipIndex);
        setChips(newChips);
    };

    // radio function 
    const handleradioChange = (event: any) => {
        const newTypeValue = (event.target as HTMLInputElement).value;

        setSelectedValue(newTypeValue);
    };
    // ......

    // confirmation function
    const handleConfirm = () => {
        if (popuptype === 'CF') {
            setOpenDialog(false);
            Cfregister()
        } else {
            Phregister()
            setOpenDialog(false);
        }
    };
    // .........

    // usertype change function
    const handlechange = (event: any) => {
        const { value } = event.target;
        setTempUsertype(value);
        setModaltype('Type');
        setOpenDialog(true);
    };

    const userType: any = [
        'Taxpayer',
        'GST Practitioner / Professional',
    ]

    const handlechangetype = () => {
        setUsertype(tempUsertype);
        setOpenDialog(false);
        setModaltype('')
    }

    const handlecanceltype = () => {
        setOpenDialog(false);
        setModaltype('');
    }

    const handlechangeloginsync = () => {
        let requestbody: any = {
            'daily_login_sync': !userdetails.daily_login_sync
        }
        serviceConfig.put('profile_user', true, requestbody, { id: user_id }).then((res: any) => {
            profile_details()
            setOpenDialog(false);
            setModaltype('')
        })

    }
    // ..........

    // mobile number submission
    const handleSubmitnumber = (type: string) => {
        let query: any = {}
        serviceConfig.get("check_otp", true, query, null).then((data: any) => {
            type === 'mobile' && setOtpmodal(true)
            setNotifyType("success")
            setNotifyMessage('OTP sended to your registered mobile number.')
            setNotifyOpen(true)
        }, (err: any) => {
            setNotifyType("error")
            setNotifyMessage('Something Went Wrong!')
            setNotifyOpen(true)
        })
    }
    // ..........

    // otp field
    const handleChangeotp = (value: string, index: number) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);


        // Move focus to the next input if a digit is entered
        if (value && index < otp.length - 1) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            nextInput?.focus();
        }

        setErr_otp('')
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            const prevInput = document.getElementById(`otp-input-${index - 1}`);
            prevInput?.focus();
        }
    };

    const validateOtp = (): boolean => {
        // Check if any of the OTP fields are empty
        return otp.every((digit) => digit !== '');
    };
    // ..........

    // otp verification function
    const verification = () => {
        if (!loading) {
            if (!validateOtp()) {
                if (!validateOtp()) {
                    setErr_otp('*Please enter all 4 digits of the OTP');
                    return;
                }
            } else {
                setLoading(true)

                let req_body = {
                    'otp': otp.join('')
                }
                serviceConfig.post('verify_otp', true, req_body, null).then((res: any) => {
                    setLoading(false)
                    setNotifyType("success")
                    setNotifyMessage('User mobile number verified successfully.')
                    setNotifyOpen(true)
                    setOtpmodal(false)
                    setOtp(['', '', '', ''])

                },
                    (err: any) => {
                        setNotifyType("error")
                        setNotifyMessage(err && err.response && err.response.data.error || 'Something Went Wrong !')
                        setNotifyOpen(true)
                        setLoading(false)
                    }
                )
            }
        }
    }
    // ..........

    const capitalizeFirstLetter = (str: string) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const formatDate = (inputDate: string | null): string => {
        if (!inputDate) return '--';

        const date = new Date(inputDate);
        if (isNaN(date.getTime())) return '--'; // Handle invalid dates

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 0 to 12

        return `${day}-${month}-${year}, ${hours}:${minutes} ${ampm}`;
    };

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: '#E96919 !important',
            '&:hover': {
                backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
            backgroundColor: '#B0B4AF',
        },
        '&.MuiSwitch-track': {
            backgroundColor: '#B0B4AF',
        },
        '& .MuiSwitch-switchBase': {
            color: '#B0B4AF'
        },
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
            backgroundColor: '#E96919',
        }
    }));

    const getStatusofSyncAllProgress = () => {
        setSyncloading(true)
        setProgres({ data: { loading: true, label: 'Sync' } })

        serviceConfig.get("gst_sync_details", true, null, { id: syncId }).then((data: any) => {
            setSyncDetails(data)
            setSyncloading(false)
            syncId = data.id
            setSyncId(data.id)
            let timerInt = !data.sync_processed ? setTimeout(() => {
                getStatusofSyncAllProgress();
            }, (10 * 1000)) : null;
            if (data.sync_processed) {
                timerInt = null;
                setreportModal(true)
                setProgres({ data: { loading: false, label: '' } })
            }
        }, (err: any) => {
            setNotifyType("error")
            setNotifyMessage('Something Went Wrong!')
            setNotifyOpen(true)
            setSyncloading(false)
            setreportModal(false)
            setProgres({ data: { loading: false, label: '' } })
        })
    }

    const detailcolumns = [
        {
            title: 'S.No',
            dataIndex: 'serial',
            key: 'serial',
            render: (text: any, record: any, index: number) => index + 1,
            width: 70,
        },
        {
            title: 'User Name',
            dataIndex: 'gst',
            render(value: any, record: any, index: any) {
                return value.user_name
            },
            fixed: 'left' as 'left',
            width: 150,
        },
        {
            title: 'Start Time',
            dataIndex: 'sync_started',
            key: 'sync_started',
            render(value: any, record: any, index: any) {
                function join(date: any, options: any, separator: any) {
                    function format(option: any) {
                        let formatter = new Intl.DateTimeFormat('en', option);
                        return formatter.format(date);
                    }
                    let joined = options.map(format).join(separator);
                    return joined + ', ' + new Date(value).toLocaleTimeString();
                }

                let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
                return value ? join(new Date(value), options, ' ') : '--';
            },
            width: 170
        },
        {
            title: 'End Time',
            dataIndex: 'sync_end',
            key: 'sync_end',
            render(value: any, record: any, index: any) {
                function join(date: any, options: any, separator: any) {
                    function format(option: any) {
                        let formatter = new Intl.DateTimeFormat('en', option);
                        return formatter.format(date);
                    }
                    let joined = options.map(format).join(separator);
                    return joined + ', ' + new Date(value).toLocaleTimeString();
                }

                let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
                return value ? join(new Date(value), options, ' ') : '--';
            },
            width: 180
        },
        {
            title: 'Status', dataIndex: 'is_sync_failed', key: 'is_sync_failed',
            render(value: any, record: any, index: any) {
                if (value === true) {
                    return <Tag color='error' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >Fail</Tag>
                } else {
                    return <Tag color='success' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >Success</Tag>
                }
            },
            width: 120
        },
        {
            title: 'Fail Reason',
            dataIndex: 'failed_reason',
            render(value: any, record: any, index: any) {
                return value ?? '--'
            },
            width: 150,
        },
        {
            title: 'Total Time for sync (M:S)',
            dataIndex: 'email',
            render(value: any, record: any, index: any) {
                function millisToMinutesAndSeconds(millis: any) {
                    var minutes = Math.floor(millis / 60000);
                    var seconds: any = ((millis % 60000) / 1000).toFixed(0);
                    return (minutes < 10 ? '0' : '') + minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
                }

                let sync_end: any = new Date(record.sync_end)
                let sync_start: any = new Date(record.sync_started)
                let diffMs = sync_end - sync_start
                return <span style={{ fontWeight: 'bold', color: '#E96919' }}>{record.sync_started && record.sync_end ? millisToMinutesAndSeconds(diffMs) : '--'}</span>
            },
            width: 170
        },
    ];

    const groupToStepMap: any = {
        'Personal details': 1,
        'Secondary details': 2,
    };

    // Resend mail function
    const resendMail = () => {
        if (!loading) {
            setLoading(true)
            serviceConfig.get('resend_mail', true, { id: userData.id }, null).then((data: any) => {
                setNotifyType("success");
                setNotifyMessage("Please check your mail for verification.");
                setNotifyOpen(true);
                setLoading(false)
            },
                (err: any) => {
                    setLoading(false)
                    setNotifyType("error");
                    setNotifyMessage("Something Went Wrong!");
                    setNotifyOpen(true);
                })
        }
    }
    // ..........

    type Field = {
        label: string;
        value: string | boolean;
    };

    interface FieldWrapProps {
        field: Field;
        setModaltype: (type: string) => void;
        setOpenDialog: (isOpen: boolean) => void;
        resendMail: () => void;
    }

    const handleGreenSwitchClick = (val_field: any) => {
        setModaltype('Sync');
        setFieldType(val_field)
        setOpenDialog(true);
    };

    const renderTag = (field: any) => {
        const isVerified = field.value === 'Verified';
        const isMailVerification = field.label === 'Mail verification';
        const isMobileVerification = field.label === 'Mobile verification';
        const isPasswordVerification = field.label === 'Password verification';
        const isMailClickable = isMailVerification && field.value === 'Not verified';
        const isMobileClickable = isMobileVerification && field.value === 'Not verified';
        const isPasswordClickable = isPasswordVerification && field.value === 'Not verified';

        return (
            <span
                style={{ cursor: isMailClickable || isMobileClickable || isPasswordClickable ? 'pointer' : undefined }}
                className={isVerified ? 'tag theme_one' : 'tag theme_two'}
                onClick={() => { if (isMailClickable) { resendMail(); } else if (isMobileClickable) { handleSubmitnumber('mobile') } else if (isPasswordClickable) { if (userData.password_status) { setPasswordopen(true) } else { handleSubmitnumber('password'); setPasswordopen(true) } } }}
            >
                {capitalizeFirstLetter(field.value as string) ?? '--'}
            </span>
        );
    };

    const renderDateOrDefault = (field: any) => {
        return (field.label === 'Account created' || field.label === 'Last sync')
            ? formatDate(field.value as string | null)
            : (field.value ? capitalizeFirstLetter(field.value as string) : '--');
    };

    const renderContent = (field: any) => {
        if (field.label === 'Sync all while daily login') {
            return <GreenSwitch checked={field.value as boolean} onClick={() => handleGreenSwitchClick(field.label)} />;
        }

        if (['Mail verification', 'Mobile verification', 'Password verification'].includes(field.label)) {
            return renderTag(field);
        }

        return <span>{renderDateOrDefault(field)}</span>;
    };

    // Copy to clipboard option function
    const handleCopyClick = (code: any, link?: any) => {
        copy(code);
        setNotifyType("success")
        setNotifyMessage(`Refer ${link ? 'Link' : "Code"} copied successfully.`)
        setNotifyOpen(true)
    };
    // ..........

    const getUserCode = (): string => {
        if (userData?.is_cf && !phlogin) {
            return userData.cf_code || '';
        } else if (userData?.ph_code && phlogin) {
            return userData.ph_code;
        }
        return '';
    };

    const copyCode = () => {
        handleCopyClick(getUserCode());
    };

    const copyLink = () => {
        const code = getUserCode();
        const baseUrl = (process.env.REACT_APP_BASE_URL || "").slice(0, -4);
        handleCopyClick(`${baseUrl}#/register?refer_code=${code}`, true);
    };

    const code_submenu = [
        {
            items: [
                { name: 'Copy Code', openstate: copyCode },
                { name: 'Copy Link', openstate: copyLink }
            ]
        }
    ]

    interface MenuItem {
        name: string;
        openstate: (state: boolean) => void;
    }

    const submenuClick = (item: MenuItem) => {
        const actions: any = {
            'Copy Code': () => copyCode(),
            'Copy Link': () => copyLink()
        };

        if (actions[item.name]) {
            actions[item.name]();
        } else {
            console.warn(`Unhandled item name: ${item.name}`);
            item.openstate(true);
        }
    };

    const renderAddMenu = (submenu: any[] | undefined) => (
        <Menu
            style={{ display: submenu ? '' : 'none' }}
            className="horizontal-grouped-menu"
        >
            {submenu?.[0]?.items.map((item: any, index: any) => (
                <Menu.Item key={index} onClick={() => submenuClick(item)} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {item.name}
                        {item.name === 'Clear & Sync All' &&
                            <Tooltip placement="bottom" title={'Sync Progressing'} arrow={true} color={'#E96919'} >
                                <VisibilityOutlinedIcon
                                    style={{ marginLeft: 4, color: '#E96919' }} />
                            </Tooltip>}
                    </span>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <>
            <Modal
                title={"Sync Report"}
                onCancel={() => setreportModal(false)}
                width={'40%'}
                className='sync-report-popup'
                centered
                style={{ color: "#031742" }}
                open={reportmodal}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <div className='report-wrap'>
                    {syncDetails ?
                        <>
                            <div className='report-title'>
                                <h3>GSTIN Processed {!isMobile && 'count'}</h3>
                                <h3>First time sync</h3>
                                <h3>{!isMobile && 'Total'} Notices fetched</h3>
                                <h3>{!isMobile ? 'Successfully synced' : 'Sync succeeded'} </h3>
                                <h3>Sync failed</h3>
                                <h3>Sync start time</h3>
                                {syncDetails.sync_end && <h3>Sync end time</h3>}
                            </div>
                            <div className='report-cnt'>
                                <h3>: {syncDetails.gst_list.length}</h3>
                                <h3>: {syncDetails.gst_list.filter((sd: any) => sd.is_first_time).length}</h3>
                                <h3>: {syncDetails.gst_list.map((obj: any) => obj.notice_count).reduce((accumulator: any, current: any) => accumulator + current, 0)}</h3>
                                <h3>: {syncDetails.gst_list.filter((sd: any) => sd.sync_end != null && !sd.is_sync_failed).length}</h3>
                                <h3>: {syncDetails.gst_list.filter((sd: any) => sd.sync_end != null && sd.is_sync_failed).length}</h3>
                                <h3 style={{ whiteSpace: 'nowrap' }}>: {isMobile ? formatDateTime(syncDetails.created_at) : new Date(syncDetails.created_at).toDateString() + ' ' + new Date(syncDetails.created_at).toLocaleTimeString()}</h3>
                                {syncDetails.sync_end && <h3 style={{ whiteSpace: 'nowrap' }}>: {isMobile ? formatDateTime(syncDetails.created_at) : syncDetails.sync_end ? new Date(syncDetails.sync_end).toDateString() + ' ' + new Date(syncDetails.sync_end).toLocaleTimeString() : '--'}</h3>}
                            </div>
                        </> :
                        <Spin spinning={syncloading} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute' }}>
                        </Spin>
                    }
                </div>
                <div className='footer_wrap'>
                    <p><b>Note:</b> Incorrect password is not at all considered.</p>
                    {syncDetails?.sync_processed && <Button className='dtail_btn' onClick={() => { setreportModal(false); setDetailopen(true); setDetailsource(syncDetails.gst_list) }}>Detailed Report <ArrowRightIcon /> </Button>}
                </div>
            </Modal>
            <Modal
                title="Sync Details"
                onCancel={() => { setDetailopen(false); setDetailsource([]) }}
                className='upload-popup'
                width={'50%'}
                centered
                style={{ color: "#031742", minWidth: '30%' }}
                open={detailopen}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <>
                    <div className='table-label-wrap'>
                        <div className='table-label'>
                            <span>Total accounts</span>
                            <h4>{syncDetails?.gst_list?.length}</h4>
                        </div>
                        <div className='table-label'>
                            <span>Sync start time</span>
                            <h4>{syncDetails?.sync_started ? new Date(syncDetails.created_at).toDateString() + ' ' + new Date(syncDetails.created_at).toLocaleTimeString() : '-'}</h4>
                        </div>
                        <div className='table-label'>
                            <span>Sync end time</span>
                            <h4>{syncDetails?.sync_end ? new Date(syncDetails.sync_end).toDateString() + ' ' + new Date(syncDetails.sync_end).toLocaleTimeString() : '-'}</h4>
                        </div>
                    </div>

                    <Table
                        loading={detailloading}
                        style={{ minWidth: '35vw', maxWidth: '100%', marginTop: '3%' }}
                        columns={detailcolumns}
                        dataSource={detailSource}
                        scroll={{ y: 345 }}
                    // Other props for Table component
                    />
                </>
            </Modal >

            <BootstrapDialog
                onClose={() => { setOtpmodal(false); setOtp(['', '', '', '']) }}
                aria-labelledby='customized-dialog-title'
                open={otpmodal}
                id='confirm_container'>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="pwd">

                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        setOtpmodal(false); setOtp(['', '', '', ''])
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent
                    dividers
                    style={{ padding: "0px 20px 11px 20px" }}>
                    <div className="verify-container">
                        <h4 className='terminal-window-mainline heading'>OTP verification</h4>
                        <p className="terminal-window-subline">Otp sended to your registered Mobile number for verfication.</p>
                        <div className="col-sm-12 otp-group" style={{ paddingBottom: err_otp ? '0%' : '' }}>
                            <div className='otp-cover'>
                                {otp.map((digit, index) => (
                                    <ThemeProvider theme={inputtheme}>
                                        <OutlinedInput
                                            className='otp_input'
                                            key={index}
                                            id={`otp-input-${index}`}
                                            autoComplete='off'
                                            value={digit}
                                            onChange={(e: any) => handleChangeotp(e.target.value, index)}
                                            onKeyDown={(e: any) => handleKeyDown(e, index)}
                                            inputProps={{
                                                maxLength: 1,
                                                style: { textAlign: 'center' }
                                            }}
                                        />
                                    </ThemeProvider>
                                ))}

                            </div>
                            <FormHelperText id='error-line' style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right' }} >{err_otp}</FormHelperText>
                        </div>
                        <div className="col-sm-12 input-group">
                            <button className="register teminal-primary-btn submt_btn" onClick={verification}>{loading ? 'Loading...' : 'Submit'}</button>
                        </div>
                    </div>
                </DialogContent>
            </BootstrapDialog >
            <BootstrapDialog
                onClose={() => { setPhpopup(false); handleInitial(); handleClose() }}
                aria-labelledby="customized-dialog-title"
                open={phpopup}
                id='ph_partner_container'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="pwd">
                    PH Partner Registration
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setPhpopup(false); handleInitial(); handleClose() }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className='dialog-content' style={{ marginTop: '0%' }}>
                    <div className='ph-content-cover'>
                        <div className='ph-content-split'>
                            <div className="fields">
                                <div className="row">
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0rem' }}>
                                        <label className="label">Full Name</label>
                                        <span>{fullname.error}</span>
                                        <div className="input-box" >
                                            {/* <img className="icons" src={fullnameIcon} alt='usernameIcon'></img> */}
                                            <input
                                                type="text"
                                                placeholder="Enter your full name"
                                                value={fullname.value}
                                                onBlur={handleChange}
                                                onChange={handleChange}
                                                autoComplete='off'
                                                name="fullname"
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Email</label>
                                        <span>{email.error}</span>
                                        <div className="input-box" >
                                            {/* <img className="icons" src={emailicon} alt='emailicon'></img> */}
                                            <input
                                                type="text"
                                                placeholder="Enter your Email"
                                                readOnly
                                                value={userData.email}
                                                onBlur={handleChange}
                                                onChange={handleChange}
                                                autoComplete='off'
                                                name="email" ></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Experience (Yrs)</label>
                                        <span>{experience.error}</span>
                                        <div className="input-box" >
                                            {/* <img className="icons" src={Exp_icon} alt='Exp_icon'></img> */}
                                            <input
                                                type="text"
                                                placeholder="Enter your experience"
                                                name='experience'
                                                value={experience.value}
                                                onBlur={handleChange}
                                                autoComplete='off'
                                                onChange={handleChange}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Address</label>
                                        <span>{address.error}</span>
                                        <div className="input-box" >
                                            <input type="text"
                                                placeholder="Enter your user name"
                                                autoComplete='off'
                                                name='address'
                                                value={address.value}
                                                onBlur={handleChange}
                                                onChange={handleChange}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Phone Number</label>
                                        <span>{ticContactNumber.error}</span>
                                        <div className="input-box" >
                                            <input type="text"
                                                placeholder="Enter your phone number"
                                                autoComplete='off'
                                                name='ticContactNumber'
                                                value={ticContactNumber.value}
                                                onBlur={handleChange}
                                                onChange={handleChange}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Existing Frequency of visits to CGST Department :</label>
                                    </div>
                                    <div className='checkbox-over-wrap'>
                                        {checkboxData1.map((item, index) => (
                                            <div className='checkbox-cover' key={index} onClick={() => setCgstcheck(item.label)}>
                                                <span
                                                    className={cgstcheck === item.label ? 'checked_box' : 'check_box'}

                                                ></span>
                                                <div className="label">{item.label}</div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Existing Frequency of visits to SGST Department :</label>
                                    </div>
                                    <div className='checkbox-over-wrap'>
                                        {checkboxData1.map((item, index) => (
                                            <div className='checkbox-cover' key={index} onClick={() => setSgstcheck(item.label)}>
                                                <span
                                                    className={sgstcheck === item.label ? 'checked_box' : 'check_box'}

                                                ></span>
                                                <div className="label">{item.label}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='ph-content-split'>
                            <div className="fields">
                                <div className="row">
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0rem' }}>
                                        <label className="label">Organisation Name</label>
                                        <span>{organisation_name.error}</span>
                                        <div className="input-box" >
                                            <input
                                                type="text"
                                                placeholder="Enter your organisation name"
                                                autoComplete='off'
                                                name='organisation_name'
                                                value={organisation_name.value}
                                                onBlur={handleChange}
                                                onChange={handleChange}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Qualification</label>
                                        <span>{qualification.error}</span>
                                        <div className="input-box" >
                                            <input type="text"
                                                placeholder="Enter your qualification"
                                                autoComplete='off'
                                                name='qualification'
                                                value={qualification.value}
                                                onBlur={handleChange}
                                                onChange={handleChange}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Team Size</label>
                                        <span>{team_size.error}</span>
                                        <div className="input-box" >
                                            <input
                                                type="text"
                                                placeholder="Enter your team size"
                                                autoComplete='off'
                                                name='team_size'
                                                value={team_size.value}
                                                onBlur={handleChange}
                                                onChange={handleChange} ></input>
                                        </div>
                                    </div>
                                    <div style={{ width: '95%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem', width: '48%' }}>
                                            <label className="label">Pin Code</label>
                                            <span>{pin_code.error}</span>
                                            <div className="input-box" >
                                                <input type="text"
                                                    placeholder="Enter your pin code"
                                                    autoComplete='off'
                                                    name='pin_code'
                                                    value={pin_code.value}
                                                    onBlur={handleChange}
                                                    onChange={handleChange} ></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem', width: '48%' }}>
                                            <label className="label">City</label>
                                            <span>{city.error}</span>
                                            <div className="input-box" >
                                                <input type="text"
                                                    name='city'
                                                    value={city.value}
                                                    placeholder="Enter your city"
                                                    autoComplete='off'
                                                    onBlur={handleChange}
                                                    onChange={handleChange} ></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Alternative Phone Number</label>
                                        <span>{alt_contactnumber.error}</span>
                                        <div className="input-box" >
                                            <input type="text"
                                                placeholder="Enter your alter phone number"
                                                autoComplete='off'
                                                name='alt_contactnumber'
                                                value={alt_contactnumber.value}
                                                onBlur={handleChange}
                                                onChange={handleChange}  ></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Depth of GST Practice :</label>
                                    </div>
                                    <div className='checkbox-over-wrap'>
                                        {checkboxData.map((item, index) => (
                                            <div className='checkbox-cover' key={index} onClick={() => setGstcheck(item.label)}>
                                                <span
                                                    className={gstcheck === item.label ? 'checked_box' : 'check_box'}

                                                ></span>
                                                <div className="label">{item.label}</div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label" id="area-label" style={{ marginBottom: '0.6rem' }}>Areas of practice</label>
                                        <div className="chip-input-container">
                                            {chips.map((chip, index) => (
                                                <div key={index} className="chip">
                                                    {chip}
                                                    <button onClick={() => handleChipDelete(index)}>x</button>
                                                </div>
                                            ))}
                                            <div className="input-box" >
                                                <input
                                                    type="text"
                                                    placeholder="Type and press Enter to add Area"
                                                    name='alt_contactnumber'
                                                    value={inputValue}
                                                    onKeyDown={handleInputKeyDown}
                                                    autoComplete='off'
                                                    onChange={handleInputChange}  ></input>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 btn-group " id="last-one" >
                        <button disabled={isError()} className="register_ph" onClick={() => { setOpenDialog(true); setPopuptype('PH') }}>{!loading ? 'submit for registration' : 'Loading...'}</button>
                    </div>
                </DialogContent>
            </BootstrapDialog >

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={cfpopup}
                id='cf_container'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="pwd">
                    CF Partner Registration
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers style={{ marginTop: '0%' }}>

                    <div>
                        <Box sx={{ width: '100%' }}>
                            <div>
                                <React.Fragment>
                                    <div>
                                        <div>
                                            <div className="fields">
                                                <div className="row">
                                                    <div className="col-sm-12 input-group" style={{ marginTop: '0' }}>
                                                        <label className="label">Pincode</label>

                                                        <ThemeProvider theme={inputtheme}>
                                                            <OutlinedInput
                                                                style={{ marginTop: '0.6rem' }}
                                                                autoComplete='off'
                                                                className='inputfield'
                                                                placeholder='Enter your pincode'
                                                                value={pincode}
                                                                error={!!err_pincode}
                                                                onChange={handlePincodeChange}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={() => { postpincode(pincode) }}
                                                                            edge="end"
                                                                            disabled={!!err_pincode}
                                                                        >
                                                                            <SearchIcon
                                                                                style={{
                                                                                    color: !!err_pincode ? '#E96919' : '#05103E',
                                                                                    cursor: 'pointer',
                                                                                }} />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                                aria-describedby="outlined-weight-helper-text"
                                                            />
                                                            <FormHelperText style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right', position: 'relative' }} id='error-line'>{err_pincode}</FormHelperText>

                                                        </ThemeProvider>
                                                    </div>
                                                    {showaddress &&
                                                        <>
                                                            <div className="col-sm-12 input-group" style={{ marginTop: err_pincode ? '0' : '0.8rem' }}>
                                                                <label className="label">City</label>
                                                                <div className="input-box" >
                                                                    <input type="text" placeholder="Your District" readOnly value={locationdetails && locationdetails.city} onChange={(e) => setUsername(e.target.value)}></input>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 input-group" style={{ marginTop: '0.8rem' }}>
                                                                <label className="label">District</label>
                                                                <div className="input-box" >
                                                                    <input type="text" placeholder="Your District" readOnly value={locationdetails && locationdetails.district} onChange={(e) => setUsername(e.target.value)}></input>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 input-group" style={{ marginTop: '0.8rem' }}>
                                                                <label className="label">State</label>
                                                                <div className="input-box" >
                                                                    <input type="text" placeholder="Your State" readOnly value={locationdetails && locationdetails.state} onChange={(e) => setUsername(e.target.value)}></input>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    <div className="col-sm-12 input-group " id="last-one" >
                                                        <button className="register" onClick={profileupdate} >{!loading ? 'Save' : 'Loading...'}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>

                            </div>
                        </Box>
                    </div>

                </DialogContent >
            </BootstrapDialog >

            <BootstrapDialog
                onClose={() => {
                    handleClose();
                }}
                aria-labelledby="customized-dialog-title"
                open={profilemodal}
                id='pro_container'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="pwd">
                    Edit {activeStep === 1 ? 'Personal details' : activeStep === 2 ? 'Secondary details' : 'Account details'}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        handleClose();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers style={{ marginTop: '0%' }}>

                    <div>
                        <Box sx={{ width: '100%' }}>
                            <div>
                                <React.Fragment>
                                    <div>
                                        {activeStep === 0 &&
                                            <div>
                                                <div className="fields">
                                                    <div className="row">
                                                        <div className="col-sm-12 input-group" style={{ marginTop: '1.2rem' }}>
                                                            <label className="label">User Type</label>
                                                            <TextField
                                                                fullWidth
                                                                focused
                                                                select
                                                                SelectProps={{ native: true, }}
                                                                variant='outlined'
                                                                name="casetype"
                                                                value={usertype}
                                                                onChange={handlechange}
                                                                autoComplete='off'
                                                                style={{ margin: '2% 0' }}
                                                            >
                                                                <option value='' disabled>...</option>
                                                                {userType.map((type: any) =>
                                                                    <option key={type} value={type}>{type}</option>
                                                                )}
                                                            </TextField>
                                                        </div>

                                                        <div className="col-sm-12 input-group" style={{ marginTop: '0.8rem' }}>
                                                            <label className="label">Mail Frequent</label>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-form-control-label-placement"
                                                                id='radio-grp'
                                                            >
                                                                <FormControlLabel
                                                                    value="Monday,Wednesday,Friday"
                                                                    id='first-radio'
                                                                    control={
                                                                        <Radio
                                                                            checked={selectedValue === 'Monday,Wednesday,Friday'}
                                                                            onChange={handleradioChange}
                                                                            value="Monday,Wednesday,Friday"
                                                                            name="radio-buttons"
                                                                        />}
                                                                    label="Monday,Wednesday,Friday" />
                                                                <FormControlLabel
                                                                    value="Daily"
                                                                    control={
                                                                        <Radio
                                                                            checked={selectedValue === 'Daily'}
                                                                            onChange={handleradioChange}
                                                                            value="Daily"
                                                                            name="radio-buttons"
                                                                        />}
                                                                    label="Daily" />
                                                            </RadioGroup>
                                                        </div>
                                                        <div className="col-sm-12 input-group " id="last-one" >
                                                            <button className="register" onClick={updateUser} >{!loading ? 'Save' : 'Loading...'}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {activeStep === 1 && <div>
                                            <div className="fields">
                                                <div className="row">

                                                    <div className="col-sm-12 input-group" style={{ marginTop: err_pincode ? '0' : '0.8rem' }}>
                                                        <label className="label">User Name</label>
                                                        <span>{err_user_name}</span>
                                                        <div className="input-box" >
                                                            <input type="text" placeholder="Enter your user name" value={user_name} onChange={(e) => setUsername(e.target.value)}
                                                                onBlur={checkUserName} ></input>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 input-group" style={{ marginTop: err_user_name ? '0' : '0.8rem' }}>
                                                        <label className="label">Mobile Number</label>
                                                        <ThemeProvider theme={inputtheme}>
                                                            <OutlinedInput
                                                                autoComplete='off'
                                                                className='inputfield'
                                                                placeholder='Enter your registered mobile number'
                                                                value={user_mobile}
                                                                error={!!err_user_mobile}
                                                                onChange={e => handleMobileChange(e, 'primary')}
                                                                endAdornment={
                                                                    userData && !userData.mobile_verified && (
                                                                        <InputAdornment position="end">
                                                                            < IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={() => { handleSubmitnumber('mobile') }}
                                                                                edge="end"
                                                                                disabled={!!err_user_mobile || !user_mobile}
                                                                            >
                                                                                <SendIcon
                                                                                    style={{
                                                                                        color: !!err_user_mobile || !user_mobile ? '#E96919' : '#05103E',
                                                                                        cursor: !!err_user_mobile || !user_mobile ? 'not-allowed' : 'pointer',
                                                                                    }} />
                                                                            </IconButton>
                                                                        </InputAdornment>)
                                                                }
                                                                aria-describedby="outlined-weight-helper-text"
                                                            />
                                                        </ThemeProvider>
                                                        <span style={{ textAlign: 'right' }} >{err_user_mobile}</span>
                                                    </div>

                                                    <div className="col-sm-12 input-group" style={{ marginTop: err_user_mobile ? '0' : '' }}>
                                                        <label className="label">Pincode</label>

                                                        <ThemeProvider theme={inputtheme}>
                                                            <OutlinedInput
                                                                autoComplete='off'
                                                                className='inputfield'
                                                                placeholder='Enter your pincode'
                                                                value={pincode}
                                                                error={!!err_pincode}
                                                                onChange={handlePincodeChange}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={() => { postpincode(pincode) }}
                                                                            edge="end"
                                                                            disabled={!!err_pincode}
                                                                        >
                                                                            <SearchIcon
                                                                                style={{
                                                                                    color: !!err_pincode ? '#E96919' : '#05103E',
                                                                                    cursor: 'pointer',
                                                                                }} />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                                aria-describedby="outlined-weight-helper-text"
                                                            />
                                                            <FormHelperText style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right', position: 'relative' }} id='error-line'>{err_pincode}</FormHelperText>

                                                        </ThemeProvider>
                                                    </div>
                                                    {showaddress &&
                                                        <>
                                                            <div className="col-sm-12 input-group" style={{ marginTop: err_pincode ? '0' : '0.8rem' }}>
                                                                <label className="label">City</label>
                                                                <div className="input-box" >
                                                                    <input type="text" placeholder="Your District" readOnly value={locationdetails && locationdetails.city} onChange={(e) => setUsername(e.target.value)}></input>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 input-group" style={{ marginTop: '0.8rem' }}>
                                                                <label className="label">District</label>
                                                                <div className="input-box" >
                                                                    <input type="text" placeholder="Your District" readOnly value={locationdetails && locationdetails.district} onChange={(e) => setUsername(e.target.value)}></input>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 input-group" style={{ marginTop: '0.8rem' }}>
                                                                <label className="label">State</label>
                                                                <div className="input-box" >
                                                                    <input type="text" placeholder="Your State" readOnly value={locationdetails && locationdetails.state} onChange={(e) => setUsername(e.target.value)}></input>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="col-sm-12 input-group " id="last-one" >
                                                        {/* <button className="ph-partner-register" onClick={phpartneregister}>{!phloading ? 'PH Partner Registration' : 'Loading...'}</button> */}
                                                        {/* {userData.role.role_name === 'User' && !ph_request && <button className="ph-partner-register" onClick={phpartneregister}>{!phloading ? 'PH Partner Registration' : 'Loading...'}</button>} */}
                                                        <button className="register" onClick={updateUser} >{!loading ? 'Save' : 'Loading...'}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        {activeStep === 2 && <div>

                                            <div className="fields">
                                                <div className="row">
                                                    <div className="col-sm-12 input-group">
                                                        <label className="label">Secondary Email</label>
                                                        <div className="input-box" >
                                                            <input type="text" placeholder="Enter your registered mail id" value={sec_email}
                                                                onChange={e => handleEmailChange(e, 'sec')} ></input>
                                                            {<span>{err_sec_email}</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 input-group">
                                                        <label className="label">Seconday Mobile Number</label>
                                                        <div className="input-box" >
                                                            <input type="text" placeholder="Enter your mobile number" value={sec_mobile} onChange={e => handleMobileChange(e, 'sec')}></input>
                                                            <span>{err_sec_mobile}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 input-group" id='secondary-sav-btn'>
                                                        <button className="register" onClick={updateUser}>{!loading ? 'Save' : 'Loading...'}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}
                                    </div>
                                </React.Fragment>

                            </div>
                        </Box>
                    </div>

                </DialogContent >
            </BootstrapDialog >
            <BootstrapDialog
                onClose={() => { setOpenDialog(false) }}
                aria-labelledby='customized-dialog-title'
                open={openDialog}
                id='confirm_container'>
                <DialogTitle
                    sx={{ m: 0, p: 1 }}
                    className='confirm-head'>
                    Confirmation
                </DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={() => { setOpenDialog(false) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                </IconButton>
                {modaltype === 'Type' ?
                    <DialogContent
                        dividers
                        style={{ padding: "0px 20px 11px 20px" }}>
                        <p className="confirm-msg">Do you want to Change your User type ?</p>
                        <div>
                            <button
                                className='confirm-btn no-btn'
                                onClick={handlechangetype}>Yes</button>
                            <button
                                className="confirm-btn"
                                onClick={handlecanceltype}>No</button>

                        </div>
                    </DialogContent> :
                    modaltype === 'Sync' ?
                        <DialogContent
                            dividers
                            style={{ padding: "0px 20px 11px 20px" }}>
                            <p className="confirm-msg">Do you want to {userdetails.daily_login_sync === true ? 'Disable' : 'Enable'} {fieldType} ?</p>
                            <div>
                                <button
                                    className='confirm-btn no-btn'
                                    onClick={handlechangeloginsync}>Yes</button>
                                <button
                                    className="confirm-btn"
                                    onClick={handlecanceltype}>No</button>

                            </div>
                        </DialogContent>
                        : <DialogContent
                            dividers
                            style={{ padding: "0px 20px 11px 20px" }}>
                            <p className="confirm-msg">Do you want to Change your Account type ?</p>
                            <div>
                                <button
                                    className='confirm-btn no-btn'
                                    onClick={handleConfirm}>Yes</button>
                                <button
                                    className="confirm-btn"
                                    onClick={() => { setOpenDialog(false) }}>No</button>

                            </div>
                        </DialogContent>}
            </BootstrapDialog >
            <div style={{ marginTop: '1%' }}>
                <Breadcrumbs />
            </div>
            <div id='cover_wrap'>
                <div className='profile_wrap'>
                    <div className='title_wrap'>
                        <span className='title_line'>Your Profile</span>
                        {/* <Tooltip placement="right" title={'Edit Profile'} arrow={true} color={'#424B70'} >
                            <EditIcon className='edit_icn' onClick={() => { setProfilemodal(true) }} />
                        </Tooltip> */}
                    </div>
                    <Avatar className='avatar' > {userData?.user_name.charAt(0) ?? 'Z'}</Avatar>
                    <span className='name_line'>{capitalizeFirstLetter(userData?.user_name)}</span>
                    {detailoading ?
                        <Spin spinning={true} size='default' style={{ maxWidth: '100%', display: 'flex', marginTop: "10%", marginBottom: "10%" }}>
                        </Spin> :
                        <>
                            <span className='type_line'>{capitalizeFirstLetter(userdetails.user_type)}</span>
                            <span className='mail_line'>{capitalizeFirstLetter(userdetails.email)}</span>
                            {sourcecf && !phlogin &&
                                <div className='cf_code-wrap' >
                                    <span style={{ color: '#E96919' }}>Refer Code -</span>
                                    <span><b>{userdetails.cf_code}</b></span>
                                    <Dropdown overlay={renderAddMenu(code_submenu)} >
                                        <ContentCopyIcon fontSize='small' className='clipboard-icon' />
                                    </Dropdown>
                                </div>}
                            {phlogin &&
                                <div className='cf_code-wrap' >
                                    <span style={{ color: '#E96919' }}>Refer Code -</span>
                                    <span><b>{userdetails.ph_code}</b></span>
                                    <Dropdown overlay={renderAddMenu(code_submenu)} >
                                        <ContentCopyIcon fontSize='small' className='clipboard-icon' />
                                    </Dropdown>
                                </div>}
                        </>}
                    {userData.role.role_name !== 'Super Admin' && !userData.is_cf && <button
                        className="cf-partner-register"
                        onClick={() => {
                            if (userdetails?.address) {
                                setOpenDialog(true);
                                setPopuptype("CF");
                            } else {
                                setCfpopup(!cfpopup);
                            }
                        }}
                    >{!cfloading ? 'CF Partner Registration' : 'Loading...'}</button>}
                    {userData.role.role_name === 'User' && !ph_request && <button className="ph-partner-register" onClick={() => { setPhpopup(!phpopup) }}>{!phloading ? 'PH Partner Registration' : 'Loading...'}</button>}
                    {/* <button className="clear-btn" onClick={() => {
                        const clear_query = { clear: 'true' };
                        synccallfunction([], clear_query);
                    }}>{!clearloading ? 'Clear & Sync all' : 'Loading...'}</button> */}
                </div>
                <div id='profile_container'>
                    <React.Fragment>
                        {user.map((field: any) => (
                            <div className='content_wrap' key={field.groupname}>
                                <div className='content_header'>
                                    <span>{field.groupname}</span>
                                    <Tooltip placement="right" title={'Edit Profile'} arrow={true} color={'#424B70'} >
                                        <EditIcon className='edit_icn'
                                            onClick={() => {
                                                setProfilemodal(true);
                                                const step = groupToStepMap[field.groupname];
                                                if (step) {
                                                    setActiveStep(step);
                                                }
                                            }} />
                                    </Tooltip>
                                </div>
                                <div className='content_cover'>
                                    {detailoading &&
                                        <Spin spinning={true} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute', marginTop: '4.5vh', alignSelf: 'center' }}>
                                        </Spin>}
                                    {field.fields.map((field: any) => (
                                        <div className='content_row' key={field.label}>
                                            <div className='label_wrap'>
                                                <span>{field.label}&nbsp;:</span>
                                            </div>
                                            <div className='field_wrap'>
                                                {renderContent(field)}
                                            </div>
                                        </div>))}
                                </div>
                            </div>
                        ))}
                    </React.Fragment>
                </div>
            </div >
            < NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType} ></NotifyAlert >
        </>
    )
}