// react 
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// .........

// scss file
import './phdashboard.scss';
// .........

// package components
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs'
// .........

// child components
import { useGlobalState } from '../../Statecontext';
import Phchart from '../../components/chart/phchart';
// .........

// icons
import arrowRightIcon from '../../assets/images/chevron-right.svg'
import { Button, Form, Modal, Spin, Table, Tag, DatePicker as AntDatePicker, } from 'antd';
import { ZTable2 } from '../../components/table2';
import { serviceConfig } from '../../core/services';
import editIcon from '../../assets/images/editIcon.svg'
import { TextField } from '@mui/material';
import { NotifyAlert } from '../../components/notify_alert';
// .........

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export const Phdashboard = () => {

    // form values states
    const [form] = Form.useForm();
    const [sel_task_no, setSelecttaskno] = useState<any>([]);
    const [statustype, setStatustype] = useState<string>('NOT Yet Started');
    const [noticedate, setNoticedate] = useState<any>(null)
    const [ph_partner_code, setPh_partner_code] = useState<any>('');
    // .........

    // data states
    const [gstCounts, setGstCounts] = useState<any>(null)
    const [noticeData, setNoticeData] = useState<any>([]);
    const [actionType, setActionType] = useState("")
    const { filterQuery, setFilterQuery, totalpages, setTotalpages, selectedGstRows, setStatuschange, statuschange } = useGlobalState();
    // .........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('');
    const [formopen, setFormopen] = useState(false)
    // .........

    // loading state
    const [loading, setLoading] = useState(false);
    const [formloading, setFormloading] = useState(false);
    // .........

    // limit & filter states
    const gstTableElement = useRef<any>(null);
    // .........

    // reference states
    const navigate = useNavigate()

    useEffect(() => {
        getGSTCounts()
        getPhnotices()
    }, []);

    // data get function
    let user_data = JSON.parse(localStorage.getItem('userData') || '')

    const getGSTCounts = useCallback(() => {
        let query: any = {
            "partner_id": user_data.ph_code
        }
        serviceConfig.get("ph_counts", true, query, null).then((data: any) => {
            setGstCounts(data)
        }, (err: any) => {
            if (err.message === "Request failed with status code 401") {
                navigate('/login')
                localStorage.clear()
                window.location.reload();
            }
        })
    }, [])

    const getPhnotices = () => {

        setLoading(true)
        let query: any = {
            "partner_id": user_data.ph_code
        }

        serviceConfig.get("ph_ticket", true, query, null).then((data: any) => {
            setTotalpages(data.data.count)
            setNoticeData(data.data.tickets)
            setLoading(false)
        }, (err: any) => {
            setLoading(false)
        })
    }
    // ............

    // Edit data function

    const handleAction = (type?: string, data?: any) => {
        setActionType(type || 'add')
        if (type === 'Edit') {
            setSelecttaskno([data.id.toString()])
            if (data.custom_fields.cf_next_visit_date) {
                const validDate = dayjs(data.custom_fields.cf_next_visit_date);
                if (validDate.isValid()) {
                    // Format date using Dayjs
                    const formattedDate = validDate.format('YYYY-MM-DD');
                    setNoticedate(formattedDate);
                } else {
                    console.error('Invalid date');
                }
            }
        }
        setFormopen(true)
    }

    const userType: any = [
        'Completed',
        'To visit again',
        'NOT Yet Started',
    ]

    const handlechange = (event: any) => {
        const { value } = event.target;
        setStatustype(value)
    };


    const handlenoticeDateChange = (date: dayjs.ConfigType | null) => { // Use dayjs.ConfigType instead of Moment
        if (date) {
            // Convert Moment to Dayjs
            const validDate = dayjs(date);
            if (validDate.isValid()) {
                // Format date using Dayjs
                const formattedDate = validDate.format('YYYY-MM-DD');
                setNoticedate(formattedDate);
            } else {
                console.error('Invalid date');
            }
        } else {
            setNoticedate(null);
        }
    };

    const updatetask = (tasks?: any) => {
        setFormloading(true)
        let body: any = {
            cf_ph_partner_id: user_data.ph_code,
            cf_status_of_ph_task: statustype,
            cf_next_visit_date: noticedate
        }
        if (tasks) {
            body["id"] = tasks
        } else {
            body["id"] = sel_task_no
        }

        serviceConfig.put('update_ticket', true, body, null).then((res: any) => {
            if (res.message === "Tickets assigned successfully.") {
                setNotifyOpen(true)
                setNotifyType('success')
                setNotifyMessage("Ticket updated successfully.")
            } else if (res.message) {
                setNotifyOpen(true)
                setNotifyType('info')
                setNotifyMessage(res.message)
            }
            setFormloading(false)
            setFormopen(false);
            setNoticedate(null)
            setStatuschange(false)
            getGSTCounts()
            getPhnotices()
        },
            (err: any) => {
                console.error("Error:", err);
                setNotifyType("error")
                setNotifyMessage(err.message || "An error occurred")
                setNotifyOpen(true)
                setFormloading(false)
            }
        )
    }
    const updatetasks = () => {
        const idArray: string[] = selectedGstRows.map((obj: { id: any }) => obj.id.toString());
        updatetask(idArray)
    }
    // ...........

    // filter adding from chart and other datas function
    const setqueryvalue = (query: any) => {
        const updatedQuery = {
            ...filterQuery,
            gst_no: null,
            tax_amount: null,
            tax_period: null,
            trade_name: null,
            notice_status: [query]
        };
        setFilterQuery(updatedQuery)
    }

    const handleChartLabelClick = (label: string) => {
        const updatedQuery = {
            ...filterQuery,
            gst_no: null,
            tax_amount: null,
            tax_period: null,
            trade_name: null,
            notice_status: [label]
        };
        setFilterQuery(updatedQuery)
    };
    // ............

    // card data details
    const dash_cards = [
        {
            label: 'Total pending tasks',
            theme: 'theme1',
            icon: require('../../assets/PH_Register/pending-task.png'),
            value: gstCounts && gstCounts.task_status_counts.Assigned ? gstCounts.task_status_counts.Assigned : '0'
        },
        {
            label: 'CGST',
            theme: 'theme2',
            icon: require('../../assets/PH_Register/CGST.png'),
            value: gstCounts && gstCounts.authority_counts.CGST ? gstCounts.authority_counts.CGST : '0'
        },
        {
            label: 'SGST',
            theme: 'theme3',
            icon: require('../../assets/PH_Register/SGST.png'),
            value: gstCounts && gstCounts.authority_counts.SGST ? gstCounts.authority_counts.SGST : '0'
        },
        {
            label: 'Registration Cancellation',
            theme: 'theme4',
            icon: require('../../assets/PH_Register/registration-cancel.png'),
            value: gstCounts && gstCounts.ph_type_counts["Registration or Cancellation"] ? gstCounts.ph_type_counts["Registration or Cancellation"] : '0'
        },
        {
            label: 'Submission of details',
            theme: 'theme5',
            icon: require('../../assets/PH_Register/submission.png'),
            value: gstCounts && gstCounts.ph_type_counts["Submission of details"] ? gstCounts.ph_type_counts["Submission of details"] : '0'
        },
        {
            label: 'Notice related PH',
            theme: 'theme6',
            icon: require('../../assets/PH_Register/notice_ph.png'),
            value: gstCounts && gstCounts.ph_type_counts["Notice related PH"] ? gstCounts.ph_type_counts["Notice related PH"] : '0'
        },
        {
            label: 'Appeal related PH',
            theme: 'theme7',
            icon: require('../../assets/PH_Register/appeal.png'),
            value: gstCounts && gstCounts.ph_type_counts["Appeal related PH"] ? gstCounts.ph_type_counts["Appeal related PH"] : '0'
        },
        {
            label: 'Inspection / Audit',
            theme: 'theme8',
            icon: require('../../assets/PH_Register/Ins_Aud.png'),
            value: gstCounts && gstCounts.ph_type_counts["Inspection or Audit"] ? gstCounts.ph_type_counts["Inspection or Audit"] : '0'
        },
    ]
    // .............

    // button details props
    const buttondetails: any = []
    // .............

    const Column = [
        // {
        //     title: 'S.No',
        //     dataIndex: 'serial',
        //     key: 'serial',
        //     render: (text: any, record: any, index: number) => index + 1,
        //     width: 10
        // },
        {
            title: 'Task Id',
            dataIndex: 'id',
            maxWidth: 180,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
            width: 180
        },
        {
            title: 'PH Type',
            dataIndex: ['custom_fields', 'cf_type_of_ph'],
            key: 'cf_type_of_ph',
            maxWidth: 180,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
            width: 180
        },
        {
            title: 'CGST / SGST',
            dataIndex: ['custom_fields', 'cf_ph_authority'],
            key: 'cf_ph_authority',
            maxWidth: 180,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
            width: 180
        },
        {
            title: 'Due date',
            dataIndex: ['custom_fields', 'cf_due_date_to_reply_to_notice'],
            key: 'cf_due_date_to_reply_to_notice',
            maxWidth: 120,
            width: 120,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'Last visit date',
            dataIndex: ['custom_fields', 'cf_last_visited_date'],
            key: 'type',
            maxWidth: 160,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
            width: 160
        },
        {
            title: 'Next visit date',
            dataIndex: ['custom_fields', 'cf_next_visit_date'],
            key: 'cf_notice_date',
            maxWidth: 120,
            width: 120,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'Client name',
            dataIndex: ['custom_fields', 'cf_trade_name'],
            key: 'cf_trade_name',
            maxWidth: 200,
            render(value: any, record: any, index: any) {
                return (
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                        {value && value.length > 25 ? value.substr(0, 25) + '...' : value ? value : '--'}
                    </span>
                )
            },
            width: 200
        },
        {
            title: 'Status',
            dataIndex: ['custom_fields', 'cf_status_of_ph_task'],
            key: 'cf_status_of_ph_task',
            maxWidth: 180,
            width: 180,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: "Action",
            dataIndex: "sync_status",
            fixed: "right",
            render(value: any, record: any, index: any) {
                return (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8%", fontSize: "20px", color: "#00000045", cursor: "default" }} >
                        <img src={editIcon}
                            alt='password icon'
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleAction("Edit", record);
                                setPh_partner_code(record.ph_code)
                            }
                            } />
                    </div>
                )
            }
        },
        Table.SELECTION_COLUMN,
    ];

    return (
        <>
            <Modal
                title={actionType === 'add' ? "ADD TASK" : `EDIT TASK : ${sel_task_no}`}
                onCancel={() => { setFormopen(false); setFormloading(false); setNoticedate(null) }}
                width={'30%'}
                className='action-popup'
                centered
                style={{ color: "#031742" }}
                open={formopen}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <div className="fields">
                    <div className="row">
                        <div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
                            <label className="label">Status</label>
                            <TextField
                                fullWidth
                                focused
                                select
                                SelectProps={{ native: true, }}
                                variant='outlined'
                                name="casetype"
                                value={statustype}
                                onChange={handlechange}
                                autoComplete='off'
                                style={{ margin: '2% 0' }}
                            >
                                <option value='' disabled>...</option>
                                {userType.map((type: any) =>
                                    <option key={type} value={type}>{type}</option>
                                )}
                            </TextField>
                        </div>
                        <div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
                            <label className="label">Next visit date</label>
                            <AntDatePicker
                                value={noticedate ? dayjs(noticedate, 'YYYY-MM-DD') : null}
                                onChange={
                                    (date, dateString: any) =>
                                        handlenoticeDateChange(dateString)
                                }
                                style={{
                                    height: '45px',
                                    width: '100%',
                                    border: '1px solid rgba(0, 0, 0, 0.13)',
                                    marginLeft: 0,
                                    marginTop: "2%"
                                }}
                                placeholder='Next visit date' />
                        </div>

                        <Button
                            htmlType="submit"
                            className='popup-button'
                            loading={formloading}
                            style={{ background: "#424B70", color: "white", border: "none", width: "60%", height: "6vh", marginTop: '1.5rem', left: '21%' }}
                            onClick={() => updatetask()}>
                            {!formloading && <>{actionType === 'add' ? 'Add' : 'Update'} Task</>}
                            {formloading && <>Loading...</>}
                        </Button>

                    </div>
                </div>
            </Modal>
            <Modal
                title={actionType === 'add' ? "ADD TASK" : `EDIT TASKS`}
                onCancel={() => { setStatuschange(false); setFormloading(false); }}
                width={'30%'}
                className='action-popup'
                centered
                style={{ color: "#031742" }}
                open={statuschange}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <div className="fields">
                    <div className="row">
                        <div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
                            <label className="label">Status</label>
                            <TextField
                                fullWidth
                                focused
                                select
                                SelectProps={{ native: true, }}
                                variant='outlined'
                                name="casetype"
                                value={statustype}
                                onChange={handlechange}
                                autoComplete='off'
                                style={{ margin: '2% 0' }}
                            >
                                <option value='' disabled>...</option>
                                {userType.map((type: any) =>
                                    <option key={type} value={type}>{type}</option>
                                )}
                            </TextField>
                        </div>
                        <div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
                            <label className="label">Next visit date</label>
                            <AntDatePicker
                                value={noticedate ? dayjs(noticedate, 'YYYY-MM-DD') : null}
                                onChange={
                                    (date, dateString: any) =>
                                        handlenoticeDateChange(dateString)
                                }
                                style={{
                                    height: '45px',
                                    width: '100%',
                                    border: '1px solid rgba(0, 0, 0, 0.13)',
                                    marginLeft: 0,
                                    marginTop: "2%"
                                }}
                                placeholder='Next visit date' />
                        </div>

                        <Button
                            htmlType="submit"
                            className='popup-button'
                            loading={formloading}
                            style={{ background: "#424B70", color: "white", border: "none", width: "60%", height: "6vh", marginTop: '1.5rem', left: '21%' }}
                            onClick={() => updatetasks()}>
                            {!formloading && <>{actionType === 'add' ? 'Add' : 'Update'} Tasks</>}
                            {formloading && <>Loading...</>}
                        </Button>

                    </div>
                </div>
            </Modal>
            <div className='ph-dashboard-container'>
                <div className='ph-dashboard-data-cover'>
                    <div className='ph-data-cards'>
                        {dash_cards.map((card: any, index: any) => (
                            <div className={`data-card-wrap ${card.theme}`} key={index} >
                                <p style={{ color: 'rgba(102, 102, 102, 1)', fontWeight: 'bold' }}>{card.label}</p>
                                <div className='value-icn-wrap'>
                                    <img alt='icon' src={card.icon} />
                                    <span>{card.value}</span>
                                </div>
                            </div>))}
                    </div>
                    <div className='ph-chart-wrap'>
                        <div className="stats-box" >
                            {gstCounts ?
                                <>
                                    <div className="gst-chart">
                                        <Phchart
                                            className='chart'
                                            counts={[
                                                gstCounts && gstCounts.task_status_counts["Completed"] ? gstCounts.task_status_counts["Completed"] : '0',
                                                gstCounts && gstCounts.task_status_counts["To visit again"] ? gstCounts.task_status_counts["To visit again"] : '0',
                                                gstCounts && gstCounts.task_status_counts["NOT Yet Started"] ? gstCounts.task_status_counts["NOT Yet Started"] : '0',
                                            ]}
                                            onLabelClick={handleChartLabelClick} />
                                    </div>
                                    <div className="chart-stat">
                                        <div className="stat-item" onClick={() => setqueryvalue('Completed')}>
                                            <div className="stat-sub">
                                                <span className="dot" style={{ backgroundColor: "#0AB39C" }}></span>
                                                <p>Completed</p>
                                            </div>
                                            <div className="stat-subs">
                                                <span style={{ color: '#000' }}>{gstCounts && gstCounts.task_status_counts["Completed"] || 0}</span>
                                                <img src={arrowRightIcon} alt="" />

                                            </div>

                                        </div>
                                        <div className="stat-item" onClick={() => setqueryvalue('To visit again')}>
                                            <div className="stat-sub">
                                                <span className="dot" style={{ backgroundColor: "#299CDB" }}></span>
                                                <p>To visit again </p>
                                            </div>
                                            <div className="stat-subs">
                                                <span style={{ color: '#000' }}>{gstCounts && gstCounts.task_status_counts["To visit again"] || 0}</span>
                                                <img src={arrowRightIcon} alt="" />

                                            </div>

                                        </div>
                                        <div className="stat-item" onClick={() => setqueryvalue('NOT Yet Started')}>
                                            <div className="stat-sub">
                                                <span className="dot" style={{ backgroundColor: "#F06548" }}></span>
                                                <p>NOT Yet Started</p>
                                            </div>
                                            <div className="stat-subs">
                                                <span style={{ color: '#000' }}>{gstCounts && gstCounts.task_status_counts["NOT Yet Started"] || 0}</span>
                                                <img src={arrowRightIcon} alt="" />

                                            </div>

                                        </div>
                                    </div>
                                </> :
                                <Spin spinning={true} size='default' >
                                </Spin>
                            }

                        </div>
                    </div>
                </div>
                <ZTable2
                    label={'PH Dashboard'}
                    button={buttondetails}
                    columns={Column}
                    expand={false}
                    ref={gstTableElement}
                    tabledata={noticeData}
                    loading={loading}
                    totalpages={totalpages} />
            </div >
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>

    )
}