import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../Statecontext';
import './breadcrumb.scss';
import { Utils } from '../../core/utils/utils';

const Breadcrumbs: React.FC = () => {
    const { setDashbordarticle, setSearchquery, setGlobalsearch, breadcrumbPath, setMenuclick, setNoticeQuery, setBreadcrumbPath, setSelectgst, setFilterQuery, setSorterQuery, setPage, setTotalpages, setTabactive, setManageactive, setRowsPerPage } = useGlobalState();
    const { menuClick } = Utils();
    const navigate = useNavigate()
    // Split the breadcrumb path into segments and generate breadcrumb links
    const breadcrumbSegments = breadcrumbPath.split('/').filter((segment: any) => segment);

    const clearsearchquery = () => {
        setGlobalsearch('')
        setSearchquery('')
    }

    const nav_btns: any = [
        { name: 'notices', path: '/home/allnotice' },
        { name: 'Latest notices', path: '/home/allnotice' },
        { name: 'Action taken', path: '/home/allnotice' },
        { name: 'Action to be taken', path: '/home/allnotice' },
        { name: 'No action required', path: '/home/allnotice' },
        { name: 'Overdue', path: '/home/allnotice' },
        { name: 'Manual notices', path: '/home/allnotice' },
        { name: 'All notices', path: '/home/allnotice' },
        { name: 'GSTIN wise notices', path: '/home/allgstin' },
        { name: 'gstin wise notices', path: '/home/allgstin' },
        { name: 'Incorrect details', path: '/home/allgstin' },
        { name: 'incorrect details', path: '/home/allgstin' },
        { name: 'services', path: '/home/solution-dashboard' },
        { name: 'Solutions', path: '/home/solution-dashboard' },
        { name: 'GST loans', path: '/home/gst-loan' },
        { name: 'Latest updates', path: '/home/latestupdates' },
        { name: 'Sync monitoring', path: '/home/syncmonitoring' },
        { name: 'compliance', path: '/home/compliance' },
        { name: 'Compliance monitor', path: '/home/compliance' },
        { name: 'users', path: '/home/users' },
        { name: 'Users list', path: '/home/users' },
        { name: 'Users approval', path: '/home/userapproval' },
        { name: 'PH partners', path: '/home/phpartners' },
    ]


    const blockclick = (item: string) => {
        console.log(item, 'segment');

        const cleanItem = item.split(" - ")[0].trim();
        const menuItem = nav_btns.find((btn: any) => btn.name === cleanItem);
        console.log(cleanItem, 'menu');



        // Regex pattern to match GSTIN format (validating 15-character GSTIN)
        const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;

        if (gstinRegex.test(item)) {
            let query: any = {
                gst_no: [item]
            };
            setFilterQuery(query);
            navigate('/home/overview');
            setBreadcrumbPath(`/home/notices/GSTIN wise notices/`)

        } else if (!menuItem) {
            console.warn("Menu item not found!");
            return;
        } else {
            navigate(menuItem.path);
            setSelectgst(null);
        }
        setDashbordarticle({});
        setFilterQuery({});
        setSorterQuery({});
        clearsearchquery();
        setPage(1);
        setTotalpages(0);
        setRowsPerPage(10);

        // Define mappings for menu click, tab active state, and breadcrumb paths
        const menuConfig: Record<string, { tab?: string; breadcrumb: string; noticeQuery?: Record<string, any> }> = {
            "Incorrect details": { tab: "All", breadcrumb: "home/notices/incorrect details" },
            "GSTIN wise notices": { tab: "GSTIN Summary", breadcrumb: "home/notices/GSTIN wise Notices" },
            "All notices": { tab: "Additional", breadcrumb: "home/notices/all notices", noticeQuery: { is_over_due: false } },
            "notices": { tab: "Additional", breadcrumb: "home/notices/all notices", noticeQuery: { is_over_due: false } },
            "Latest notices": { tab: "Latest", breadcrumb: `home/notices/${item}`, noticeQuery: { is_over_due: false } },
            "Action to be taken": { tab: "Additional", breadcrumb: `home/notices/${item}`, noticeQuery: { notice_status: [item], is_over_due: false } },
            "Action taken": { tab: "Additional", breadcrumb: `home/notices/${item}`, noticeQuery: { notice_status: [item], is_over_due: false } },
            "No action required": { tab: "Additional", breadcrumb: `home/notices/${item}`, noticeQuery: { notice_status: [item], is_over_due: false } },
            "Overdue": { tab: "Additional", breadcrumb: `home/notices/${item}`, noticeQuery: { is_over_due: true } },
            "Manual notices": { tab: "Manual", breadcrumb: `home/notices/${item}`, noticeQuery: { is_over_due: false } },
            "Latest updates": { breadcrumb: `home/services/${item}` },
            "Sync monitoring": { breadcrumb: `home/services/${item}` },
            "users": { breadcrumb: "home/users/Users list" }
        };

        // User-related menu items
        const userRelatedNames = ["Users list", "Users approval", "PH partners"];
        if (userRelatedNames.includes(item)) {
            setBreadcrumbPath(`home/users/${item}`);
            return;
        }

        // Apply settings based on menuConfig
        if (menuConfig[item]) {
            const { tab, breadcrumb, noticeQuery } = menuConfig[item];
            if (tab) setTabactive(tab);
            setBreadcrumbPath(breadcrumb);
            if (noticeQuery) setNoticeQuery(noticeQuery);
            setMenuclick(item === 'notices' ? 'All notices' : item);
        }
    };
    return (
        <nav className="breadcrumb">
            <ul>
                {breadcrumbSegments.map((segment: any, index: any) => {
                    const path = `/${breadcrumbSegments.slice(0, index + 1).join('/')}`;
                    return (
                        <li key={path}>
                            {index === 0 ? (
                                <span
                                    // className='last'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        navigate('/home/gst-dashboard');
                                        menuClick()
                                    }}>
                                    {segment}
                                </span>
                            ) : index < breadcrumbSegments.length - 1 ? (
                                <span style={{ cursor: 'pointer' }} onClick={() => { blockclick(segment) }}>{segment}</span>
                            ) : (
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => { blockclick(segment) }}
                                    className='last'>
                                    {segment}
                                </span>
                            )}
                        </li>
                    );
                })}
            </ul>
        </nav >
    );
};

export default Breadcrumbs;
