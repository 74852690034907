// react 
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// ..........

// scss file
import './gstloan.scss';
import { Button, Radio, Spin } from 'antd';
// ..........

// package components
import YouTube from 'react-youtube';
import { styled } from '@mui/material/styles';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, IconButton, RadioGroup } from '@mui/material';
// ..........

// child components
import Fileupload from './fileupload';
// ..........

// icons
import closeIcon from '../../assets/register/close.png'
import open_logo from '../../assets/images/open-capital.svg'
import { serviceConfig } from '../../core/services';
import { NotifyAlert } from '../../components/notify_alert';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Loanlist from './loanlist';
import { useGlobalState } from '../../Statecontext';
import Breadcrumbs from '../../shared/Breadcrumb';
// .........

// Custom dialog component
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
// .........

interface FilesState {
    [key: string]: File[];
}

export const Gstloan = () => {

    // form values states
    const userRole = localStorage.getItem('userRole') || '';
    const [currenttab, setCurrentab] = useState(userRole !== 'Super Admin' ? 'Sole Proprietor' : 'Applied Loans');
    const [selectedValue, setSelectedValue] = React.useState('Partnership Firm');
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [newValue, setNewValue] = useState<string>('');

    const { setBreadcrumbPath } = useGlobalState()

    const fieldsToShow = selectedValue === 'Partnership Firm'
        ? ['Consent letter for CIBIL',
            'PAN',
            'Business rent agreement (If owned, latest e-bill) GST certificate and Udyam Certificate',
            'Bank Statement for the latest 6 months (OD/CC/CA)',
            'Partnership Deed (Registered)',
            'Sanction letter For OD/CC (if any)',
            'Selfie / Photo',
            'Selfie / Photo of atleast 2 Directors holding more than 51% SH',
            'POI (PAN)',
            'POA (Aadhar) of atleast 2 Partners holding more than 51% SH',
            'POI (PAN) of atleast 2 Partners holding more than 51% SH',
            'Beneficiery Owners: PAN, Aadhar & selfie (Person holding >=10% share in share in capital and profit)',
            'Last 2 year"s Financials with current year"s Provisional financials/ledgers']
        : ['Consent letter for CIBIL',
            'PAN',
            'Business rent agreement (If owned, latest e-bill) GST certificate and Udyam Certificate',
            'Bank Statement for the latest 6 months (OD/CC/CA)',
            'AOA, MOA, COI',
            'Sanction letter For OD/CC (if any)',
            'Latest Shareholding pattern (Certified), LOD',
            'Selfie / Photo of atleast 2 Directors holding more than 51% SH',
            'POA (Aadhar) of atleast 2 Directors holding more than 51% SH',
            'POI (PAN) of atleast 2 Directors holding more than 51% SH',
            'Beneficiery Owners: PAN, Aadhar & selfie (Person holding >=10% share in share in capital and profit)'];

    const initialFilesState = fieldsToShow.reduce((acc, field) => {
        acc[field] = [];
        return acc;
    }, {} as { [key: string]: File[] });

    const [filesState, setFilesState] = useState<{ [key: string]: File[] }>(initialFilesState);
    const [loanlist, setLoanlist] = useState([])
    const [taskcollapse, setTaskcollapse] = useState(true)
    // ......

    // loading state
    const [loading, setLoading] = useState(false);
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    // ......

    useEffect(() => {
        setBreadcrumbPath('home/services/gst loans')
        getLoanlist()
    }, [])

    // reference states
    const fileInputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});
    // ......

    // header tab details
    const avail_tabs = [
        {
            name: 'Applied Loans'
        },

    ];

    if (userRole !== 'Super Admin') {
        avail_tabs.push(
            {
                name: 'Sole Proprietor',
            },
            {
                name: 'Partnership Firm/Company',
            },
        )
    }
    // ......

    // web page config
    const pageUrl = "https://app.opencapital.co.in/en/onboarding/register?utm_source=zentaxclinic&utm_campaign=zentax_partner&ref=zentaxclinic";
    const newtabopen = () => {
        window.open(pageUrl, 'Open capital', 'noopener,noreferrer');
    }
    // ......

    // form value function 
    // ................. Upload function
    const handleFilesSelected = (field: string, files: FileList) => {
        setFilesState((prevState) => {
            return {
                ...prevState,
                [field]: [...(prevState[field] || []), ...Array.from(files)]
            };
        });
    };


    const handleRemoveFile = (field: string, index: number) => {
        setFilesState((prevState) => {
            return {
                ...prevState,
                [field]: prevState[field].filter((_, i) => i !== index)
            };
        });
    };

    const fieldMappings: { [key: string]: string } = {
        'Consent letter for CIBIL': 'cibil_letter',
        'PAN': 'pan_doc',
        'Business rent agreement (If owned, latest e-bill) GST certificate and Udyam Certificate': 'business_rent_agreement',
        'Bank Statement for the latest 6 months (OD/CC/CA)': 'bank_statement',
        'Partnership Deed (Registered)': 'partnership_deed',
        'Sanction letter For OD/CC (if any)': 'sanction_letter',
        'Selfie / Photo': 'selfie_photo',
        'Selfie / Photo of atleast 2 Directors holding more than 51% SH': 'selfie_photo_directors',
        'POI (PAN)': 'poi_pan_ph',
        'POA (Aadhar) of atleast 2 Partners holding more than 51% SH': 'poa_adhaar_ph',
        'POI (PAN) of atleast 2 Partners holding more than 51% SH': 'poi_pan_dh',
        'POA (Aadhar) of atleast 2 Directors holding more than 51% SH': 'poa_adhaar_directors',
        'POI (PAN) of atleast 2 Directors holding more than 51% SH': 'poi_pan_directors',
        'Beneficiery Owners: PAN, Aadhar & selfie (Person holding >=10% share in share in capital and profit)': 'beneficiary_owner',
        'Last 2 year"s Financials with current year"s Provisional financials/ledgers': 'ledgers',
        'AOA, MOA, COI': 'aoa_moa_coi',
        'Latest Shareholding pattern (Certified), LOD': 'sharehold_loc'
    };

    const createFormData = () => {
        const formData = new FormData();

        // Iterate over the keys of filesState
        Object.keys(filesState).forEach((key) => {
            const files = filesState[key];
            const apiFieldName = fieldMappings[key];

            files.forEach((file, index) => {
                // Append each file to the FormData object
                formData.append(apiFieldName, file, file.name);
            });
        });
        let user_data = JSON.parse(localStorage.getItem('userData') || '')

        formData.append('user', user_data.id);

        return formData;
    };

    const validateFiles = (): boolean => {
        for (let key of fieldsToShow) {
            if (!filesState[key] || filesState[key].length === 0) {
                return false;
            }
        }
        return true;
    };

    const fileSubmit = () => {

        if (!validateFiles()) {
            setNotifyType("warning")
            setNotifyMessage("Kindly upload all the documents.")
            setNotifyOpen(true)
            return;
        }
        else {

            setLoading(true)
            const req_body = createFormData();

            serviceConfig.post("gst_loan_add", true, req_body, null, null, false).then(
                (data: any) => {
                    setLoading(false)
                    Object.keys(fileInputRefs.current).forEach(key => {
                        if (fileInputRefs.current[key]) {
                            fileInputRefs.current[key]!.value = '';
                        }
                    });
                    initialstate()
                    setNotifyType("success")
                    setNotifyMessage("Loan application submitted successfully.")
                    setNotifyOpen(true)
                }).catch((err: any) => {
                    setLoading(false)
                })
        }
    }
    // ..............

    // radio switch function 
    const handleChange = (event: any) => {
        const newTypeValue = (event.target as HTMLInputElement).value;

        // Check if any files are uploaded
        const anyFilesUploaded = Object.values(filesState).some(filesArray => filesArray.length > 0);

        if (anyFilesUploaded) {
            setNewValue(newTypeValue);
            setOpenDialog(true);
        } else {
            setSelectedValue(newTypeValue);
        }
    };

    const initialstate = () => {
        setFilesState({
            'Consent letter for CIBIL': [],
            'PAN': [],
            'Busienss rent agreement (If owned, latest e-bill) GST certificate and Udyam Certificate': [],
            'Bank Statement for the latest 6 months (OD/CC/CA)': [],
            'Partnership Deed (Registered)': [],
            'AOA, MOA, COI': [],
            'Sanction letter For OD/CC (if any)': [],
            'Latest Shareholding pattern (Certified), LOD': [],
            'Selfie / Photo': [],
            'Selfie / Photo of atleast 2 Directors holding more than 51% SH': [],
            'POI (PAN)': [],
            'POA (Aadhar) of atleast 2 Directors holding more than 51% SH': [],
            'POI (PAN) of atleast 2 Directors holding more than 51% SH': [],
            'POA (Aadhar) of atleast 2 Partners  holding more than 51% SH': [],
            'POI (PAN) of atleast 2 Partners holding more than 51% SH': [],
            'Beneficiery Owners: PAN, Aadhar & selfie (Person holding >=10% share in share in capital and profit)': [],
            'Last 2 year"s Financials with current year"s Provisional financials/ledgers': []
        });
    }

    const handleConfirm = () => {
        setSelectedValue(newValue);
        initialstate()
        setOpenDialog(false);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const user_data = JSON.parse(localStorage.getItem('userData') || '');

    const getLoanlist = () => {
        setLoading(true)
        let query: any = {
            user: user_data.id
        }

        serviceConfig.get("gst_loan", true, userRole !== 'Super Admin' ? query : null, null).then((data: any) => {
            setLoading(false)
            setLoanlist(data)
        }).catch((err: any) => {
            setLoading(false)
        })
    }
    //   ......

    return (
        <>
            <div style={{ marginTop: '1%' }}>
                <Breadcrumbs />
            </div>
            <div id='avail-gst-dashboard'>
                <div className='avail-menu-container'>
                    {avail_tabs.map((button: any) => (
                        <Button onClick={() => {
                            setCurrentab(button.name);
                            initialstate();
                            setSelectedValue('Partnership Firm');
                            setLoanlist([])
                            { button.name === 'Applied Loans' && getLoanlist() }
                        }} className={currenttab === button.name ? 'menu_btn menu_btn_active' : 'menu_btn'}>{button.name}</Button>
                    ))}
                </div>
                <div className='avail-content-container'>
                    {
                        currenttab === 'Applied Loans' &&
                        <div className='applied-loans-cover'>
                            <Loanlist
                                Loading={loading}
                                data={loanlist}
                                func={getLoanlist}
                            />
                        </div>
                    }
                    {
                        currenttab === 'Sole Proprietor' &&
                        <div className='sole-poprietor-cover'>
                            <span style={{color:'black'}}>Click the below link to apply</span>
                            <img src={open_logo} alt='Open_Capital' onClick={() => { newtabopen() }} style={{ marginTop: '1%', cursor: 'pointer',color:'black' }} />
                        </div>
                    }
                    {
                        currenttab === 'Partnership Firm/Company' &&
                        <div className='corporate-container'>
                            {loading && <Spin spinning={true} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute', marginTop: "35vh" }}></Spin>}
                            <div className='form-header'>
                                <span className='head-line'>Loan is powered by</span>
                                <img src={open_logo} alt='open_logo' onClick={() => { newtabopen() }} style={{ marginLeft: '1%', cursor: 'pointer', boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.2)' }} />
                            </div>
                            <div className='field-list-wrap'>
                                <div className='field-cover'>
                                    <FormLabel className="field-type-label">Type</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-form-control-label-placement"
                                        className='radio-grp'
                                    >
                                        <FormControlLabel
                                            className='first-radio'
                                            value="Partnership Firm"
                                            control={
                                                <Radio
                                                    checked={selectedValue === 'Partnership Firm'}
                                                    onChange={handleChange}
                                                    value="Partnership Firm"
                                                    name="radio-buttons"
                                                />}
                                            label="Partnership Firm" />
                                        <FormControlLabel
                                            value="Company"
                                            control={
                                                <Radio
                                                    checked={selectedValue === 'Company'}
                                                    onChange={handleChange}
                                                    value="Company"
                                                    name="radio-buttons"
                                                />}
                                            label="Company" />
                                    </RadioGroup>
                                </div>
                                {fieldsToShow.map((field, index) => (
                                    <Fileupload
                                        key={field}
                                        serialNumber={index + 1}
                                        label={field}
                                        selectedFiles={filesState[field]}
                                        onFilesSelected={handleFilesSelected}
                                        onRemoveFile={handleRemoveFile}
                                    />
                                ))}
                                <div className='submit-footer'>
                                    <Button disabled={loading} className='clear_btn' onClick={() => initialstate()}>Clear</Button>
                                    <Button disabled={loading} className='submit_btn' onClick={fileSubmit}>Submit</Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div >
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby='customized-dialog-title'
                open={openDialog}
                id='confirm_container'>
                <DialogTitle
                    sx={{ m: 0, p: 1 }}
                    className='confirm-head'>
                    Confirmation
                </DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                </IconButton>
                <DialogContent
                    dividers
                    style={{ padding: "0px 20px 11px 20px" }}>
                    <p className="confirm-msg">Switching the type will clear all the uploaded files. Do you want to proceed?</p>
                    <div>
                        <button
                            className='confirm-btn no-btn'
                            onClick={handleConfirm}>Yes</button>
                        <button
                            className="confirm-btn"
                            onClick={handleClose}>No</button>

                    </div>
                </DialogContent>
            </BootstrapDialog>
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}