// react 
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// ..........

// scss file
import './landing.scss';
// ..........

// package components
import {
    Directions,
    FloatingMenu,
    MainButton
} from "react-floating-button-menu";
import Drawer from '@mui/material/Drawer';
import { Box, Button, TextField } from '@mui/material';
import { useGlobalState } from '../../Statecontext';
// .............

// icon components
import FeedbackIcon from '@mui/icons-material/Feedback';
import CloseIcon from '@mui/icons-material/Close';
import { serviceConfig } from '../../core/services';
import { NotifyAlert } from '../../components/notify_alert';
import SendIcon from '@mui/icons-material/Send';
// .............

export const Landing = () => {

    // form values state
    const [comment, setComment] = useState('');
    // .......

    // alert or modal state
    const [footer, setFooter] = useState(false);
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    // .......

    // loading state
    const [loading, setLoading] = useState(false);
    // .......

    // reference state
    let location = useLocation();
    const { feedbackOpen, setFeedbackOpen } = useGlobalState()
    const userData = JSON.parse(localStorage.getItem('userData') || '{}')
    // .......

    // privacy details page navigation from comments section
    const openprivacy = () => {
        const url = 'https://qa.solutions.zentaxclinic.com/#/privacy-policy';
        window.open(url, '_blank');
    }
    // ......

    useEffect(() => {
        if (location.pathname === '/' ||
            location.pathname === '/login' ||
            location.pathname === '/forgot-password' ||
            location.pathname === '/register' ||
            location.pathname === '/privacy-policy') {
            setFooter(false)
        } else {
            setFooter(true)
        }
    }, [location.pathname])

    // Comment submission
    const commentSubmit = () => {
        let body: any = {
            "feedback": comment,
            "user": userData.id
        }
        setLoading(true)
        serviceConfig.post('feedback', true, body, null).then((res: any) => {
            setFeedbackOpen(false);
            setLoading(false)
            setComment('')
            setNotifyOpen(true)
            setNotifyType('success')
            setNotifyMessage('Thanks for your valuable Comment!')
        }).catch((err: any) => {
            setLoading(false)
            setNotifyOpen(true)
            setNotifyType('error')
            setNotifyMessage('Something Went Wrong!')
        })
    }
    // ......

    // Comment field handlechange function
    const handleCommentChange = (e: any) => {
        setComment(e.target.value);
    }
    return (
        <>
            <Drawer
                anchor={'right'}
                open={feedbackOpen}
                onClose={() => { setFeedbackOpen(false); setComment('') }}
            >
                <Box
                    sx={{ width: 450 }}
                    role="presentation"
                    className="feedback_drawer_cover"
                >
                    <div className='feed_header'>
                        <span className='head-line'>
                            Send feedback to <b>Zentax</b>
                        </span>
                        <CloseIcon
                            onClick={() => { setFeedbackOpen(false); setComment('') }}
                            style={{ color: '#c4c7c5', marginLeft: 'auto', cursor: 'pointer' }} />
                    </div>
                    <div className='feed_content'>
                        <label className='input_label'>Describe your feedback</label>
                        <TextField
                            id="outlined-multiline-static"
                            label=""
                            className="input_field"
                            multiline
                            value={comment}
                            onChange={handleCommentChange}
                            rows={5}
                            placeholder='Tell us what prompted this feedback...'
                        />
                        <div className='feed_desc'>
                            <span>
                                Some <b>account and system information</b> may be sent to Zentax. We will use it to fix problems and improve our services, subject to our <b className='anchor_link' onClick={() => { openprivacy() }}>Privacy Policy</b>. We may email you for more information or updates. Go to <b>Legal Help</b> to ask for content changes for legal reasons.
                            </span>
                        </div>
                    </div>
                    <div className='feed_footer'>
                        <Button className='feed_btn' disabled={!comment} onClick={() => { commentSubmit() }}>
                            {loading ? 'Sending' : 'Send'}
                            <SendIcon className='feed_send' style={{ marginLeft: '5px', fontSize: '2.3vmin' }} />
                        </Button>
                    </div>
                </Box>
            </Drawer >
            <div className="landing">
                <Outlet />
                {footer &&
                    <div
                        className='float_icon'
                        style={{
                            position: 'absolute',
                            marginLeft: '94vw',
                            marginTop: '85vh',
                            zIndex: 2
                        }}
                    >
                        <FloatingMenu
                            slideSpeed={500}
                            isOpen={feedbackOpen}
                            spacing={20}
                            direction={Directions.Up}
                        >
                            <MainButton
                                isOpen={feedbackOpen}
                                iconResting={<FeedbackIcon style={{ color: '#E96919', fontSize: 30 }} />}
                                iconActive={<FeedbackIcon style={{ color: '#E96919', fontSize: 30 }} />}
                                background="white"
                                onClick={() => {
                                    setFeedbackOpen((prev: any) => !prev);
                                }}
                                size={56}
                            />
                            {/* <ChildButton
                            icon={<ChatRoundedIcon style={{ color:'#E96919',fontSize: 20 }} />}
                            background="white"
                            size={40}
                        /> */}
                        </FloatingMenu>
                    </div>
                }
            </div >
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}