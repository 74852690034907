// react 
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
// ..........

// scss file
import './mail-success.scss';
// ..........

// package components
import { serviceConfig } from "../../../core/services";
import { NotifyAlert } from '../../../components/notify_alert';
// ..........

// child components
import mailSent from '../../../assets/register/mail-sent.png';
import { useGlobalState } from "../../../Statecontext";
// ..........

export const MailTemplate = () => {

    const navigate = useNavigate();
    
    // data state
    const { setIsManagegst, setVideopopup, setUserdata, masterData, setMasterData, setRowsPerPage, setTotalpages, setPage } = useGlobalState()
    // .........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>();
    const [notifyMessage, setNotifyMessage] = useState('')
    // ..........

    // loading state
    const [loading, setLoading] = useState(false)
    // ..........

    useEffect(() => {
        const timer = setTimeout(() => {
            
            navigate('/home/allgstin');
        }, 5000);

        // Cleanup the timer if the component unmounts before the timer completes
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <>
            <div className="mail-container">
                <div>
                    <img className="icons" src={mailSent} alt='mail_icon'></img>
                </div>
                <h4 className='terminal-window-mainline heading'>Check your mail</h4>
                <p className="terminal-window-subline">Please check your mailbox including Spam and Bin folder for Account verification.</p>
                {/* <div className="col-sm-12 input-group">
                    <button className="register teminal-primary-btn" onClick={resendMail}>{loading ? 'Loading...' : 'Resend Mail'}</button>
                </div> */}
                {/* <div className="col-sm-12 login-link" style={{ textAlign: 'center', paddingBottom: '3%' }}>
                    <Link className="terminal-link" to='../'>Back to Login</Link>
                </div> */}
            </div>
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}