// package components
import axios, { AxiosRequestConfig } from "axios";
// ..........

// child components
import { serviceUrl } from "./service_url";
// ..........

export const serviceConfig: any = {
  // set Auth header function
  getHeader: function (is_json?: boolean) {

    let header: any = {
      headers: {
        Authorization:
          "token " + localStorage.getItem("authKey"),
      },
    };
    is_json && (header.headers["Content-Type"] = "application/json");
    return header;
  },
  // ..........

  // Generate Url 
  buildUrl: function (url: string, query_params: any, url_params: any) {
    var return_url =
      process.env["REACT_APP_BASE_URL"] + serviceUrl[url];
    if (query_params) {
      return_url += "?";
      let loop_start = false;
      for (var key in query_params) {
        loop_start && (return_url += "&");
        return_url = return_url + key + "=" + query_params[key];
        loop_start = true;
      }
    }
    if (url_params) {
      for (var url_key in url_params) {
        return_url = return_url.replaceAll(":" + url_key, url_params[url_key]);
      }
    }
    return return_url;
  },
  // ..........

  // Overall get function
  get: function (url: string, is_header: boolean, query_params: any, url_params: any, signal?: AbortSignal) {
    return new Promise((resolve, reject) => {
      var header: AxiosRequestConfig = {};
      if (is_header) {
        header = this.getHeader();
      }
      header.signal = signal;
      let service_url = this.buildUrl(url, query_params, url_params);
      axios
        .get(service_url, header)
        .then((data: any) => {
          resolve(data.data);
        })
        .catch((er: any) => {
          if (axios.isCancel(er)) {
            console.log('Request canceled', er.message);
          } else {
            reject(er);
          }
        });
    });
  },
  // ..........

  // Overall post function
  post: function (
    url: string,
    is_header: boolean,
    data: any,
    query_params: any,
    url_params: any,
    is_json: boolean
  ) {
    return new Promise((resolve, reject) => {
      var header = null;
      if (is_header) {
        header = this.getHeader(is_json);
      }
      let service_url = this.buildUrl(url, query_params, url_params);
      axios
        .post(service_url, data, header)
        .then((data: any) => {
          resolve(data.data);
        })
        .catch((er: any) => {
          reject(er);
        });
    });
  },
  // ..........


  // Overall put function
  put: function (
    url: string,
    is_header: boolean,
    data: any,
    query_params: any,
    url_params: any,
    is_json: boolean
  ) {
    return new Promise((resolve, reject) => {
      var header = null;
      if (is_header) {
        header = this.getHeader(is_json);
      }
      let service_url = this.buildUrl(url, query_params, url_params);
      axios
        .put(service_url, data, header)
        .then((data: any) => {
          resolve(data.data);
        })
        .catch((er: any) => {
          reject(er);
        });
    });
  },
  // ..........

  // Overall delete function
  delete: function (url: string, is_header: boolean, query_params: any, url_params: any) {
    return new Promise((resolve, reject) => {
      var header = null;
      if (is_header) {
        header = this.getHeader();
      }
      let service_url = this.buildUrl(url, query_params, url_params);
      axios
        .delete(service_url, header)
        .then((data: any) => {
          resolve(data.data);
        })
        .catch((er: any) => {
          reject(er);
        });
    });
  },
  // ..........

  // Overall upload function
  upload: function (endpoint: string, urlParams?: string | number | null, file?: any, fileParams?: any) {
    return new Promise((resolve, reject) => {
      let headers: any = ({
        'Authorization': 'token ' + localStorage.getItem("authKey"),
      });
      const formData: FormData = new FormData();
      if (file.length == undefined) {
        formData.append('file', file);
      }
      else if (file.length == 1) {
        formData.append('file1', file[0]);
      } else {
        file.forEach((val: any, index: number) => {
          formData.append('file' + (index + 1), val);
        });
        formData.append('file_count', file.length);
      }
      if (fileParams) {
        for (let key in fileParams) {
          formData.append(key, fileParams[key]);
        }
      }
      let service_url = this.buildUrl(endpoint, null, null, 'upload')
      axios.post(service_url, formData, { headers })
        .then((data: any) => {
          resolve(data.data);
        })
        .catch((er: any) => {
          reject(er);
        })
    })
  },
  // ..........

  // Overall put upload function
  putupload: function (endpoint: string, urlParams?: string | number | null, file?: any, fileParams?: any) {
    return new Promise((resolve, reject) => {
      let headers: any = ({
        'Authorization': 'token ' + localStorage.getItem("authKey"),
      });
      const formData: FormData = new FormData();
      if (file.length == undefined) {
        formData.append('file', file);
      }
      else if (file.length == 1) {
        formData.append('file1', file[0]);
      } else {
        file.forEach((val: any, index: number) => {
          formData.append('file' + (index + 1), val);
        });
        formData.append('file_count', file.length);
      }
      if (fileParams) {
        for (let key in fileParams) {
          formData.append(key, fileParams[key]);
        }
      }
      let service_url = this.buildUrl(endpoint, null, null, 'upload')
      axios.put(service_url, formData, { headers })
        .then((data: any) => {
          resolve(data.data);
        })
        .catch((er: any) => {
          reject(er);
        })
    })
  },
  // ..........

};
