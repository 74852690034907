import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Button, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface NavButton {
  name: string;
  icon: string;
  submenu?: { name: string }[]; // Example submenu type
}

interface Props {
  nav_btns: NavButton[];
  userRole: string;
  phlogin: boolean;
  menu_click: (button: NavButton) => void;
}

const AccordionMenu: React.FC<Props> = ({ nav_btns, userRole, phlogin, menu_click }) => {
  const [expanded, setExpanded] = useState<string | false>(false); // Track the open accordion panel

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false); // Toggle the accordion
  };

  const filteredNavBtns = nav_btns.filter((button) => {
    if (userRole === 'PH Partner' && phlogin) {
      return button.name !== 'NOTICES' && button.name !== 'SERVICES';
    }
    return true;
  });

  return (
    <div>
      {filteredNavBtns.map((button, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{
            boxShadow: 'none',
            borderBottom: '1px solid #ccc',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                width: '100%',
              }}
            >
              <img src={button.icon} alt='' style={{ height: 24, width: 24 }} />
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {button.name}
              </Typography>
            </div>
          </AccordionSummary>
          {button.submenu && (
            <AccordionDetails style={{paddingBottom:'3%'}}>
              <div style={{ display: 'flex', flexDirection: 'column'}}>
                {button.submenu.map((group: any, groupIndex: number) => (
                  <div key={groupIndex}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold',  color: 'rgba(0, 0, 0, 0.45)' }}>
                      {group.groupName}
                    </Typography>
                    {
                      group.items.map((item: any, itemIndex: number) => (
                        <Button
                          key={itemIndex}
                          onClick={() => menu_click(item)}
                          sx={{
                            color:'black',
                            textAlign: 'left',
                            justifyContent: 'flex-start',
                            textTransform: 'none',
                            marginLeft: '29px', // Indentation for submenu items
                          }}
                        >
                          {item.name}
                        </Button>
                      ))
                    }
                  </div>
                ))}
              </div>
            </AccordionDetails>
          )}

        </Accordion>
      ))
      }

    </div >
  );
};

export default AccordionMenu;
