// react 
import { useCallback, useState, useRef, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// ..........

// scss file
import './zviewGstInfo.scss';
// ..........

// package components
import { Button, Form, Input, Modal, Radio, RadioChangeEvent, Select, Spin, Tag, Tooltip } from 'antd';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Document, Page, pdfjs } from 'react-pdf';
import { Badge, Dialog, InputAdornment, TextField } from '@mui/material';
// ..........

// child components
import { NotifyAlert } from '../../components/notify_alert';
import { serviceConfig } from '../../core/services';
import { Utils } from '../../core/utils/utils';
import { ChatWindow } from './chat';
import Breadcrumbs from '../Breadcrumb';
import { useGlobalState } from '../../Statecontext'
import { AppContext } from '../../core/utils/Context';
// ..........

// icons
import closeIcon from '../../assets/register/close.png'
import attachIcon from '../../assets/images/attach_icon.svg'
import plusIcon from '../../assets/images/plus.svg';
import searchIcon from '../../assets/images/search.svg';
import attachment_icon from '../../assets/images/attachment_icon.png';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// ..........

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


export const ZviewGstInfo = () => {
    const { TextArea } = Input;

    //  datas states
    const formatter = new Intl.NumberFormat('en-IN', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    const [selected, setSelected] = useState('Select Action')
    const [noticeData, setNoticeData] = useState<any>(null)
    const [noticeDatalist, setNoticeDatalist] = useState<any>([])
    const [fileDownloading, setFileDownloading] = useState(false)
    const [taskcollapse, setTaskcollapse] = useState(true)
    const [subtab, setSubtab] = useState('Details')
    const [totalPages, setTotalPages] = useState<number>(0);
    const { selectntc, setSelectntc, menuclick, setTotalpages, breadcrumbPath, setBreadcrumbPath, noticeQuery, setPage, } = useGlobalState();
    const [numPages, setNumPages] = useState<number | null>(null);
    const [remark, setRemark] = useState<any>('No remarks');
    const [demandamnt, setDemandamnt] = useState<any>('');
    const [unformattedDemandamnt, setUnformattedDemandamnt] = useState<string>('');
    const [remarkchange, setRemarkchange] = useState<any>(false)
    const [amntchange, setAmntchange] = useState<any>(false);
    const [selectchange, setSelectchange] = useState<any>(false);
    const [localsearch, setLocalsearch] = useState('')
    const [noticeObj, setNoticeObj] = useState(false);
    const [filterList, setFilterList] = useState<any>([]);
    const [selectedFiles, setSelectedFiles] = useState<any>([])
    const TypeOfSolution = [
        { id: 1, value: 'Reply to Notice' },
        { id: 2, value: 'Personal Hearing' },
        { id: 3, value: 'Expert Opinion' },
        { id: 4, value: 'Support Mail' },
        { id: 5, value: 'Personal Representation before Authorities' },
        { id: 6, value: 'Drafting of Appeal' },
        { id: 7, value: 'Assistance for Departmental Audit' },
        { id: 8, value: 'Others' }
    ]
    const { formValues, handleChange, handleInitial } = useContext(AppContext)
    const { typeOfSolution, ticDescription, ticContactNumber } = formValues;
    let user_data = JSON.parse(localStorage.getItem('userData') || '')
    // ..........

    // alerts and modal states
    const [modal2Open, setModal2Open] = useState(false);
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    const [noticeStatus, setNoticeStatus] = useState('Action to be taken');
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [tkt, setTkt] = useState<any>(false)
    // ..........

    // reference states
    const inputFileElement = useRef<any>(null);
    const { ticket, updateNotice } = Utils();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    let location = useLocation();
    const navigate = useNavigate()

    const observer = useRef<IntersectionObserver | null>(null);
    const targetRef = useRef<HTMLDivElement | null>(null);

    const noticedataString = localStorage.getItem('noticedata');
    const Noticedata = noticedataString ? JSON.parse(noticedataString) : null;
    const TKT_ID = Noticedata ? Noticedata.id : null;
    // ..........

    // loading state
    const [loading, setLoading] = useState(false)
    const [detailloading, setDetailloading] = useState(false)
    const [noticeloading, setNoticeloading] = useState(false)
    // ..........

    // data get function
    const getNoticeData = (id?: any) => {
        if (id) {
            setDetailloading(true)
            serviceConfig.get("get_notice_data", true, null, { id: id }).then((data: any) => {
                setDetailloading(false)
                setNoticeData(data)
                setBreadcrumbPath(`home/notices/${menuclick}/${data?.gst?.gst_no}/notice details/${data?.other_details?.refId ?? '--'}`)
                setUnformattedDemandamnt(data && data.tax_amount ? data.tax_amount : 0)
                setRemark(data && data.remarks ? data.remarks : 'No remarks')
                setDemandamnt(data && data.tax_amount ? formatter.format(data.tax_amount) : 0);
                setNoticeStatus(data.notice_status)
            }, (err: any) => {
                setDetailloading(false)
                if (err.message === "Request failed with status code 401" || "Request failed with status code 404") {
                    navigate('-1')
                    setNotifyOpen(true)
                    setNotifyType('Error')
                    setNotifyMessage('Something Went Wrong!')
                }
            })
            setSubtab('Details')
        }
    }

    const handledrawerfilter = (gst_no?: any, query?: any, is_over_due?: any) => {
        setNoticeloading(true)
        setDetailloading(true)

        let gst = location.pathname.split('/')[2];

        let updatedQuery: any = {
        };
        if (noticeQuery && noticeQuery.gst) {
            updatedQuery['gst'] = gst
            if (noticeQuery && noticeQuery.notice_status) {
                updatedQuery['notice_status'] = [noticeQuery.notice_status]
            }
        }
        if (noticeQuery && noticeQuery.is_over_due) {
            updatedQuery['gst'] = gst
            updatedQuery['is_over_due'] = true
        }
        if (noticeQuery && noticeQuery.gst_case_id) {
            updatedQuery['master_type'] = noticeQuery.master_type
            updatedQuery['gst_case_id'] = noticeQuery.gst_case_id
        }
        if (noticeQuery && noticeQuery.is_due) {
            updatedQuery['is_due'] = true
            updatedQuery['notice_start_due_date'] = noticeQuery.notice_start_due_date
            updatedQuery['notice_end_due_date'] = noticeQuery.notice_end_due_date
        }
        if (noticeQuery && noticeQuery.gst_no) {
            updatedQuery['gst_no'] = noticeQuery.gst_no
            if (noticeQuery && noticeQuery.notice_status) {
                updatedQuery['notice_status'] = [noticeQuery.notice_status]
            }
        }
        updatedQuery['sort'] = `issue_date,desc`

        serviceConfig.get("get_notices", true, updatedQuery, null).then((data: any) => {
            setNoticeDatalist(data.results || data)
            setFilterList(data.results || data)
            getNoticeData(selectntc?.id || data[0] && data[0].id || data.results && data.results[0].id || gst)
            setBreadcrumbPath(`home/notices/${menuclick}/${selectntc?.gst?.gst_no || gst}/notice details/${selectntc?.other_details?.refId || data[0] && data[0].other_details?.refId || data.results && data.results[0].other_details?.refId}`)
            setNoticeloading(false)
        }, (err: any) => {
            setNoticeloading(false)
            setDetailloading(false)
            setNotifyOpen(true)
            setNotifyType('Error')
            setNotifyMessage('Something Went Wrong!')
            if (window.history.length > 1) {
                navigate(-1)
            } else {
                navigate('home/allnotice')
            }
        })
    }

    const getTickets = () => {
        setNoticeloading(true)
        let query: any = {}
        let gst = location.pathname.split('/')[2];

        setBreadcrumbPath('home/services/solutions/notice details')
        serviceConfig.get(user_data.role.role_name === "Super Admin" ? "overall_ticket" : "ticket_list", true, query, null).then((data: any) => {
            setNoticeDatalist(data.data.tickets || data.data)
            setFilterList(data.data.tickets || data.data)
            const tagToSend = data?.data?.tickets.find((ticket: any) => ticket?.tags?.[0])?.tags?.[0] || gst;
            getNoticeData(tagToSend)
            setNoticeloading(false);
        }, (err: any) => {
            setNoticeloading(false)
            if (err.message === "Request failed with status code 401") {
                navigate('/login')
                localStorage.clear()
                window.location.reload();
            }
        })
    }

    useEffect(() => {
        // Initialize Intersection Observer
        observer.current = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && !loading && noticeDatalist && noticeDatalist.length < totalPages) {
                    setPage((prevPage: any) => prevPage + 1);
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            }
        );

        // Observe the target element
        if (targetRef.current) {
            observer.current.observe(targetRef.current);
        }

        // Cleanup Observer when component unmounts
        return () => {
            if (observer.current && targetRef.current) {
                observer.current.unobserve(targetRef.current);
            }
        };
    }, [loading, noticeDatalist && noticeDatalist.length, totalPages]);

    useEffect(() => {
        if (location.pathname.split('/')[3] === 'view-solution-details') {
            getTickets()
            setTkt(true)
        } else {
            handledrawerfilter()
        }
        setBreadcrumbPath(breadcrumbPath)
    }, [])


    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)'); // Adjust the max-width according to your mobile breakpoint
        setIsMobile(mediaQuery.matches);

        const handleResize = () => setIsMobile(mediaQuery.matches);

        mediaQuery.addListener(handleResize);

        return () => {
            mediaQuery.removeListener(handleResize);
        };
    }, []);

    // ..........

    // getsolution function
    const createTicket = () => {
        ticket(null, noticeData.id).then((data: any) => {
            setNotifyType("success")
            setNotifyMessage("Our experts team contact you shortly.")
            setNotifyOpen(true)
            getNoticeData(noticeData.id)
        }).catch((err: any) => {
            console.log(err)
            setNotifyType("error")
            setNotifyMessage(err.response.message || err.response.data)
            setNotifyOpen(true)
        })
    }
    // ..........

    // update solution notice function
    const update = () => {
        updateNotice(noticeData.id, selectchange ? noticeStatus : null, amntchange ? unformattedDemandamnt : null, remarkchange ? remark : null).then((data: any) => {
            setNotifyType("success")
            setNotifyMessage("Notice updated successfully.")
            setNotifyOpen(true)
            setSelectchange(false);
            setAmntchange(false)
            remarkchange(false)
        }, (err: any) => {
            setNotifyType("error")
            setNotifyMessage(err.response.data)
            setNotifyOpen(true)
        })
    }
    // ..........

    // radio selection 
    const handlechange = (value: string) => {
        setSelected(value);
        setDropdownOpen(false);
        setSelectchange(true);
    };
    const onChange = (e: RadioChangeEvent) => {
        setNoticeStatus(e.target.value);
        setSelected(e.target.value);
        setDropdownOpen(false);
        setSelectchange(true);
    };
    //..... 

    const task_details_list = [
        {
            name: 'GSTIN',
            value: noticeData?.gst?.gst_no ?? '--'
        },
        {
            name: 'Issue Date',
            value: noticeData?.issue_date ?? '--'
        },
        {
            name: 'Notice Type',
            value: noticeData?.sub_type ?? '--'
        },
        {
            name: 'Tax Period',
            value: noticeData?.tax_period ?? '--'
        },
        {
            name: 'Notice ID',
            value: noticeData?.other_details?.refId ?? '--'
        },
        {
            name: 'Case ID',
            value: noticeData?.case_data?.case_arn_no ?? '--'
        },
        {
            name: 'Portal Status',
            value: noticeData?.case_data?.portal_status ?? '--'
        },
        {
            name: 'Remarks',
            value: noticeData?.remarks ?? '--'
        },
        {
            name: 'Outstanding Demand',
            value: noticeData?.tax_amount ? formatter.format(noticeData.tax_amount) : '--'
        }
        // {
        //     name: 'Description',
        //     value: noticeData && noticeData.case_data ? noticeData.case_data.case_description.join(', ') : '--'
        // },
        // {
        //     name: 'State',
        //     value: noticeData && noticeData.gst ? noticeData.gst.state : '--'
        // },

        // {
        //     name: 'Due Date',
        //     value: noticeData && noticeData.due_date ? noticeData.due_date : '--'
        // },
        // {
        //     name: 'Section',
        //     value: noticeData ? noticeData.section : '--'
        // },

    ]

    const sub_tab_list = [
        {
            name: 'Details',
        },
    ]

    if (
        noticeData?.ticket_created &&
        noticeData?.ticket_id &&
        noticeData?.ticket_id !== 'None' &&
        noticeData?.ticket_id !== null
    ) {
        sub_tab_list.push({
            name: 'Comments',
        });
    }

    interface Attachment {
        docName: string;
        id: string;
        ct: string;
        s3_link: string;
    }

    interface ReplyField {
        name: string;
        value: string | Attachment[];
    }

    const reply_fields_list: ReplyField[] = [
        {
            name: 'Reply filed',
            value: noticeData && noticeData.reply_details && noticeData.reply_details.reason
        },
        {
            name: 'Reply filed date',
            value: noticeData && noticeData.reply_details && noticeData.reply_details.other_details.update_date
        },
        {
            name: 'Attachments',
            value: noticeData && noticeData.reply_details && noticeData.reply_details.attachments
        },
    ]

    // document viewer
    const openPdfInNewTab = (pdfUrl: any) => {
        const url = `https://docs.google.com/viewerng/viewer?url=${pdfUrl}`;
        window.open(url, '_pdf');
    }
    // .......

    // amount value handle
    const formatNumberWithIndianCommas = (value: string) => {
        // Split the value into integer and fractional parts (if any)
        const parts = value.split(".");
        let integerPart = parts[0];
        const fractionalPart = parts.length > 1 ? "." + parts[1] : "";

        // Add commas for the Indian numbering system
        const lastThreeDigits = integerPart.slice(-3);
        const otherDigits = integerPart.slice(0, -3);

        if (otherDigits !== "") {
            integerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThreeDigits;
        }

        return integerPart + fractionalPart;
    };

    const handleamntChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        let value = evt.target.value.replace(/,/g, ''); // Remove existing commas
        if (!isNaN(Number(value))) {
            setDemandamnt(formatNumberWithIndianCommas(value));
            setUnformattedDemandamnt(value)
            setAmntchange(true);
        }
    };
    // .......

    // search function
    const search = (value: any) => {
        if (noticeDatalist[0].notice) {
            setNoticeObj(true);
        }
        const filterTable = noticeDatalist.filter((o: any) =>
            Object.keys(noticeObj ? o.notice : o).some(k => {
                if (k !== 'gst' && k !== 'case_data') {
                    return String(noticeObj ? o.notice[k] : o[k])
                        .toLowerCase()
                        .includes(value.toLowerCase())
                } else {
                    return Object.keys(noticeObj ? o.notice[k] : o[k]).some(j => String(noticeObj ? o.notice[k][j] : o[k][j])
                        .toLowerCase()
                        .includes(value.toLowerCase()))
                }
            }
            )
        );

        setFilterList(filterTable);
    };

    const handleGlobalSearchChange = (e: any) => {
        let value = e.target.value;

        if (!value) {
            setLocalsearch(value)
            search(value);
        } else {
            setLocalsearch(value)
        }

    }

    const searchclick = (value: any) => {
        search(value);
        setPage(1)
        // setSearchquery(value)
    }
    // .............

    const handleremarkChange = (e: any) => {
        let value = e.target.value;
        setRemark(value)
        setRemarkchange(true)
    }

    const renderTag = (value: number | undefined) => {
        if (value === 2) {
            return <Tag color="error" style={{ borderRadius: "25px", padding: '3px 10px' }}>Open</Tag>;
        }
        if (value === 3) {
            return <Tag color="warning" style={{ borderRadius: "25px", padding: '3px 10px' }}>Pending</Tag>;
        }
        if (value === 6) {
            return <Tag color="warning" style={{ borderRadius: "25px", padding: '3px 10px' }}>Primary Analysis Done</Tag>;
        }
        if (value === 7) {
            return <Tag color="warning" style={{ borderRadius: "25px", padding: '3px 10px' }}>Success Analysis Matrix Done</Tag>;
        }
        if (value === 8) {
            return <Tag color="warning" style={{ borderRadius: "25px", padding: '3px 10px' }}>Draft Ready - Payment Pending</Tag>;
        }
        if (value === 9) {
            return <Tag color="warning" style={{ borderRadius: "25px", padding: '3px 10px' }}>Proforma Invoice - Payment Pending</Tag>;
        }
        if (value === 10) {
            return <Tag color="warning" style={{ borderRadius: "25px", padding: '3px 10px' }}>Draft Ready for Download</Tag>;
        }
        if (value === 11) {
            return <Tag color="warning" style={{ borderRadius: "25px", padding: '3px 10px' }}>Pending with department</Tag>;
        }
        if (value === 4 || value === 5) {
            return <Tag color="success" style={{ borderRadius: "25px", padding: '3px 10px' }}>{value === 4 ? 'Resolved' : 'Closed'}</Tag>;
        }
        return '--';
    };

    // create task manually
    const handleselectedFile = (event: any) => {
        let oldFiles = [...selectedFiles]
        Array.from(event.target.files).forEach(fi => {
            oldFiles.push(fi)
        });
        setSelectedFiles(oldFiles)

    };

    const removeFile = (ind: any) => {
        let oldFiles = [...selectedFiles]
        oldFiles.splice(ind, 1)
        setSelectedFiles(oldFiles)
    }

    const [form] = Form.useForm();


    // Check if all values are not empty and if there are some errors
    const isError = useCallback(
        () =>
            Object.keys({ typeOfSolution, ticDescription, ticContactNumber }).some(
                (name) => (formValues[name].required && !formValues[name].value) || formValues[name].error
            ),
        [formValues, typeOfSolution, ticDescription, ticContactNumber]
    )

    const createTaskManually = () => {
        if (!loading) {

            setLoading(true)
            let req_body: any;
            if (selectedFiles.length > 0) {
                req_body = new FormData()
                selectedFiles.forEach((file: any, index: any) => {
                    req_body.append('file' + (index + 1), file)
                });
                req_body.append('file_count', selectedFiles.length)
                req_body.append('type', typeOfSolution.value)
                req_body.append('description', ticDescription.value)
                req_body.append('contact_no', ticContactNumber.value)
            } else {
                req_body = {
                    'file_count': 0,
                    "type": typeOfSolution.value,
                    "description": ticDescription.value,
                    "contact_no": ticContactNumber.value,
                }
            }

            serviceConfig.post("create_ticket", true, req_body, null, null, selectedFiles.length > 0 ? false : true).then((data: any) => {
                setModal2Open(false)
                setLoading(false)
                getTickets()
                setTkt(true)
                handleInitial()
                setSelectedFiles([])
                setNotifyType("success");
                setNotifyMessage("Ticket Created Successfully!");
                setNotifyOpen(true);
            }, (err: any) => {
                setLoading(false)
                setNotifyType("error");
                setNotifyMessage("Something went wrong!");
                setNotifyOpen(true);
            })

        }

    }
    // ........

    return (
        <>
            <Modal
                title="Create task manually"
                onCancel={() => { setModal2Open(false); handleInitial(); setSelectedFiles([]) }}
                width={'35%'}
                className='action-popup'
                centered
                style={{ color: "#031742" }}
                open={modal2Open}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <span className='type-field-lable' style={{ fontSize: '2vmin ' }}>Type of Solution Sought</span>
                <TextField
                    fullWidth
                    focused
                    select
                    SelectProps={{ native: true, }}
                    variant='outlined'
                    name="typeOfSolution"
                    value={typeOfSolution.value}
                    onBlur={handleChange}
                    onChange={handleChange}
                    defaultValue='Reply to Notice'
                    autoComplete='off'
                    style={{ margin: '2% 0' }}
                >
                    <option value='' disabled>...</option>
                    {TypeOfSolution.map((type: any) =>
                        <option key={type.id} value={type.value}>{type.value}</option>
                    )}
                </TextField>
                <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
                    <Form.Item
                        name="ticDescription"
                        label="Description"
                        style={{ minHeight: 200 }}
                        className='form_item one'
                    >
                        {ticDescription.error && <span className="gst-err-msg">{ticDescription.error}</span>}
                        <div style={{ border: "1px solid #00000021", padding: 10 }}>
                            <TextArea
                                maxLength={100}
                                style={{
                                    height: 120,
                                    resize: 'none',
                                    padding: 0,
                                    border: "none"
                                }}
                                bordered={false}
                                name="ticDescription"
                                value={ticDescription.value}
                                onChange={handleChange}
                                onBlur={handleChange}
                            />
                            <hr />
                            <input
                                ref={inputFileElement}
                                onChange={handleselectedFile}
                                multiple
                                type="file"
                                style={{ display: 'none' }} />
                            <span
                                onClick={() => inputFileElement.current.click()}
                                className='attach_file_line'
                                style={{
                                    color: '#4472C4',
                                    fontSize: '1.8vmin',
                                    fontWeight: 400,
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }}>
                                <img
                                    src={attachIcon}
                                    className='attach_file_img'
                                    style={{
                                        marginRight: '5px'
                                    }}
                                    height={'13vmin'}
                                    alt='attach' />
                                Attach files (Optional)
                            </span>
                            <div
                                style={{
                                    display: 'flex',
                                    marginTop: '10px',
                                    width: '100%',
                                    flexWrap: 'wrap'
                                }}>
                                {selectedFiles.map((file: any,
                                    index: any) =>
                                    <span
                                        key={file.name}
                                        style={{
                                            width: '27.9%',
                                            padding: '1% 2%',
                                            backgroundColor: '#FCE7D9',
                                            border: '0.5px solid #E96919',
                                            marginRight: '1%',
                                            marginBottom: '1%',
                                            justifyContent: 'space-between',
                                            wordBreak: 'break-all',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>{file.name}
                                        <img
                                            alt="close-icon"
                                            onClick={() => removeFile(index)}
                                            src={closeIcon}
                                            style={{
                                                marginLeft: '5px',
                                                cursor: 'pointer'
                                            }}
                                            height={'10vmin'} />
                                    </span>)}
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item
                        name="ticContactNumber"
                        messageVariables={{ name: 'name' }}
                        label="Contact mobile num"
                        className='form_item two'
                    >
                        {ticContactNumber.error && <span className="gst-err-msg">{ticContactNumber.error}</span>}
                        <Input type='text'
                            name="ticContactNumber"
                            value={ticContactNumber.value}
                            onChange={handleChange}
                            onBlur={handleChange}
                            style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0 }} />
                    </Form.Item>
                    <Form.Item className='form_item'>
                        <Button
                            htmlType="submit"
                            id='popup-submit-btn'
                            className='create-popup-btn'
                            disabled={isError()}
                            loading={loading}
                            style={{
                                background: "#424B70",
                                color: "white",
                                border: "none",
                                width: "70%",
                                height: "6vh",
                                marginLeft: '15%',
                                fontSize: '2.3vmin'
                            }}
                            onClick={() => createTaskManually()}>
                            {!loading && <>Create Task</>}
                            {loading && <>Loading...</>}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <div id="gst-details-container" className='view-gst-info'>
                <div className='z-gst-info-wrap'>
                    <div className='info-list-wrap'>
                        <div className='search_cover'>
                            <ArrowBackIosNewIcon onClick={() => {
                                if (window.history.length > 1) {
                                    navigate(-1)
                                } else {
                                    navigate('home/allnotice')
                                }
                                setTotalpages(0);
                                setBreadcrumbPath(`home/notices/${menuclick}`)
                            }} fontSize='small' className='icon' />
                            <Input
                                className='z-table_global_search'
                                value={localsearch}
                                onChange={handleGlobalSearchChange}
                                placeholder='Type here to search...'
                                suffix={<img
                                    src={searchIcon}
                                    onClick={() => searchclick(localsearch)}
                                    style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                                    alt='password icon' />}
                            />
                            {tkt && <Tooltip placement="right" title={'Create task'} arrow={true} color={'#424B70'} >
                                <Button className='gst_add_btn' onClick={() => { setModal2Open(true) }}>
                                    <img src={plusIcon} alt='plus icon' />
                                </Button>
                            </Tooltip>}
                        </div>
                        <div className='list_item_wrap'>
                            {!noticeloading && filterList ? filterList.length === 0 ?
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <span style={{ color: '#000', }}>No Data</span>
                                </div> :
                                filterList.map((detail: any, index: any) => (
                                    <div
                                        className={
                                            tkt
                                                ? noticeData?.ticket_id === detail.id.toString()
                                                    ? 'info-card mark-border'
                                                    : 'info-card'
                                                : noticeData?.id === detail.id
                                                    ? 'info-card mark-border'
                                                    : 'info-card'
                                        }

                                        key={index}
                                        onClick={() => {
                                            if (tkt) {
                                                if (detail.tags[0]) {
                                                    getNoticeData(detail.tags[0])
                                                    setAmntchange(false); setSelectchange(false); setRemarkchange(false)
                                                }
                                            } else {
                                                setSelectntc({})
                                                getNoticeData(detail.id)
                                                setAmntchange(false); setSelectchange(false); setRemarkchange(false)
                                            }
                                            // setFilterList((prevItems: any) => {
                                            //     const newItems = [...prevItems];
                                            //     const selectedItem = newItems.splice(index, 1)[0]; // Remove selected item
                                            //     newItems.unshift(selectedItem); // Add selected item at the beginning
                                            //     return newItems;
                                            // });
                                        }}>
                                        {detail.is_latest && <Tooltip placement="right" title={'Latest notice'} arrow={true} color={'#424B70'}>
                                            <Badge variant="dot" overlap="circular" className='cnt_badge'>
                                            </Badge>
                                        </Tooltip>}
                                        <div className="type_cover">
                                            <div className="type_wrap">
                                                {tkt
                                                    ? detail?.type ?? '--'
                                                    : detail?.notice_type && detail.sub_type !== 'REMINDER'
                                                        ? (detail.sub_type?.includes("Rem1:") || detail.sub_type?.includes("Rem2:") || detail.sub_type?.includes("Rem3:"))
                                                            ? 'REMINDER'
                                                            : detail.notice_type
                                                        : detail?.sub_type ?? '--'}
                                            </div>
                                            <div className="tax_wrap">
                                                {tkt ? detail?.custom_fields?.cf_tax_period ?? '--' : detail?.tax_period ?? '--'}
                                            </div>
                                            {tkt && <div className="type_wrap">
                                                {detail?.id}
                                            </div>}
                                        </div>
                                        <div className="date-cover">
                                            {['Due date', 'Issue date'].map((label, idx) => (
                                                <div className="date-cell" key={label}>
                                                    <span className="date_label">{label}</span>
                                                    <span className="date_value">
                                                        {tkt
                                                            ? (idx === 0 ? detail?.custom_fields?.cf_due_date_to_reply_to_notice : detail?.custom_fields?.cf_notice_date) ?? '--'
                                                            : (idx === 0 ? detail?.due_date : detail?.issue_date) ?? '--'}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                        {tkt && <span className='info-title'>{detail?.custom_fields.cf_trade_name ?? '--'}</span>}
                                        <span className='info-title'>{
                                            tkt ? renderTag(detail?.status) : detail?.case_data?.portal_status ?? '--'}</span>
                                    </div>
                                )) :
                                <Spin spinning={true} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute', marginTop: '15%', }}>
                                </Spin>
                            }
                        </div>
                        <div ref={targetRef}></div>
                    </div>
                    <div className='info-details-wrap'>
                        <div className='overview_menu_wrap'>
                            <Breadcrumbs />
                        </div>
                        {!detailloading && task_details_list ?
                            <div className='info-details-wrap-cover'>
                                <div className='info-header'>
                                    <h3 className='info-title'>{noticeData && noticeData.gst ? noticeData.gst.trade_name : '--'}</h3>
                                    {noticeQuery && location.pathname.split('/').pop() === 'view-gst-details' &&
                                        <div className='query_wrap'>
                                            {noticeQuery.notice_status || noticeQuery.is_over_due ? (
                                                <>
                                                    {noticeQuery.notice_status}
                                                    {noticeQuery.is_over_due && 'Over Due'}
                                                </>
                                            ) : (
                                                'All Notices'
                                            )}
                                        </div>
                                    }
                                    {/* {noticeQuery && location.pathname.split('/').pop() === 'case-summary-details' &&
                                        <div className='query_wrap'>
                                            {noticeData && noticeData.case_data ? noticeData.case_data.case_arn_no : '--'}
                                        </div>
                                    } */}
                                </div>
                                <div className='task-info-wrap'>
                                    <div className='task-info-header'>
                                        <ArrowBackIosNewIcon onClick={() => setTaskcollapse(!taskcollapse)} fontSize='small' className='icon' style={{ transform: `rotate(${taskcollapse ? '270deg' : '180deg'})` }} />
                                        <h4 onClick={() => setTaskcollapse(!taskcollapse)} className='task-info-title'>Task Information</h4>
                                        <div className="view-container">
                                            <Select className="dropdown-btn"
                                                defaultValue="Select Action"
                                                onChange={handlechange}
                                                value={noticeStatus}
                                                open={dropdownOpen}
                                                onDropdownVisibleChange={(open) => setDropdownOpen(open)}
                                                dropdownRender={menu => {

                                                    return (
                                                        <div>

                                                            <Radio.Group onChange={onChange} value={noticeStatus} style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <Radio value={'No action required'}>No action required</Radio>
                                                                <Radio value={'Action to be taken'}>Action to be taken</Radio>
                                                                <Radio value={'Action taken'}>Action taken</Radio>

                                                            </Radio.Group>

                                                        </div>
                                                    )
                                                }}
                                                options={[

                                                    { value: 'No action required' },
                                                    { value: 'Action to be taken', },
                                                    { value: 'Action taken' },
                                                ]}
                                            />
                                            <Button className='save-btn' disabled={!amntchange && !selectchange && !remarkchange} onClick={update}>SAVE</Button>
                                            {noticeData && !noticeData.ticket_created && <Button className='get-btn' onClick={createTicket}>Get Solution from Zen Tax Clinic</Button>}
                                            <Button className='save-btn' onClick={() => { navigate('/home/gst-loan'); setBreadcrumbPath('/home/gst-loan') }}>Get Loan</Button>
                                        </div>
                                    </div>
                                    {taskcollapse &&
                                        <div className='task-info-content-wrap'>
                                            {task_details_list.map((task: any) => (
                                                <div className='task-details-tile' key={task.name}>
                                                    <span className='details-tile-label'
                                                        style={{ fontWeight: task.name === 'Case Status' ? 'bold' : '' }}>
                                                        {task.name}
                                                    </span>

                                                    {task.name === 'Outstanding Demand' ? (<TextField
                                                        id="outlined-start-adornment"
                                                        sx={{
                                                            width: '40%',

                                                            '& input[type=number]': {
                                                                '-moz-appearance': 'textfield',
                                                                '-webkit-appearance': 'none',
                                                                'appearance': 'textfield',
                                                            },
                                                            '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                                                                '-webkit-appearance': 'none',
                                                                'margin': 0,
                                                            },
                                                        }}
                                                        value={demandamnt}
                                                        onChange={handleamntChange}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                                        }}
                                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                        autoComplete="off"
                                                    />
                                                    ) : task.name === 'Remarks' ? (
                                                        <TextArea
                                                            placeholder="Enter remarks"
                                                            value={remark}  // Ensure the remark is prefilled
                                                            onChange={(e) => handleremarkChange(e)} // Handle remark change
                                                            style={{ width: '12vw' }}
                                                            autoSize={{ minRows: 1, maxRows: 5 }}
                                                            allowClear
                                                        />
                                                    ) : (
                                                        <span className='details-tile-value'
                                                            style={{ fontWeight: task.name === 'Case Status' ? 'bold' : '' }}>
                                                            {task.value}
                                                        </span>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    }

                                </div>
                                {
                                    <div className='sub-tabs-cover-wrap'>
                                        <div className='sub-tabs-cover-header'>
                                            {
                                                sub_tab_list.map((tab: any) => (
                                                    <span key={tab.name} className={tab.name === subtab ? 'sub-tab mark' : 'sub-tab'} onClick={() => setSubtab(tab.name)}>{tab.name}</span>
                                                ))
                                            }
                                        </div>
                                        <div className='sub-tabs-content'>
                                            {
                                                subtab === 'Details' &&
                                                <div className='details-tab-wrap'>
                                                    <div className="attachment">
                                                        <span className='sub-tab-title'>Attachments</span>
                                                        {noticeData && noticeData.attachments && noticeData.attachments.length !== 0 &&
                                                            <ul className='attach_ul'>
                                                                {noticeData.attachments.map((doc: any, index: any) =>
                                                                    <li>
                                                                        <div className='list_no'>{(index < 10 ? "0" : "") + (index + 1)}</div>
                                                                        <a className="click-me"
                                                                            onClick={() => {
                                                                                openPdfInNewTab(doc.s3_link);
                                                                            }}
                                                                        >
                                                                            {doc.docName ? doc.docName : "Attachment"}
                                                                        </a>
                                                                    </li>

                                                                )
                                                                }
                                                            </ul>
                                                        }
                                                        {noticeData && noticeData.attachments && noticeData.attachments.length === 0 && <p style={{ color: 'black', textAlign: 'center', fontSize: '2.2vmin', margin: 'auto' }}>No Attachments found!</p>}

                                                        {fileDownloading && <div style={{ position: 'absolute', top: 0, height: '100%', width: '100%', background: '#000', opacity: 0.7, color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span>File download inprogress..</span></div>}
                                                    </div>
                                                    <div className='divider'></div>
                                                    <div className="attachment">
                                                        <span className='sub-tab-title reply-title'>Reply</span>
                                                        <div className='reply_cover'>

                                                            {noticeData && noticeData.reply_details && reply_fields_list.map((task: any) => (
                                                                <div className='task-details-tile' key={task.name}>
                                                                    <span className='details-tile-label'>
                                                                        {task.name}
                                                                    </span>

                                                                    {task.name === 'Attachments' ?
                                                                        ((task.value as Attachment[]).map((attachment, idx) => (
                                                                            <div
                                                                                className='details-attach-value link'
                                                                                key={idx}>
                                                                                <li>
                                                                                    <div className='list_no'>{(idx < 10 ? "0" : "") + (idx + 1)}</div>
                                                                                    <a
                                                                                        onClick={() => {
                                                                                            openPdfInNewTab(attachment?.s3_link);
                                                                                        }}
                                                                                    >
                                                                                        {attachment?.docName}
                                                                                    </a><br></br>
                                                                                </li>
                                                                            </div>
                                                                        )))
                                                                        : (
                                                                            <span className='details-tile-value'>
                                                                                {task.value as string}
                                                                            </span>
                                                                        )}
                                                                </div>
                                                            ))}

                                                            {noticeData && !noticeData.reply_details && <p className='notice-reply-sub'
                                                                style={{ color: 'black', margin: 'auto', width: '100%', textAlign: 'center', fontSize: '2.2vmin' }}>
                                                                No Reply found!
                                                            </p>}

                                                        </div>

                                                        {/* {noticeData && 'reply_details' in noticeData ?
                                                    <ul className='attach_ul' style={{}}>
                                                        <li>Reply filed : <b>{noticeData.reply_details.reason}</b></li>
                                                        <li>Reply filed date : <b>{noticeData.reply_details.other_details.update_date}</b></li>
                                                        <li>Attachments : </li>
                                                        <ol style={{ marginTop: '0px !important' }}>
                                                            {noticeData.reply_details.attachments.map((doc: any) => <li><a className="click-me" href={doc.s3_link} target='_blank'><div className="attach-icon"><img src={attachment_icon} alt='attach' /></div> {doc.docName}</a></li>)}
                                                        </ol>
                                                    </ul> : <p className='notice-reply-sub' style={{ color: 'black', margin: 'auto', width: '100%', textAlign: 'center', fontSize: '2.2vmin' }}>No Reply found!</p>} */}
                                                    </div>
                                                </div>
                                            }
                                            {
                                                subtab === 'Comments' &&
                                                <>
                                                    <ChatWindow id={noticeData && noticeData.ticket_id}></ChatWindow>
                                                </>
                                            }
                                            {
                                                subtab === 'Reply' &&
                                                <div className="attachment">
                                                    {'reply_details' in noticeData ?
                                                        <ul style={{}}>
                                                            <li>Reply filed : <b>{noticeData.reply_details.reason}</b></li>
                                                            <li>Reply filed date : <b>{noticeData.reply_details.other_details.update_date}</b></li>
                                                            <li>Attachments : </li>
                                                            <ol style={{ marginTop: '0px !important' }}>
                                                                {noticeData?.reply_details?.attachments.map((doc: any) =>
                                                                    <li><a
                                                                        // onClick={() => { setDocviewurl(doc.s3_link); setDocopen(true) }}
                                                                        className="click-me" href={doc.s3_link} target='_blank'><div className="attach-icon"><img src={attachment_icon} alt='attach' /></div> {doc.docName}</a></li>)}
                                                            </ol>
                                                        </ul> : <p className='notice-reply-sub' style={{ color: 'black', margin: 'auto', width: '100%', textAlign: 'center', fontSize: '2.2vmin' }}>No Reply found!</p>}
                                                </div>
                                            }
                                        </div>
                                    </div>}
                            </div> :
                            <Spin spinning={true} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute', marginLeft: '40%', marginTop: '15%', }}>
                            </Spin>
                        }
                    </div>
                </div>
                <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
            </div >
        </>

    )
}

