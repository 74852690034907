// react 
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// ..........

// scss file
import './login.scss';
// ..........

// package components
import { Box, FormHelperText, IconButton, InputAdornment, OutlinedInput, TextField, ThemeProvider, createTheme } from '@mui/material';
// ..........

// child components
import { useGlobalState } from '../../../Statecontext';
import passwordIcon from '../../../assets/register/password.png';
import { NotifyAlert } from '../../../components/notify_alert';
import { serviceConfig } from "../../../core/services";
// ..........

// icons
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import mailIcon from '../../../assets/register/mail.png';
import { Logintype } from '../login_type';
import phoneIcon from "../../../assets/register/phone.png";
import SendIcon from '@mui/icons-material/Send';
// ..........

export const Login = () => {

    // form values states
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [mobile_number, setMobile_number] = useState('')
    const [otp, setOtp] = useState<string[]>(['', '', '', '']);
    const [err_email, setErrEmail] = useState('')
    const [err_number, setErr_number] = useState('');
    const [err_otp, setErr_otp] = useState('');
    const [err_pswd, setErrPswd] = useState('')
    const [responsedata, setResponsedata] = useState<any>({})
    const [user_id, setUser_id] = useState('')
    // ..........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [rolepopup, setRolepopup] = useState(false)
    const [otplogin, setOtplogin] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    // ..........

    // loading state
    const [loading, setLoading] = useState(false)
    const [formloading, setFormloading] = useState(false);
    // ..........

    // data states
    const [showPassword, setShowPassword] = React.useState(false);
    // ..........

    // reference states
    const {setBreadcrumbPath, setIsManagegst, setVideopopup, setUserdata, masterData, setMasterData, setRowsPerPage, setTotalpages, setPage } = useGlobalState();
    const navigate = useNavigate()
    // ..........

    useEffect(() => {

        if (localStorage.getItem('authKey') !== null) {
            navigate('/home/gst-dashboard');
        }

        const listener = (event: any) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                loginUser()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [email, password]);

    // password field typechange icon function
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    // ..........

    // form value validation function
    const validateEmail = (email: any) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email);
    }

    const handleEmailChange = (e: any) => {
        const newval = e.target.value;
        setEmail(newval);
        if (newval !== '') {
            setErrEmail(validateEmail(newval) ? '' : '*Invalid E-Mail id')
        } else {
            setErrEmail("*Please enter registered mail id")
        }
    }

    const handlePassword = (e: any) => {
        const newval = e.target.value;
        setPassword(newval);
        if (newval !== '') {
            setErrPswd('')
        } else {
            setErrPswd("*Please enter your password")
        }

    }

    const validateMobile = (number: any) => {
        const pattern = /^[6-9]\d{9}$/; // Indian mobile number

        return pattern.test(number);
    }

    const handleMobileChange = (e: any) => {
        const newval = e.target.value;
        setMobile_number(newval);
        if (newval !== '') {
            setErr_number(validateMobile(newval) ? '' : '*Invalid Mobile number')
        } else {
            setErr_number("*Please enter registered mobile number")
        }
    }


    const intialfield = () => {
        setPassword('');
        setErrPswd('')
        setEmail('');
        setErrEmail('')
        setMobile_number('')
        setErr_number('')
        setOtp(['', '', '', ''])
        setErr_otp('')
    }

    // otp field
    const handleChange = (value: string, index: number) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);


        // Move focus to the next input if a digit is entered
        if (value && index < otp.length - 1) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            nextInput?.focus();
        }

        setErr_otp('')
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            const prevInput = document.getElementById(`otp-input-${index - 1}`);
            prevInput?.focus();
        }
    };

    const validateOtp = (): boolean => {
        // Check if any of the OTP fields are empty
        return otp.every((digit) => digit !== '');
    };
    // ..........

    // master Data for filter
    const loadMasterData = useCallback(() => {
        const fetchData = async () => {
            try {
                const gstData = await serviceConfig.get("master_gst", true, null, null);
                const statesData = await serviceConfig.get("master_states", true, null, null);
                const tradesData = await serviceConfig.get("master_trades", true, null, null);
                const caseIdData = await serviceConfig.get("get_case_id", true, null, null);
                const arnData = await serviceConfig.get("get_arn", true, null, null);
                const sessionData = await serviceConfig.get("get_session", true, null, null);
                const caseTypeData = await serviceConfig.get("get_notice_type", true, null, null);
                const usernamesData = await serviceConfig.get("master_usernames", true, null, null);

                setMasterData({
                    gst: gstData.data,
                    states: statesData.data,
                    trades: tradesData.data,
                    caseIdFilter: caseIdData.data,
                    arnFilter: arnData.data,
                    sessionFilter: sessionData.data,
                    caseType: caseTypeData.data.map((item: any) => item.toUpperCase()),
                    userNames: usernamesData.data
                });
                localStorage.setItem('masterData', JSON.stringify({
                    gst: gstData.data,
                    states: statesData.data,
                    trades: tradesData.data,
                    caseIdFilter: caseIdData.data,
                    arnFilter: arnData.data,
                    sessionFilter: sessionData.data,
                    caseType: caseTypeData.data.map((item: any) => item.toUpperCase()),
                    userNames: usernamesData.data
                }));

            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [])
    // ..........

    // mobile number submission
    const handleSubmitnumber = () => {
        let req_body = {
            'mobile_number': mobile_number,
        }
        serviceConfig.post('login_otp_request', true, req_body, null).then((res: any) => {
            setNotifyType("success")
            setNotifyMessage(res.message || 'OTP sended successfully !')
            setUser_id(res.data.user_id || '')
            setNotifyOpen(true)
        }, (err: any) => {
            setNotifyType("error")
            setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
            setNotifyOpen(true)
            setLoading(false)
        })
    }
    // ..........

    // login submission 
    const loginUser = (e?: any) => {
        if (!loading) {
            if (!otplogin && (!email || !password)) {
                !email && setErrEmail('*Please enter registered mail id')
                !password && setErrPswd('*Please enter your password')
            } else if (otplogin && (!mobile_number || !validateOtp())) {
                !mobile_number && setErr_number('*Please enter your mobile number')
                if (!validateOtp()) {
                    setErr_otp('*Please enter all 4 digits of the OTP');
                    return;
                }
            } else {
                setLoading(true)
                let req_body = {
                    'email': email.toLowerCase(),
                    'password': password
                }
                let req_body1 = {
                    'user_id': user_id,
                    'otp': otp.join('')
                }
                serviceConfig.post(otplogin ? 'login_otp_verify' : 'login_user', true, otplogin ? req_body1 : req_body, null).then((res: any) => {
                    setPage(1);
                    setTotalpages(0);
                    setRowsPerPage(10);


                    if (res.data.user_data.role.role_name === 'PH Partner' && res.data.user_data.is_cf === true) {
                        setRolepopup(true)
                        setResponsedata(res.data)
                    } else {
                        if (res.data.user_data.role.role_name === 'PH Partner') {
                            localStorage.setItem('ph_login', JSON.stringify(true))
                        } else {
                            localStorage.setItem('ph_login', JSON.stringify(false))
                        }
                        localStorage.setItem('authKey', res.data.auth_key)
                        setUserdata(res.data.user_data)
                        localStorage.setItem('userData', JSON.stringify(res.data.user_data))
                        localStorage.setItem('is_cf', JSON.stringify(res.data.user_data.is_cf))
                        localStorage.setItem('cf_code', JSON.stringify(res.data.user_data.cf_code))
                        localStorage.setItem('userRole', res.data.user_data.role.role_name)
                        localStorage.setItem('syncedCount', JSON.stringify(res.data.user_data.sync_count))
                        localStorage.setItem('ph_request', JSON.stringify(res.data.user_data.ph_request))

                        if (res.data.is_gst_available === true) {
                            navigate('/home/gst-dashboard');
                            setIsManagegst(false)
                        } else {
                            navigate('/home/allgstin');
                            setBreadcrumbPath(`home/notices/GSTIN wise Notices`);
                            setIsManagegst(true)
                            if (res.data.user_data.first_login === true) {
                                setVideopopup(true)
                            }
                        }

                    }

                    if (res.data.user_data.role.role_name !== 'Super Admin') {
                        loadMasterData()
                    }
                    setLoading(false)
                },
                    (err: any) => {
                        setNotifyType("error")
                        setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
                        setNotifyOpen(true)
                        setLoading(false)
                    }
                )
            }
        }
    }
    // ..........

    // custom theming function
    const inputtheme = createTheme({
        palette: {
            primary: {
                main: '#9a9999',
            }
        },
    });
    // ..........

    return (
        <>
            {
                rolepopup ?
                    <Logintype data={responsedata} setState={setRolepopup} /> :
                    <div className="container-login">
                        <h4 className="terminal-window-mainline">Welcome Back</h4>
                        <p className="terminal-window-subline">Sign in to continue to Zen Tax Clinic</p>
                        <div className="fields">
                            <div className="row">

                                {
                                    otplogin ?
                                        <div className="col-sm-12 input-group">
                                            <label className="input-label">Mobile number</label>
                                            <ThemeProvider theme={inputtheme}>
                                                <OutlinedInput
                                                    autoComplete='off'
                                                    className='inputfield'
                                                    
                                                    placeholder='Enter your registered mobile number'
                                                    value={mobile_number}
                                                    error={!!err_number}
                                                    onChange={handleMobileChange}
                                                    startAdornment={<img className="input-icon" src={phoneIcon} alt='mail_icon' />}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleSubmitnumber}
                                                                edge="end"
                                                                disabled={!!err_number || !mobile_number}
                                                            >
                                                                <SendIcon
                                                                    style={{
                                                                        color: !!err_number || !mobile_number ? '#E96919' : '#05103E',
                                                                        cursor: !!err_number || !mobile_number ? 'not-allowed' : 'pointer',
                                                                    }} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    aria-describedby="outlined-weight-helper-text"
                                                />
                                            </ThemeProvider>
                                            <FormHelperText id='error-line' style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right' }} >{err_number}</FormHelperText>
                                        </div> :
                                        <div className="col-sm-12 input-group">
                                            <label className="input-label">E-Mail</label>
                                            <ThemeProvider theme={inputtheme}>
                                                <OutlinedInput
                                                    autoComplete='off'
                                                    className='inputfield'
                                                    
                                                    placeholder='Enter your registered mail id'
                                                    value={email}
                                                    error={!!err_email}
                                                    onChange={handleEmailChange}
                                                    startAdornment={<img className="input-icon" src={mailIcon} alt='mail_icon' />}
                                                    aria-describedby="outlined-weight-helper-text"
                                                />
                                            </ThemeProvider>
                                            <FormHelperText id='error-line' style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right' }} >{err_email}</FormHelperText>
                                        </div>
                                }

                                {otplogin ?
                                    <div className={err_number ? "col-sm-12 input-group error-margin" : "col-sm-12 input-group"} style={{ marginTop: err_number ? '0' : '' }}>
                                        <label className="input-label" >OTP</label>
                                        <div className='otp-cover'>
                                            {otp.map((digit, index) => (
                                                <ThemeProvider theme={inputtheme}>
                                                    <OutlinedInput
                                                        className='otp_input'
                                                        key={index}
                                                        id={`otp-input-${index}`}
                                                        autoComplete='off'
                                                        value={digit}
                                                        onChange={(e: any) => handleChange(e.target.value, index)}
                                                        onKeyDown={(e: any) => handleKeyDown(e, index)}
                                                        inputProps={{
                                                            maxLength: 1,
                                                            style: { textAlign: 'center' }
                                                        }}
                                                    />
                                                </ThemeProvider>
                                            ))}

                                        </div>
                                        <FormHelperText id='error-line' style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right' }} >{err_otp}</FormHelperText>
                                    </div> :
                                    <div className={err_email ? "col-sm-12 input-group error-margin" : "col-sm-12 input-group"} style={{ marginTop: err_email ? '0' : '' }}>
                                        <label className="input-label">Password</label>
                                        <ThemeProvider theme={inputtheme}>
                                            <OutlinedInput
                                                className='inputfield'
                                                
                                                placeholder='Enter your password'
                                                value={password}
                                                type={showPassword ? 'text' : 'password'}
                                                error={!!err_pswd}
                                                onChange={handlePassword}
                                                startAdornment={<img className="input-icon" src={passwordIcon} alt='password_icon' />}
                                                endAdornment={<InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                                    </IconButton>
                                                </InputAdornment>}
                                                aria-describedby="outlined-weight-helper-text"
                                            />
                                            <FormHelperText id='error-line' style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right' }} >{err_pswd}</FormHelperText>
                                        </ThemeProvider>
                                    </div>}

                                <div className="col-sm-12  forgot-link">
                                    <Link className="terminal-link" to='/forgot-password'>Forgot Password?</Link>
                                </div>

                                <div className="col-sm-12 btn_grp">
                                    <button type='submit' className="teminal-submit-btn" onClick={loginUser}>{loading ? 'Loading...' : 'Login'}</button>
                                    <button type='submit' className="teminal-submit-secondary-btn" onClick={() => { setOtplogin(!otplogin); intialfield() }}>Login with {!otplogin ? 'OTP' : 'Password'}</button>
                                </div>

                                <div className="col-sm-12 terminal-divider" style={{ paddingTop: '0.8rem' }}>
                                    <hr></hr>
                                </div>

                                <div className="col-sm-12 input-group">
                                    <button className="teminal-primary-btn teminal-secondary-btn" onClick={() => navigate('/register')} >Create new account</button>
                                </div>
                            </div>
                        </div>

                    </div >
            }
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}