// react
// .........

// scss file
import './index.scss';
// .........

// package components
import { useEffect, useRef, useState } from 'react';
// .........

// child components 
import { useGlobalState } from '../../Statecontext';
import { Button, Modal, Spin, Tag } from 'antd';
import Table from 'antd/es/table';
import { serviceConfig } from '../../core/services';
import { NotifyAlert } from '../../components/notify_alert';
import { format } from 'date-fns';
// .......

// icons 
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Avatar } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useNavigate } from 'react-router-dom';

// .....

export const Notification = ({ anchorEl, setAnchorEl }: any) => {

    // alert and modal
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [detailopen, setDetailopen] = useState(false);
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    // .....

    // reference states
    const navigate = useNavigate()
    const optionsRef = useRef<any>(null);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const { setIsManagegst, setOverviewDetails, setProgres, progres, masterData, setMasterData, setFilterQuery, setSorterQuery, setSearchquery, syncedCount, setPage, setTotalpages, setTabactive, setManageactive, tabview, setTabview, setSumpopup, setSumdrawer, setRowsPerPage, setreportModal, reportmodal, syncDetails, setChangeview, setSyncDetails, setChartquery } = useGlobalState();
    // .........

    // loading state
    const [loading, setLoading] = useState(false)
    // .......

    // form value state
    let [syncId, setSyncId] = useState<any>(null)
    // ......

    // data states
    const [monitordata, setMonitorData] = useState<any>([])
    const [detailSource, setDetailsource] = useState<any>([]);
    // ......


    const getSyncdetails = () => {
        let query: any = {
            by_page: true,
            page: 1,
            page_size: 10,
        }
        setLoading(true)
        serviceConfig.get("gst_sync", true, query, null).then((data: any) => {
            setLoading(false)
            setMonitorData(data.results || data.data)
        }, (err: any) => {
            setLoading(false)
            if (err.message === "Request failed with status code 401") {
                navigate('/login')
                localStorage.clear()
                window.location.reload();
            }
        })
    }

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (optionsRef.current && !optionsRef.current?.contains(event.target)) {
                setTimeout(() => {
                }, 500);
                setAnchorEl(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsRef]);

    useEffect(() => {
        getSyncdetails()
    }, [])

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)'); // Adjust the max-width according to your mobile breakpoint
        setIsMobile(mediaQuery.matches);

        const handleResize = () => setIsMobile(mediaQuery.matches);

        mediaQuery.addListener(handleResize);

        return () => {
            mediaQuery.removeListener(handleResize);
        };
    }, []);

    const formatDateTime = (dateString: string) => {
        return format(new Date(dateString), 'hh:mm a dd-MM-yyyy');
    };

    const Time_calc = (record: any) => {
        function millisToMinutesAndSeconds(millis: any) {
            var minutes = Math.floor(millis / 60000);
            var seconds: any = ((millis % 60000) / 1000).toFixed(0);
            return (minutes < 10 ? '0' : '') + minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
        }

        let sync_end: any = new Date(record.sync_end)
        let sync_start: any = new Date(record.created_at)
        let diffMs = sync_end - sync_start
        return <span style={{ fontWeight: 'bold', color: '#E96919', cursor: 'pointer' }}>{record.sync_processed ? millisToMinutesAndSeconds(diffMs) : '--'}</span>
    }
    const detailcolumns = [
        {
            title: 'S.No',
            dataIndex: 'serial',
            key: 'serial',
            render: (text: any, record: any, index: number) => index + 1,
            width: 70,
        },
        {
            title: 'User Name',
            dataIndex: 'gst',
            render(value: any, record: any, index: any) {
                return value.user_name
            },
            fixed: 'left' as 'left',
            width: 150,
        },
        {
            title: 'Start Time',
            dataIndex: 'sync_started',
            key: 'sync_started',
            render(value: any, record: any, index: any) {
                function join(date: any, options: any, separator: any) {
                    function format(option: any) {
                        let formatter = new Intl.DateTimeFormat('en', option);
                        return formatter.format(date);
                    }
                    let joined = options.map(format).join(separator);
                    return joined + ', ' + new Date(value).toLocaleTimeString();
                }

                let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
                return value ? join(new Date(value), options, ' ') : '--';
            },
            width: 170
        },
        {
            title: 'End Time',
            dataIndex: 'sync_end',
            key: 'sync_end',
            render(value: any, record: any, index: any) {
                function join(date: any, options: any, separator: any) {
                    function format(option: any) {
                        let formatter = new Intl.DateTimeFormat('en', option);
                        return formatter.format(date);
                    }
                    let joined = options.map(format).join(separator);
                    return joined + ', ' + new Date(value).toLocaleTimeString();
                }

                let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
                return value ? join(new Date(value), options, ' ') : '--';
            },
            width: 180
        },
        {
            title: 'Status', dataIndex: 'is_sync_failed', key: 'is_sync_failed',
            render(value: any, record: any, index: any) {
                if (value === true) {
                    return <Tag color='error' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >Fail</Tag>
                } else {
                    return <Tag color='success' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >Success</Tag>
                }
            },
            width: 120
        },
        {
            title: 'Fail Reason',
            dataIndex: 'failed_reason',
            render(value: any, record: any, index: any) {
                return value ?? '--'
            },
            width: 150,
        },
        {
            title: 'Notice Count',
            dataIndex: 'notice_count',
            render(value: any, record: any, index: any) {
                return value ?? '0'
            },
            width: 100,
        },
        {
            title: 'Total Time for sync (M:S)',
            dataIndex: 'email',
            render(value: any, record: any, index: any) {
                function millisToMinutesAndSeconds(millis: any) {
                    var minutes = Math.floor(millis / 60000);
                    var seconds: any = ((millis % 60000) / 1000).toFixed(0);
                    return (minutes < 10 ? '0' : '') + minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
                }

                let sync_end: any = new Date(record.sync_end)
                let sync_start: any = new Date(record.sync_started)
                let diffMs = sync_end - sync_start
                return <span style={{ fontWeight: 'bold', color: '#E96919' }}>{record.sync_started && record.sync_end ? millisToMinutesAndSeconds(diffMs) : '--'}</span>
            },
            width: 170
        },
    ];

    return (
        <>
            <Modal
                title="Sync Details"
                onCancel={() => { setDetailopen(false); setDetailsource([]); setAnchorEl(true) }}
                className='upload-popup'
                width={'50%'}
                centered
                style={{ color: "#031742", minWidth: '30%' }}
                open={detailopen}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
                maskClosable={false}
            >
                <>
                    <div className='table-label-wrap' onClick={() => { }}>
                        <div className='table-label'>
                            <span>Total accounts</span>
                            <h4>{detailSource?.gst_list?.length}</h4>
                        </div>
                        <div className='table-label'>
                            <span>Sync start time</span>
                            <h4>{detailSource?.sync_started ? new Date(detailSource.created_at).toDateString() + ' ' + new Date(detailSource.created_at).toLocaleTimeString() : '-'}</h4>
                        </div>
                        <div className='table-label'>
                            <span>Sync end time</span>
                            <h4>{detailSource?.sync_end ? new Date(detailSource.sync_end).toDateString() + ' ' + new Date(detailSource.sync_end).toLocaleTimeString() : '-'}</h4>
                        </div>
                    </div>

                    <Table
                        style={{ minWidth: '35vw', maxWidth: '100%', marginTop: '3%' }}
                        columns={detailcolumns}
                        dataSource={detailSource?.gst_list}
                        scroll={{ y: 345 }}
                    // Other props for Table component
                    />
                </>
            </Modal >
            {
                anchorEl && <div className='Notification_cover fade-out' ref={optionsRef}>
                    <div className='head_box'>
                        <span className='head_line'>Recent Sync Details</span>
                    </div>
                    <div className='notify_content_cover'>
                        {loading ?
                            <Spin spinning={true} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute', marginTop: '4vh' }}>
                            </Spin>
                            : monitordata
                                .filter((data: any) => data.sync_processed === true)
                                .slice(0, 5)
                                .map((data: any) => (
                                    <div className='content_block' key={data} onClick={() => { setDetailopen(true); setDetailsource(data) }}>

                                        < AutorenewIcon style={{ position: 'relative' }} />
                                        <div className='content-msg-cover'>
                                            <div className='content_msg'><span>GSTIN</span> <span className='vlu'>{data.gst_list.length}</span></div>
                                            <div className='content_msg'><span>Notices</span> <span className='vlu'> {data.gst_list.map((obj: any) => obj.notice_count).reduce((accumulator: any, current: any) => accumulator + current, 0)}</span></div>
                                            <div className='content_msg'><span>Total Time</span> <span className='vlu'> {Time_calc(data)}</span></div>
                                            < ArrowRightIcon style={{ color: '#424B70', fontSize: '4.5vmin' }} />
                                        </div>
                                        <span className='content_stamp'>{formatDateTime(data.created_at)}</span>

                                    </div>
                                ))}
                        {!loading && <span className='more_btn' onClick={() => { navigate('/home/syncmonitoring'); setAnchorEl(false) }}>Click to view more</span>}
                    </div>
                </div >
            }
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}