// react 
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
// ..........

// scss file
import './overview.scss';
// ..........

// package components
import { Form, Input, Modal, Tooltip, Button as Buttons, Spin, Dropdown, Menu, Upload, Table, message } from 'antd';
// ..........

// child components
import { NotifyAlert } from '../../components/notify_alert';
import { Utils } from '../../core/utils/utils';
import { Subnotice } from '../../modules/Subnotice';
import { useGlobalState } from '../../Statecontext';
import { AppContext } from '../../core/utils/Context';
import Breadcrumbs from '../Breadcrumb';
import { downloadServices } from '../../core/services/download';
// ..........

// icons
import EditIcon from '@mui/icons-material/Edit';
import { serviceConfig } from '../../core/services';
import { Casessumary } from '../../modules/casesummary';
import { DueDashboard } from '../../modules/dueDashboard';
import { useNavigate } from 'react-router-dom';
import searchIcon from '../../assets/images/search.svg';
import plusIcon from '../../assets/images/plus.svg';
import usernameIcon from '../../assets/images/username.svg';
import passwordIcon from '../../assets/images/password.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import deleteConfirmIcon from '../../assets/images/deleteConfirm.svg';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import wrongPswdIcon from '../../assets/images/wrong_pswd.png';
import passwordDotIcon from '../../assets/images/passwordDot.svg';
// .........

interface Menu {
    name: string;
}

export const Overview = () => {

    // formvalue state
    const [actionType, setActionType] = useState("add")
    const [password_type, setPasswordType] = useState('password')
    const [form] = Form.useForm();
    const { formValues, handleChange, handleInitial } = useContext(AppContext)
    const { user_name, password, otp } = formValues;
    const [editId, setEditId] = useState('')
    const [ismanage, setIsmanage] = useState(false)
    const [tabactive, setTabActive] = useState('form')
    const [uploadedfile, setUploadedFile] = useState<any>();
    const [detailSource, setDetailsource] = useState<any>([])
    // ..........

    //  datas states
    const [gstList, setGstList] = useState<any>([])
    const [sel_gst_no, setSelGST] = useState<any>('')
    const [filterList, setFilterList] = useState<any>([])
    const [noticeData, setNoticeData] = useState<any>([])
    const [noticeObj, setNoticeObj] = useState(false);
    const [active_menu, setActive_menu] = useState('Case Summary')
    const [gstCounts, setGstCounts] = useState<any>()
    const [localsearch, setLocalsearch] = useState('')
    const [isSyncAllProgress, setIsSyncAllProgress] = useState(false)
    const { action, setAction, menuchange, manageactive, breadcrumbPath, setTotalpages, setSubselectgst, setBreadcrumbPath, selectgst, setSelectgst, progres, ismanagegst, setreportModal, setSyncDetails, setGstSyncModal, masterData, setMasterData, setProgres, setPage, searchquery, setSearchquery, setNoticeQuery, setChangeview, sorterQuery, setSorterQuery, setTabactive, chartquery, setChartquery, filterQuery, setFilterQuery, overviewDetails } = useGlobalState();
    // ..........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    const [modal2Open, setModal2Open] = useState(false);
    const [addGstLoadModal, setAddGstLoadModal] = useState(false)
    const [uploadcomplete, setUploadcomplete] = useState(true);
    const [deleteact, setDeleteact] = useState(false)
    const [multimodal, setMultimodal] = useState(false)
    // ..........

    // loading state
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false)
    const [formloading, setFormloading] = useState(false);
    // ..........

    // limit & filter states

    //........mobile screen size chart
    const [mobil_chart, setMobile_chart] = useState<any>(false)
    // ..........

    // reference states
    const { getSyncCount, handlesync } = Utils();
    const navigate = useNavigate();
    const manageGstTable = useRef<any>(null)
    const ismanageRef = useRef(ismanage);
    const manageGstDataLoading = useRef(false)
    const key = 'updatable';
    const [messageApi] = message.useMessage();
    // ..........

    // data get function
    useEffect(() => {
        getGSTCounts()
    }, [])

    useEffect(() => {
        ismanageRef.current = ismanage;
        setIsmanage(ismanagegst)
    }, [ismanagegst, ismanage])

    const getGSTCounts = () => {
        let query: any = {
            'gst_no': overviewDetails.gst_no
        }
        serviceConfig.get("get_group_notices", true, query, null).then((data: any) => {
            { data && setGstCounts(data[0]) }
        }, (err: any) => {
            console.log(err)
        })
    }
    // ..........

    // sub_menus list 
    const submenu: Menu[] = [
        {
            name: 'Case Summary',
        },
        {
            name: 'Notices & Orders',
        },
        {
            name: 'Manual Notices',
        },
        {
            name: 'Latest Notices',
        },
        {
            name: 'Due Calendar',
        }
    ];

    const submenu1 = submenu.splice(0, 3);
    const submenu2 = submenu.splice(-2, 2);
    // ..........

    // menu click function
    const menuclick = (menu: any) => {
        setActive_menu(menu);
        if (menu === 'Due Calendar') {
            setChangeview(true)
        } else if (menu === 'Manual Notices') {
            setTabactive('Manual');
            setChangeview(false);
        } else {
            setChangeview(false);
        }
    }
    // .........

    const renderMenuItems = () => {
        const submenu: Menu[] = [
            {
                name: 'Case Summary',
            },

            {
                name: 'Manual Notices',
            },

            {
                name: 'Due Calendar',
            }
        ];

        return submenu.map((menu: any) => (
            <div
                className={active_menu === menu.name ? 'overview_menu active' : 'overview_menu'}
                key={menu.name}
                onClick={() => { menuclick(menu.name) }}
            >
                <span>{menu.name}</span>
            </div>
        ));
    };

    // search 
    const searchclick = (value: any) => {
        setPage(1)
        const filterTable = gstList.filter((o: any) =>
            Object.keys(noticeObj ? o.notice : o).some(k => {
                if (k !== 'gst' && k !== 'case_data') {
                    return String(noticeObj ? o.notice[k] : o[k])
                        .toLowerCase()
                        .includes(value.toLowerCase())
                } else {
                    return Object.keys(noticeObj ? o.notice[k] : o[k]).some(j => String(noticeObj ? o.notice[k][j] : o[k][j])
                        .toLowerCase()
                        .includes(value.toLowerCase()))
                }
            }
            )
        );

        setFilterList(filterTable);
        // if (filterTable.length > 0) {
        //     setSelectgst(filterTable[0])
        //     let query: any = {
        //         gst_no: [filterTable[0].gst_no]
        //     };
        //     setBreadcrumbPath((prevPath: any) => {
        //         const pathSegments = prevPath.split('-').filter(Boolean); // Split the path by '/' and remove empty segments
        //         pathSegments.pop(); // Remove the last segment
        //         return `${pathSegments.join('/')} - ${filterTable[0].gst_no || filterTable[0].user_name}`; // Join remaining segments and append the new gst_no
        //     });
        //     setFilterQuery(query);
        // }
    };

    const handleGlobalSearchChange = (e: any) => {
        let value = e.target.value;

        if (!value) {
            setSearchquery(value)
            setLocalsearch(value)
            searchclick(value);
        } else {
            setLocalsearch(value)
        }

    }

    // .............


    // fetch filter data
    const fetchData = async () => {
        try {
            const gstData = await serviceConfig.get("master_gst", true, null, null);
            const statesData = await serviceConfig.get("master_states", true, null, null);
            const tradesData = await serviceConfig.get("master_trades", true, null, null);
            const usernamesData = await serviceConfig.get("master_usernames", true, null, null);

            setMasterData({
                ...masterData,
                gst: gstData.data,
                states: statesData.data,
                trades: tradesData.data,
                userNames: usernamesData.data
            });

            localStorage.setItem('masterData', JSON.stringify({
                gst: gstData.data,
                states: statesData.data,
                trades: tradesData.data,
                userNames: usernamesData.data
            }));
        } catch (err) {
            console.log(err);
        }
    };
    // .........

    // gst get call
    const loadGst = useCallback((search_data?: any) => {
        manageGstDataLoading.current = true;
        setLoading(true)

        let query: any = {
        }
        if (searchquery) {
            query['search'] = searchquery;
        }

        query['active'] = true


        serviceConfig.get("manageGst", true, query, null).then((data: any) => {
            setLoading(false)
            setFilterList(data)
            setGstList(data);
            if (selectgst) {
                let query: any = {
                    gst_no: [selectgst.gst_no || selectgst.gst.gst_no]
                };
                setBreadcrumbPath(`${breadcrumbPath}/${selectgst.gst_no || selectgst.gst.gst_no || selectgst.user_name || selectgst.gst.user_name}`);
                setFilterQuery(query);

            } else {
                setSelectgst(data[0])
                let query: any = {
                    gst_no: [data[0].gst_no]
                };
                setBreadcrumbPath(`${breadcrumbPath}/${data[0].gst_no || data[0].user_name}`);
                setFilterQuery(query);
            }
            getSyncCount()
            manageGstDataLoading.current = false;

        }, (err: any) => {
            setLoading(false)
            if (err.message === "Request failed with status code 401") {
                navigate('/login')
                localStorage.clear()
                window.location.reload();
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action])

    useEffect(() => {
        loadGst()
    }, [loadGst])

    useEffect(() => {
        setLocalsearch('')
        searchclick('');
    }, [menuchange])
    // .........

    // edit or add gst function
    // Check if all values are not empty and if there are some errors
    const isError = useCallback(
        () =>
            Object.keys({ user_name, password }).some(
                (name) => (formValues[name].required && !formValues[name].value) || formValues[name].error
            ),
        [formValues, user_name, password]
    )

    const actionGst = () => {
        setFormloading(true)
        setModal2Open(false)
        setAddGstLoadModal(true)

        { actionType !== "Edit" && setProgres({ data: { loading: true, label: 'Upload' } }) }

        const addformdata = { user_name: formValues.user_name.value, password: formValues.password.value }

        serviceConfig[actionType === "Edit" ? "put" : "post"]("manageGst", true, { ...addformdata, id: actionType === "Edit" ? editId : '' }, false, false, true)
            .then((res: any) => {
                setAction(true)
                if (res.id) {
                    loadGst()
                    setProgres({ data: { loading: false, label: '' } })
                    // getFileUploadStatus()
                    setFormloading(false)
                    setNotifyType("success")
                    setNotifyMessage(actionType === "Edit" ? "GST details updated successfully." : "GST details added successfully.")
                    setNotifyOpen(true)
                    manageGstTable.current.setTableQuery({})
                    handleInitial()
                    setAddGstLoadModal(false)
                    fetchData()
                    setPasswordType('password')
                }
                if (res === "GST data updated successfully.") {
                    setProgres({ data: { loading: false, label: '' } })
                    setFormloading(false)
                    setNotifyType("success")
                    setNotifyMessage(actionType === "Edit" ? "GST details updated successfully." : "GST details added successfully.")
                    setNotifyOpen(true)
                    manageGstTable.current.setTableQuery({})
                    handleInitial()
                    loadGst()
                    setAddGstLoadModal(false)
                    fetchData()
                    setPasswordType('password')
                }
                else {
                    if (res.message) {
                        setProgres({ data: { loading: false, label: '' } })
                        const { msg, ...rest } = res.data;
                        setFormloading(false)
                        setNotifyType("warning")
                        setNotifyMessage("Enter your OTP.")
                        setNotifyOpen(true)
                        setAddGstLoadModal(false)
                        fetchData()
                    }
                }
            }).catch((err: any) => {
                setProgres({ data: { loading: false, label: '' } })
                setNotifyType("error")
                if (err.response && err.response.data) {
                    setNotifyMessage(err.response.data);
                } else {
                    setNotifyMessage("An error occurred");
                }
                setNotifyOpen(true)
                setFormloading(false)
                setAddGstLoadModal(false)
                setPasswordType('password')
            })
        handleInitial()
    }


    const handleAction = (type?: string, data?: any) => {
        setActionType(type || 'add')
        if (type === 'Edit') {
            setSelGST(data.gst_no)
            setEditId(data.id)
            handleInitial();
            Object.keys(data).forEach((key) => {
                if (formValues[key]) {
                    handleChange({
                        target: { type: 'text', name: key, value: data[key] },
                    } as React.ChangeEvent<HTMLInputElement>);
                }
            });
        }
        setModal2Open(true)
    }

    // gstin delete function
    const handleClickdelete = (id: any) => {
        setSelGST(id.gst_no)
        setEditId(id.id)
        setDeleteact(true);
    }
    const handleDelete = (data?: any) => {

        const idToDelete = editId;

        setFormloading(true)
        serviceConfig.delete("manageGst", true, { id: idToDelete }, false).then((res: any) => {
            setNotifyType("success")
            setNotifyMessage(res)
            setNotifyOpen(true)
            setFormloading(false)
            setDeleteact(false)
            manageGstDataLoading.current = false;
            // manageGstTable.current.setTableQuery({})
            if (selectgst === editId) {
                setSelectgst(null)
            }
            setBreadcrumbPath('home/Notices/GSTIN wise Notices')
            loadGst()
        })
            .catch((err: any) => {
                setNotifyType("error")
                setNotifyMessage(err.response.data)
                setNotifyOpen(true)
                setFormloading(false)
            })
    }
    // ........

    // sync initiate function
    const syncProgress = useRef(false)
    let [syncId, setSyncId] = useState<any>(null)

    const getStatusofSyncAllProgress = async () => {
        try {
            setProgres({ data: { loading: true, label: 'Sync' } });

            const data = await serviceConfig.get("gst_sync_details", true, null, { id: syncId });
            setSyncDetails(data)
            setIsSyncAllProgress(!data.sync_processed);
            setSyncId(data.id);

            if (!data.sync_processed) {
                const timerInt = setTimeout(() => {
                    if (ismanageRef.current) {
                        loadGst()
                    }
                    getStatusofSyncAllProgress()
                }, 10 * 1000);

                // Ensure the timeout is cleared when the sync is processed
                if (data.sync_processed) {
                    clearTimeout(timerInt)
                    finalizeSync();
                }
            } else {
                finalizeSync();
            }
        } catch (err) {
            setProgres({ data: { loading: false, label: '' } });
        }
    }

    const finalizeSync = () => {
        setreportModal(true);
        syncProgress.current = false;

        if (ismanageRef.current) {
            loadGst();
        }

        manageGstDataLoading.current = false;
        setProgres({ data: { loading: false, label: '' } });
    }
    // ........

    // add gstin dropdown
    const add_opt_submenu = [
        {
            items: [
                { name: 'Add GSTIN', openstate: setModal2Open },
                { name: 'Add Multiple GSTIN', openstate: setMultimodal },
            ]
        }
    ]
    const renderAddMenu = (submenu: any[] | undefined, index: number) => (
        <Menu
            style={{ display: submenu ? '' : 'none' }}
            className="horizontal-grouped-menu"
        >
            {submenu?.[0]?.items.map((item: any) => (
                <Menu.Item key={item.name} onClick={() => submenuClick(item)}>
                    {item.name}
                </Menu.Item>
            ))}
        </Menu>
    );

    const submenuClick = (item: any) => {
        item.openstate(true)
    }
    // ......


    // form modal function
    const handlecloseuploadpopup = () => {
        setMultimodal(false)
        setFormloading(false)
        setUploadedFile(null)
        setTabActive('form');
    }
    // .....

    //.manage gst multiple add gst
    const handleUpload = (info: any) => {
        setUploadedFile(info.file.originFileObj)

        if (info.file.status === 'done') {
            setUploadedFile(info.file)
        } else if (info.file.status === 'error') {
            console.log(`${info.file.name} file upload failed.`);
        }
    };

    const handleDragEnter = (e: React.DragEvent) => {
        e.preventDefault();
        const files: any = e.dataTransfer.files[0];
        // setUploadedFile(files)
        if (files && files.length > 0) {

            if (files.type === 'application/vnd.ms-excel' || files.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                setUploadedFile(files);
            } else {
                setNotifyOpen(true)
                setNotifyType("error")
                setNotifyMessage('Please select a valid csv or xslx file.');
                // alert('Please select a valid csv or xslx file.');
                setUploadedFile(null)
            }
        } else {
            setUploadedFile(null);
        }
    };

    const handleDragLeave = () => {
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();

        const files: any = e.dataTransfer.files[0];
        // setUploadedFile(files)
        if (files && files.length > 0) {

            if (files.type === 'application/vnd.ms-excel' || files.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                setUploadedFile(files);
            } else {
                setNotifyOpen(true)
                setNotifyType("error")
                setNotifyMessage('Please select a valid csv or xslx file.');
                // alert('Please select a valid csv or xslx file.');
                setUploadedFile(null)
            }
        } else {
            setUploadedFile(null);
        }
    };


    const getFileuploadstatus = () => {
        setUploading(true)
        var start_autoload: any = setInterval(() => {
            serviceConfig.get('gst_upload_file_status', true, null, null)
                .then((data: any) => {
                    if (data && data.length > 0 && data[0].is_completed) {
                        clearInterval(start_autoload)
                        start_autoload = null;
                        setProgres({ data: { loading: false, label: '' } })
                        setUploadcomplete(data[0].is_completed)
                        setDetailsource(data)
                        handletabClick('table')
                        setUploading(false)
                        setMultimodal(true);
                    }
                }, (err: any) => {
                    clearInterval(start_autoload)
                    start_autoload = null;
                    setProgres({ data: { loading: false, label: '' } })
                    setUploading(false)
                })
        }, 10000);
    }

    const UploadFile = () => {
        setFormloading(true)
        let file_data = uploadedfile.hasOwnProperty('originFileObj') ? uploadedfile.originFileObj : uploadedfile
        serviceConfig.upload("upload_file", null, file_data)
            .then((data: any) => {
                setFormloading(false)
                setNotifyOpen(true)
                setNotifyType(data.data)
                setNotifyMessage(data.message);
                setUploadedFile(null)
                setMultimodal(false)
                setUploadedFile(null)
                openMessage()
                setProgres({ data: { loading: true, label: 'Upload' } })
                getFileuploadstatus()
            }).catch((err: any) => {
                setUploadedFile(null)
                setFormloading(false)
                setNotifyOpen(true)
                setNotifyType("error")
                setNotifyMessage(err.response.data);
            })
    }

    const handletabClick = (label: any) => {
        setTabActive(label);
    }

    // message open function
    const openMessage = () => {
        messageApi.open({
            key,
            type: 'loading',
            content: 'Loading...',
        });
        setTimeout(() => {
            messageApi.open({
                key,
                type: 'success',
                content: 'Add Multiple GSTIN sync started successfully!',
                duration: 2,
            });
        }, 1000);
    }
    // ..............
    const detailcolumns = [
        {
            title: 'S.No',
            dataIndex: 'serial',
            key: 'serial',
            render: (text: any, record: any, index: number) => index + 1,
        },
        {
            title: 'User Name',
            dataIndex: 'user_name',
            key: 'user_name',
        },
        {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
            render(value: any, record: any, index: any) {
                if (!record.wrong_password) {
                    return <img src={passwordDotIcon} alt='password icon' />
                }
                else {
                    return <Tooltip title={'Given credentials mismatched!'}><img src={wrongPswdIcon} alt='password icon' /></Tooltip>
                }
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];
    // ..............

    return (
        <>
            <Modal
                title={actionType === 'add' ? "ADD GSTIN" : `EDIT: ${sel_gst_no}`}
                onCancel={() => { setModal2Open(false); setFormloading(false); handleInitial(); setPasswordType('password'); setActionType('add') }}
                width={'30%'}
                className='action-popup'
                centered
                style={{ color: "#031742" }}
                open={modal2Open}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >

                <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
                    <div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
                        <label className="label">User id</label>
                        <div className="input-box" >
                            <img className="icons" src={usernameIcon} alt='usernameIcon'></img>
                            <input
                                type="text"
                                name='user_name'
                                value={user_name.value}
                                onBlur={handleChange}
                                onChange={handleChange}
                                placeholder="Enter your portal user id"></input>
                            {user_name.error && <p className="gst-err-msg" >{user_name.error}</p>}
                        </div>
                    </div>
                    <div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
                        <label className="label">Password</label>
                        <div className="input-box" >
                            <img className="icons" src={passwordIcon} alt='password_icon'></img>
                            <input
                                type={password_type}
                                name='password'
                                value={password.value}
                                onBlur={handleChange}
                                onChange={handleChange}
                                placeholder="Enter your password"></input>
                            {password_type === 'password' ? <VisibilityOutlinedIcon onClick={() => setPasswordType('text')} className='eye_icon' /> : <VisibilityOffOutlinedIcon onClick={() => setPasswordType('password')} className='eye_icon' />}
                            {password.error && <p className="gst-err-msg">{password.error}</p>}
                        </div>
                    </div>

                    <Buttons
                        htmlType="submit"
                        className='popup-button'
                        loading={formloading}
                        disabled={isError()}
                        style={{ background: "#424B70", color: "white", border: "none", width: "80%", height: "6vh", marginTop: '1.5rem', left: '10%' }}
                        onClick={() => {
                            actionGst()
                        }}>
                        {!formloading && <>{actionType === 'add' ? 'Add' : 'Update'} & Sync GSTIN</>}
                        {formloading && <>Loading...</>}
                    </Buttons>

                </Form>
            </Modal>
            <Modal
                onCancel={() => setDeleteact(false)}
                centered
                closeIcon={false}
                className='delete-gst'
                style={{ textAlign: "center", color: "#031742", top: '-14%' }}
                open={deleteact}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
                    <img src={deleteConfirmIcon} alt='action' />
                    <h2 className="del-header">Are you sure want to delete this GSTIN</h2>
                    <span style={{ textAlign: "right", display: "none" }}>This field is required</span>
                    <p className="gstin-num">GSTIN : {sel_gst_no}</p>
                    <Buttons htmlType="submit" style={{ background: "none", color: "#ed7d31", border: "0.5px solid #ed7d31", width: "30%", height: "5vh", fontFamily: 'Inter' }} onClick={() => {
                        setDeleteact(false)
                    }}>CANCEL</Buttons>
                    <Buttons htmlType="submit" onClick={handleDelete} loading={formloading} style={{ background: "#424B70", color: "white", border: "none", width: "30%", height: "5vh", marginLeft: "10%", fontFamily: 'Inter' }}  >DELETE</Buttons>
                </Form>
            </Modal>
            <Modal
                title="Multiple GSTIN"
                onCancel={handlecloseuploadpopup}
                className='upload-popup'
                width={'auto'}
                centered
                style={{ color: "#031742", minWidth: '30%' }}
                open={multimodal}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <div className="detail-view-tab-cover-container">
                    <div className='detail-view-tab-cover'>
                        <span className={tabactive === 'form' ? 'view-tab one active-tab' : 'view-tab one'} onClick={() => handletabClick('form')}>Upload</span>
                        <span className={tabactive === 'table' ? 'view-tab two active-tab' : 'view-tab two'} onClick={() => { handletabClick('table'); getFileuploadstatus() }}>Details</span>
                    </div>
                </div>
                {tabactive === 'form' ?
                    <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">

                        <Upload
                            action="/your-upload-api-endpoint"
                            onChange={handleUpload}
                            style={{ width: '100%' }}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            showUploadList={false} // Hide the list of uploaded files
                        >
                            <div
                                className='upload_box'
                                onDragEnter={handleDragEnter}
                                onDragOver={handleDragEnter}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}>
                                <input
                                    type="file"
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    style={{ display: 'none' }}
                                    onChange={handleUpload}
                                />
                                {uploadedfile ?
                                    <p className='selected-file-name'>
                                        <b>Selected File: </b>{uploadedfile.name}</p> :
                                    <p>
                                        Drag & Drop files here or click to select<br></br>
                                        (CSV or XLSX (.csv or .xlsx)) file only allowed)
                                    </p>}
                            </div>
                        </Upload>

                        <div className='downlod-template-wrap'>
                            <p className='download-link' onClick={() => downloadServices.gst_template()}>Download Template</p>
                        </div>

                        <Form.Item>
                            <Buttons
                                htmlType="submit"
                                className='popup-button upload-pop-btn'
                                loading={formloading}
                                disabled={!uploadedfile || !uploadcomplete}
                                style={{
                                    background: "#424B70",
                                    color: "white",
                                    border: "none",
                                    width: "80%",
                                    height: "6vh",
                                    marginTop: '1.5rem',
                                    left: '10%'
                                }}
                                onClick={() => {
                                    UploadFile()
                                }}>
                                {!formloading && <>{uploadcomplete ? 'Upload' : 'Upload Inprogressing'}</>}
                                {formloading && <>Loading...</>}
                            </Buttons>
                        </Form.Item>

                    </Form> :
                    <>
                        <div className='table-label-wrap'>
                            <div className='table-label'>
                                <span>File name</span>
                                <h4>{detailSource.length > 0 ? detailSource[0].file_name : '-'}</h4>
                            </div>
                            <div className='table-label'>
                                <span>Total count</span>
                                <h4>{detailSource.length > 0 ? detailSource[0].total_count : '-'}</h4>
                            </div>
                            <div className='table-label'>
                                <span>Success count</span>
                                <h4>{detailSource.length > 0 ? detailSource[0].success_count : '-'}</h4>
                            </div>
                            <div className='table-label'>
                                <span>Error count</span>
                                <h4>{detailSource.length > 0 ? detailSource[0].error_count : '-'}</h4>
                            </div>
                        </div>
                        <Table
                            className='details-table'
                            loading={uploading}
                            style={{ minWidth: '35vw', maxWidth: '100%', marginTop: '3%' }}
                            columns={detailcolumns}
                            dataSource={detailSource.length > 0 ? detailSource[0]?.gst_list.filter((it: any) => it.is_error) : []}
                        // Other props for Table component
                        />
                    </>
                }
            </Modal >
            <div id="overview-details-container" className='view-gst-info'>
                <div className='overview-menu-wrap'>
                    <div className='list_wrap'>
                        <div className='search_cover'>
                            <ArrowBackIosNewIcon onClick={() => {
                                navigate('/home/allgstin');
                                setSubselectgst([])
                                setSelectgst(null)
                                setBreadcrumbPath('home/notices/GSTIN wise Notices');
                                setTotalpages(0);
                                setFilterQuery({})
                                setSorterQuery({})
                            }} fontSize='small' className='icon' />
                            <Input
                                className='z-table_global_search'
                                value={localsearch}
                                onChange={handleGlobalSearchChange}
                                placeholder='Type here to search...'
                                suffix={<img
                                    src={searchIcon}
                                    onClick={() => searchclick(localsearch)}
                                    style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                                    alt='password icon' />}
                            />
                            <Dropdown overlay={renderAddMenu(add_opt_submenu, 0)} >
                                <Buttons className='gst_add_btn' >
                                    <img src={plusIcon} alt='plus icon' />
                                </Buttons>
                            </Dropdown>
                        </div>
                        <div className='list_item_wrap'>
                            {!loading && filterList ? filterList.length === 0 ?
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <span style={{ color: '#000', }}>No Data</span>
                                </div> :
                                filterList.map((detail: any, index: any) => (
                                    <div
                                        className={
                                            selectgst
                                                && selectgst
                                                && selectgst.id === detail.id
                                                ? 'list_item mark-border' : 'list_item'
                                        }
                                        onClick={() => {
                                            setSelectgst(detail);
                                            // setFilterList((prevItems: any) => {
                                            //     const newItems = [...prevItems];
                                            //     const selectedItem = newItems.splice(index, 1)[0]; // Remove selected item
                                            //     newItems.unshift(selectedItem); // Add selected item at the beginning
                                            //     return newItems;
                                            // });
                                            let query: any = {
                                                gst_no: [detail.gst_no]
                                            };
                                            setFilterQuery(query);
                                            setBreadcrumbPath(`/home/notices/GSTIN wise notices/${detail.gst_no}`)
                                            // setBreadcrumbPath((prevPath: any) => {
                                            //     const pathSegments = prevPath.split('-').filter(Boolean); // Split the path by '/' and remove empty segments
                                            //     pathSegments.pop(); // Remove the last segment
                                            //     return `${pathSegments.join('/')} - ${detail.gst_no}`; // Join remaining segments and append the new gst_no
                                            // });
                                        }}
                                        key={index}>
                                        <div className='head_wrap'>
                                            <span className='gst_value'>{detail.gst_no || detail.user_name || '_'}</span>
                                            <Tooltip placement="right" title={'Edit GSTIN'} arrow={true} color={'#424B70'} >
                                                <Buttons
                                                    className='gst_sync_btn'
                                                    onClick={(e: any) => {
                                                        e.stopPropagation(); // Prevent triggering the parent div's onClick
                                                        handleAction("Edit", detail)
                                                    }}>
                                                    <EditIcon className='edit_icn' />
                                                </Buttons>
                                            </Tooltip>
                                        </div>
                                        <div className='content_wrap'>
                                            <span className='name_value'>{detail.trade_name || detail.password || '_'}</span>
                                            <Tooltip placement="right" title={'Delete GSTIN'} arrow={true} color={'#E96919'} >
                                                <Buttons
                                                    className='gst_sync_btn'
                                                    onClick={(e: any) => {
                                                        e.stopPropagation(); // Prevent triggering the parent div's onClick
                                                        handleClickdelete(detail)
                                                    }}>
                                                    <DeleteIcon className='delete_icn' />
                                                </Buttons>
                                            </Tooltip>
                                        </div>
                                    </div>
                                )) :
                                <Spin spinning={true} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute', marginTop: '15%', }}>
                                </Spin>
                            }
                        </div>
                    </div>
                </div>
                <div className="overview-upper-container" style={{ height: mobil_chart ? '60%' : "100%" }}>
                    <div className="overview-notice-main">
                        <div className='overview_menu_wrap'>
                            <Breadcrumbs />
                            {renderMenuItems()}
                        </div>
                        {active_menu === 'Case Summary' && <Casessumary selectedgst={selectgst} />}
                        {active_menu === 'Notices & Orders' && <Subnotice />}
                        {active_menu === 'Manual Notices' && <Subnotice />}
                        {active_menu === 'Latest Notices' && <Subnotice />}
                        {active_menu === 'Due Calendar' && <DueDashboard />}
                    </div>
                </div>
                <div className='mobile_view_design'>

                    <div className='submenu_container'>
                        {submenu1.map((menu: any) => (
                            <div
                                style={{ borderRight: menu.name === "Manual Notices" ? "none" : "1px solid #424B70" }}
                                className={active_menu === menu.name ? 'mob_overview_menu active' : 'mob_overview_menu'}
                                key={menu.name}
                                onClick={() => { menuclick(menu.name) }}
                            >
                                <span>{menu.name}</span>
                            </div>
                        ))}
                    </div>
                    <div className='submenu_container2'>
                        {submenu2.map((menu: any) => (
                            <div
                                style={{ borderRight: menu.name === "Due Calendar" ? "none" : "1px solid #424B70" }}
                                className={active_menu === menu.name ? 'mob_overview_menu active' : 'mob_overview_menu'}
                                key={menu.name}
                                onClick={() => { menuclick(menu.name) }}
                            >
                                <span>{menu.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div >
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}

