// react
import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// scss file
import './allnotice.scss';

// package components

// child components
import { NotifyAlert } from "../../components/notify_alert";
import Breadcrumbs from "../../shared/Breadcrumb";
import { useGlobalState } from '../../Statecontext';
import { Subnotice } from '../Subnotice';

// icons

export const Allnotice = () => {

    //  datas states
    const [filterList, setFilterList] = useState<any>([])
    const [gstList, setGstList] = useState<any>([])
    const [noticeObj, setNoticeObj] = useState(false);
    const {
        setAmountOperator,
        setFromTaxAmount,
        setToNoticeDate,
        setFromNoticeDate,
        setToDueDate,
        setFromDueDate,
        menuclick,
        setMenuclick,
        setPage,
        setSorterQuery,
        setGlobalsearch,
        setBreadcrumbPath,
        setSelectgst,
        setNoticeQuery,
        setTabactive,
        setExpanded,
        submenuchange,
        setSubmenuchange,
        setFilterQuery,
        setSectionValue } = useGlobalState();

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')

    // loading state
    const [loading, setLoading] = useState(false);

    // limit & filter states

    // reference state
    useEffect(() => {
        setBreadcrumbPath(`home/notices/${menuclick}`);
    }, [menuclick])


    const menu_itms: any = [
        {
            name: 'Latest notices'
        },
        {
            name: 'Action taken'
        },
        {
            name: 'Action to be taken'
        },
        {
            name: 'No action required'
        },
        {
            name: 'Overdue'
        },
        {
            name: 'Manual notices'
        },
        {
            name: 'Section 128A Waiver'
        },
        {
            name: 'All notices'
        }
    ]
    // ........
    const menuClickhandler = (item: any) => {
        setGlobalsearch('')
        setExpanded(false)
        setMenuclick(item.name)
        setSubmenuchange(!submenuchange)
        setFilterQuery({})
        setSorterQuery({})
        setFromDueDate('')
        setToDueDate('')
        setFromNoticeDate('')
        setToNoticeDate('')
        setFromTaxAmount('')
        setAmountOperator('eq')
        // Define tab and breadcrumb mappings
        const tabMapping: Record<string, string> = {
            'All notices': 'Additional',
            'Latest notices': 'Latest',
            'Action to be taken': 'Additional',
            'Action taken': 'Additional',
            'No action required': 'Additional',
            'Overdue': 'Additional',
            'Manual notices': 'Manual',
            'Section 128A Waiver': 'Section128A'
        };

        const breadcrumbMapping: Record<string, string> = {
            'All notices': 'home/notices/all notices',
            'Latest notices': `home/notices/${item.name}`,
            'Action to be taken': `home/notices/${item.name}`,
            'Action taken': `home/notices/${item.name}`,
            'No action required': `home/notices/${item.name}`,
            'Overdue': `home/notices/${item.name}`,
            'Manual notices': `home/notices/${item.name}`,
            'Section 128A Waiver': 'home/notices/Section 128A Waiver',
        };

        setTabactive(tabMapping[item.name]);
        setBreadcrumbPath(breadcrumbMapping[item.name] || item.name);

        let updatedQuery: Record<string, any> = { is_over_due: false };
        if (['Action to be taken', 'Action taken', 'No action required'].includes(item.name)) {
            updatedQuery['notice_status'] = [item.name];
        } else if (item.name === 'Overdue') {
            updatedQuery['is_over_due'] = true;
        }

        if (breadcrumbMapping[item.name]) {
            setNoticeQuery(updatedQuery);
        }
        if (item.name === 'Section 128A Waiver') {
            setSectionValue('73')
        }
    }
    return (
        <>
            <div id="allnotice-details-container" className='view-gst-info'>
                <div className='overview-menu-wrap'>
                    <div className='list_wrap'>
                        {/* <div className='search_cover'>
                            <Input
                                className='z-table_global_search'
                                value={localsearch}
                                onChange={handleGlobalSearchChange}
                                placeholder='Type here to search...'
                                suffix={<img
                                    src={searchIcon}
                                    onClick={() => searchclick(localsearch)}
                                    style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                                    alt='password icon' />}
                            />
                            <Tooltip placement="right" title={'Add GSTIN'} arrow={true} color={'#424B70'} >
                                <Buttons className='gst_add_btn' onClick={() => { handleAction() }}>
                                    <img src={plusIcon} alt='plus icon' />
                                </Buttons>
                            </Tooltip>
                        </div> */}
                        <div className='list_item_wrap'>
                            {menu_itms.map((menu: any, index: any) => (
                                <div
                                    className={menuclick === menu.name ? 'menu_block menu_active' : 'menu_block'}
                                    key={index}
                                    onClick={() => { menuClickhandler(menu); }}>
                                    {menu.name}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="overview-upper-container" style={{ height: "100%" }}>
                    <div className="overview-notice-main">
                        <div className='overview_menu_wrap'>
                            <Breadcrumbs />
                        </div>
                        <Subnotice />
                    </div>
                </div>

            </div >
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}