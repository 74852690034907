// react 
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format'
// ..........

// scss file
import './viewnotice.scss';
// ..........

// package components
import Table from 'antd/es/table'
import { useGlobalState } from '../../Statecontext';
import { ZTable2 } from '../../components/table2';
import { DatePicker as AntDatePicker, Button, Modal, Radio, RadioChangeEvent, Select, Tag, Tooltip } from 'antd';
// ..........

// child components
import { NotifyAlert } from '../../components/notify_alert';
import { serviceConfig } from '../../core/services';
import { Utils } from '../../core/utils/utils';
// ..........

// icons
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import filterIcon from '../../assets/images/filter.svg'

import VisibilityIcon from '@mui/icons-material/Visibility';
import ReplyIcon from '@mui/icons-material/Reply';

import attachment_icon from '../../assets/images/attachment_icon.png';

// ..........

export const ViewNotices = () => {

    //  datas states
    const [selected, setSelected] = useState('Select Action')
    const [noticeData, setNoticeData] = useState<any>(null)
    const [viewsummarydetails, setViewSummaryDetails] = useState<any>({})
    const [replydetails, setReplydetails] = useState<any>({})
    const { setNoticeQuery, summarydetails, totalpages, setTotalpages, page, rowsPerPage, masterData, sorterQuery, filterQuery, statuschange, setStatuschange, selectedGstRows, viewnoticeacitve, setViewnoticeactive, setSelectedGstRows } = useGlobalState();
    // ..........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    const [noticeStatus, setNoticeStatus] = useState('Action to be taken');
    const [viewTaskLink, setViewTaskLink] = useState('')
    const [replymodal, setReplymodal] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    // ..........

    // loading state
    const [loading, setLoading] = useState(false)
    // ..........

    // limit & filter states
    const [fromTaxAmount, setFromTaxAmount] = useState<any>('')
    const [amountOperator, setAmountOperator] = useState<any>('eq')
    // ..........

    // reference states
    const gstTableElement = useRef<any>(null);
    const { ticket, MultiNoticeUpdate } = Utils();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    let location = useLocation();
    const navigate = useNavigate()
    // ..........

    // data get function
    const getNoticeData = useCallback(() => {
        setLoading(true)
        let query: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage,
        }

        let notice_id: any = location.pathname.split('/')[2];

        // Ensure notice_id is an array
        if (!Array.isArray(notice_id)) {
            // If notice_id is not an array, convert it to an array containing only the original value
            notice_id = [notice_id];
        }

        query['gst_case_id'] = notice_id.join(',')


        if (viewnoticeacitve === 'Additional') {
            query['master_type'] = viewnoticeacitve
        }
        if (viewnoticeacitve === 'Appeal') {
            query['master_type'] = viewnoticeacitve
        }

        serviceConfig.get("get_notices", true, query, null).then((data: any) => {
            setLoading(false)
            setNoticeData(data.results)
            setTotalpages(data.count)
        }, (err: any) => {
            setLoading(false)
            if (err.message === "Request failed with status code 401") {
                navigate('/login')
                localStorage.clear()
                window.location.reload();
            }
        })
    }, [viewnoticeacitve])

    useEffect(() => {
        getNoticeData()
    }, [getNoticeData, filterQuery])


    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)'); // Adjust the max-width according to your mobile breakpoint
        setIsMobile(mediaQuery.matches);

        const handleResize = () => setIsMobile(mediaQuery.matches);

        mediaQuery.addListener(handleResize);

        return () => {
            mediaQuery.removeListener(handleResize);
        };
    }, []);

    useEffect(() => {
        let notice_id: any = location.pathname.split('/')[2];

        const noticeheader = summarydetails.filter((obj: any) => obj.id === notice_id);

        setViewSummaryDetails(noticeheader[0])

        setViewnoticeactive('Additional')
    }, [])
    // ..........

    // radio selection 
    const handlechange = (value: string) => {
        setSelected(value);
        setDropdownOpen(false);  
    };
    const onChange = (e: RadioChangeEvent) => {
        setNoticeStatus(e.target.value);
        setSelected(e.target.value);
        setDropdownOpen(false);  
    };
    // ...........

    // update status
    const update = () => {
        const idArray: string[] = selectedGstRows.map((obj: { id: any }) => obj.id);
        MultiNoticeUpdate(idArray, noticeStatus).then((data: any) => {
            setNotifyType("success")
            setNotifyMessage("Notice status updated successfully.")
            setNotifyOpen(true)
            setSelectedGstRows([])
            setStatuschange(false)
            getNoticeData()
        }, (err: any) => {
            setNotifyType("error")
            setNotifyMessage(err.response.message)
            setNotifyOpen(true)
            setSelectedGstRows([])
        })
    }
    // ............

    // update status
    const handleCellClick = (value: any) => {
        let query: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage,
        }

        let notice_id: any = location.pathname.split('/')[2];

        // Ensure notice_id is an array
        if (!Array.isArray(notice_id)) {
            // If notice_id is not an array, convert it to an array containing only the original value
            notice_id = [notice_id];
        }

        query['gst_case_id'] = notice_id.join(',')


        if (viewnoticeacitve === 'Additional') {
            query['master_type'] = viewnoticeacitve
        }
        if (viewnoticeacitve === 'Appeal') {
            query['master_type'] = viewnoticeacitve
        }
        setNoticeQuery(query)
        navigate(`/home/${value}/view-gst-details`)
    };
    // ............

    // table columns and filter requirement props
    const columns = [
        {
            title: 'Notice Type',
            key: 'notice_type',
            dataIndex: 'notice_type',
        },
        {
            title: 'Notice Date',
            dataIndex: 'issue_date',
            key: 'issue_date',
            render(value: any, record: any, index: any) {
                return value && value.indexOf('/') !== -1 ? value.split('/').join('-') : value ? value : '--'
            }
        },
        {
            title: 'Due date', dataIndex: 'due_date', key: 'due_date',
            render(value: any, record: any, index: any) {
                return value && value.indexOf('/') !== -1 ? value.split('/').join('-') : value ? value : '--'
            }
        },
        {
            title: 'Tax amount', dataIndex: 'tax_amount', key: 'tax_amount',
            render(value: any, record: any, index: any) {
                return (value ? <CurrencyFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'₹ '} /> : '--')
            },
        },
        {
            title: 'Attachments',
            key: 'attachments',
            dataIndex: 'attachments',
            maxWidth: 100,
            render(value: any, record: any, index: any) {
                if (value) {
                    // return extractContent(value)
                    return (
                        // <a href={value.s3_link} target='_blank' style={{ whiteSpace: 'break-spaces' }}>{value.length > 0 ? value.map((val:any)=> val.docName).join("\r\n") : '--'}</a>
                        value.map((val: any) => <a key={val} href={val.s3_link} target='_blank' style={{ whiteSpace: 'break-spaces' }}>{val.docName || '--'}<br></br></a>)
                    )

                } else {
                    return '--'
                }

            },
        },
        {
            title: 'Status', dataIndex: 'notice_status', key: 'notice_status',
            render(value: any, record: any, index: any) {
                if (value === "No action required") {
                    return <Tag color='error' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >{value}</Tag>
                }
                if (value === "Action to be taken") {
                    return <Tag color='warning' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >{value}</Tag>
                }
                if (value === "Action taken") {
                    return <Tag color='success' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >{value}</Tag>
                }
            },
        },
        {
            title: 'Action', dataIndex: 'reply_details', key: 'reply_details',
            render(value: any, record: any, index: any) {
                if (value) {
                    return (
                        <>
                            <Tooltip placement="topLeft" title='Reply Details' arrow={true} color={'#424B70'} >
                                <ReplyIcon style={{ cursor: 'pointer', color: '#424B70', marginRight: '5px' }} onClick={() => { setReplymodal(true); setReplydetails(value) }} />
                            </Tooltip>
                            <Tooltip placement="topLeft" title='View Notice' arrow={true} color={'#E96919'} >
                                <VisibilityIcon style={{ cursor: 'pointer', color: '#E96919' }} onClick={() => { handleCellClick(record.id) }} />
                            </Tooltip>
                        </>
                    )

                } else {
                    return (
                        <>
                            <Tooltip placement="topLeft" title='View Notice' arrow={true} color={'#E96919'} >
                                <VisibilityIcon style={{ cursor: 'pointer', color: '#E96919' }} onClick={() => { handleCellClick(record.id) }} />
                            </Tooltip>
                        </>
                    )
                }
            },
        },
    ];
    // ............


    // button details props
    const buttondetails = [
        // {
        // 	type: 'syncall',
        // 	name: 'Sync All',
        // 	theme: 'theme3',
        // 	conditions: ['if_list'],
        // 	function: synccallfunction
        // },
        {
            type: 'download',
            name: 'Download',
            theme: 'theme4',
            conditions: ['if_list']
        }
    ]
    // ............

    // reply fields


    interface Attachment {
        docName: string;
        id: string;
        ct: string;
        s3_link: string;
    }

    interface ReplyField {
        name: string;
        value: string | Attachment[];
    }

    const reply_fields_list: ReplyField[] = [
        {
            name: 'Reply filed',
            value: replydetails && replydetails.reason
        },
        {
            name: 'Reply filed date',
            value: replydetails && replydetails.other_details ? replydetails.other_details.update_date : '--'
        },
        {
            name: 'Attachments',
            value: replydetails && replydetails.attachments
        },
    ]
    // ............

    return (
        <>
            <Modal
                title={"Reply Details"}
                onCancel={() => setReplymodal(false)}
                width={'35%'}
                className='action-popup status-chng-popup'
                centered
                style={{ color: "#031742" }}
                open={replymodal}
                onOk={update}
                footer={null}
            >
                <div className="reply-container">
                    {/* {reply_fields_list.map((task: any) => (
                        <div className='task-details-tile' key={task.name}>
                            <span className='details-tile-label'>
                                {task.name}
                            </span>
                            {task.name === 'Attachments' ? (
                                (task.value as Attachment[]).map((attachment, idx) => (
                                    <div className='details-attach-value link'>
                                        <a key={idx} href={attachment.s3_link} >
                                            {attachment.docName}
                                        </a><br></br>
                                    </div>
                                ))
                            ) : (
                                <span className='details-tile-value'>
                                    {task.value as string}
                                </span>
                            )}
                        </div>
                    ))} */}
                </div>
            </Modal>
            <Modal
                title={"Update Status"}
                onCancel={() => setStatuschange(false)}
                width={'20%'}
                className='action-popup status-chng-popup'
                centered
                style={{ color: "#031742" }}
                open={statuschange}
                onOk={update}
            >
                <div className="select-container">
                    <span className='heading'>New status</span>
                    <Select
                        className="dropdown-btn"
                        defaultValue="Select Action"
                        onChange={handlechange}
                        value={noticeStatus}
                        open={dropdownOpen}
                        onDropdownVisibleChange={(open) => setDropdownOpen(open)}

                        dropdownRender={menu => {

                            return (
                                <div>

                                    <Radio.Group onChange={onChange} value={noticeStatus} style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Radio value={'No action required'}>No action required</Radio>
                                        <Radio value={'Action to be taken'}>Action to be taken</Radio>
                                        <Radio value={'Action taken'}>Action taken</Radio>

                                    </Radio.Group>

                                </div>
                            )
                        }}
                        options={[

                            { value: 'No action required' },
                            { value: 'Action to be taken', },
                            { value: 'Action taken' },
                        ]}
                    />
                    {/* <Button className='save-btn' onClick={updateNotice}>SAVE</Button> */}
                </div>
            </Modal>
            <div id="notice-details-container" className='view-gst-info'>
                <div className="notice-upper-container">
                    {/* {
                        <div className="top-notice-container">
                            <div className="notice-left-container">
                                <Button onClick={() => { navigate(-1) }} className='back-nav'><ArrowBackIosNewIcon fontSize='small' className='icon' />{!isMobile && 'Back'}</Button>
                            </div>
                        </div>
                    } */}
                    {
                        <div className="mobile-top-notice-container">
                            <Button onClick={() => { navigate(-1) }} className='back-nav'><ArrowBackIosNewIcon fontSize='small' className='icon' />{!isMobile && 'Back'}</Button>
                        </div>
                    }
                    <div className="view-notice-main">
                        <div className='view-notice-header-wrap'>
                            <span className='content-group' >
                                <h4 className='main-line'>Trade Name</h4>
                                <p className='sub-line'>{viewsummarydetails.gst ? viewsummarydetails.gst[0].trade_name : '--'}</p>
                            </span>
                            <span className='content-group' >
                                <h4 className='main-line'>GSTIN</h4>
                                <p className='sub-line'>{viewsummarydetails.gst ? viewsummarydetails.gst[0].gst_no : '--'}</p>
                            </span>
                            <span className='content-group' >
                                <h4 className='main-line'>Case ID</h4>
                                <p className='sub-line'>{viewsummarydetails.case_arn_no ? viewsummarydetails.case_arn_no : '--'}</p>
                            </span>
                            {/* <span className='content-group' >
                                <h4 className='main-line'>Ref ID/ARN</h4>
                                <p className='sub-line'>{viewsummarydetails.case_ref_id ? viewsummarydetails.case_ref_id[viewsummarydetails.case_ref_id.length - 1] : '--'}</p>
                            </span> */}
                            <span className='content-group' >
                                <h4 className='main-line'>GST Portal Status</h4>
                                <p className='sub-line'>{viewsummarydetails.portal_status ? viewsummarydetails.portal_status : '--'}</p>
                            </span>
                        </div>
                        <ZTable2
                            label={'View Notice'}
                            ref={gstTableElement}
                            button={buttondetails}
                            columns={columns}
                            expand={false}
                            tabledata={noticeData}
                            loading={loading}
                            totalpages={totalpages} />
                    </div>
                </div>
                <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
            </div>
        </>
    )
}

