// react 
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// .........

// scss file
import './phpartners.scss';
// .........

// package components
import { Checkbox, DialogContent, DialogTitle, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { Button, Radio, RadioChangeEvent, Spin } from 'antd';
// .........

// child components
import { useGlobalState } from '../../Statecontext';
import { ZTable2 } from '../../components/table2';
import { serviceConfig } from '../../core/services';
// .........

// icons
import CloseIcon from '@mui/icons-material/Close';
import filterIcon from '../../assets/images/filter.svg';
import React from 'react';
import { NotifyAlert } from '../../components/notify_alert';
import Breadcrumbs from '../../shared/Breadcrumb';
// .........

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export const Phpartners = () => {

    // form values states
    const [usersList, setUsersList] = useState<any>([]);
    const [ph_partner_code, setPh_partner_code] = useState<any>('');
    const [task_id, setTask_id] = useState<any>('');
    const [task, setTasks] = React.useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [tasklist, setTasklist] = useState<any[]>([])
    // .........

    // data states

    // .........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    // .........

    // loading state
    const [loading, setLoading] = useState(false);
    const [optionloading, setoptionloading] = useState(false);
    const [poploading, setPoploading] = useState(false)
    // .........

    // limit & filter states
    const { searchquery, filterQuery, page, totalpages, setTotalpages, rowsPerPage, userdata, setUserdata, assignpopup, setAssignpopup, selectedGstRows } = useGlobalState();
    // .........

    // reference states
    const navigate = useNavigate()
    // .........

    // view data function
    const transformedTaskList = tasklist
        // .filter((task: any) => task.custom_fields?.cf_ph_partner_id === null)
        .map((task: any) => `${task.id} - ${task.type}`);
    // ............

    // data get function
    const loadUser = useCallback((search_data?: any) => {

        setLoading(true)
        let query: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage
        }

        if (searchquery) {
            query['search'] = searchquery;
        }

        query['approval_status'] = ['approved']

        if (userdata && userdata.is_cf === true) {
            query['cf_code'] = userdata.cf_code
        }

        serviceConfig.get("ph_register", true, query, null).then((data: any) => {
            setTotalpages(data.count)
            setUsersList(data.results || data.data)
            setLoading(false)
        }, (err: any) => {
            setLoading(false)
            if (err.message === "Request failed with status code 401") {
                navigate('/login')
                localStorage.clear()
                window.location.reload();
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, filterQuery, userdata, searchquery])

    useEffect(() => {
        loadUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterQuery, searchquery])

    useEffect(() => {
        if (assignpopup === true) {
            taskgetcall()
        }
    }, [assignpopup])
    // .........

    // radio selection 
    const handlechange = (event: SelectChangeEvent<typeof task>) => {
        const {
            target: { value },
        } = event;

        // Safeguard against undefined values
        const selectedTasks = typeof value === 'string' ? value.split(',') : value;

        setTasks(selectedTasks);

        // Safeguard for splitting the value for task_id
        const selectedTaskIds = selectedTasks.map((item: string) => {
            // Ensure the item exists and contains ' - ' before splitting
            return item && item.includes(' - ') ? item.split(' - ')[0] : item;
        });

        setTask_id(selectedTaskIds);
        setDropdownOpen(false);
    };
    // ...........

    // assignfuction
    const taskgetcall = () => {
        setoptionloading(true)
        serviceConfig.get("open_ticket", true, null, null).then((data: any) => {
            setTasklist(data.data.tickets)
            setoptionloading(false)
        }, (err: any) => {
            setoptionloading(false)
            if (err.message === "Request failed with status code 401") {
                navigate('/login')
                localStorage.clear()
                window.location.reload();
            }
        })
    }
    // ..........

    //  function Ph assign 
    const Assignpartner = () => {
        setPoploading(true)
        let body: any = {
            id: task_id,
            cf_ph_partner_id: ph_partner_code,
            cf_status_of_ph_task: "Assigned"
        }

        serviceConfig.put('update_ticket', true, body, null).then((res: any) => {
            if (res.message === 'Tickets assigned successfully.') {
                setNotifyOpen(true)
                setNotifyType('success')
                setNotifyMessage('Tickets assigned successfully.')
            } else if (res.message === 'Failed to update all tickets.') {
                setNotifyOpen(true)
                setNotifyType('error')
                setNotifyMessage('Failed to update ticket.')
            }
            setPoploading(false)
            setAssignpopup(false);
            setTasks([])
        }, (err: any) => {
            setNotifyType("error")
            setNotifyMessage(err.message)
            setNotifyOpen(true)
            setPoploading(false)
        }
        )
    }
    // ..........

    // selection search 
    const filteredTaskList = transformedTaskList.filter((name) =>
        name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };
    //   .......

    // table columns and filter requirement props
    const columns: any = [
        {
            title: 'S No.',
            dataIndex: '',
            render(value: any, record: any, index: any) {
                return (
                    <span>{index + 1}</span>
                )
            }
        },
        {
            title: 'PH Partner Name',
            dataIndex: 'full_name',
            // filters: userGSTMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'Organization Name',
            dataIndex: 'org_name',
            // filters: userStateMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'Pincode',
            dataIndex: 'pin_code',
            // filters: userTradeMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            // filters: userStateMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'PH office',
            dataIndex: 'city',
            // filters: userGSTMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'Depth of GST Practice',
            dataIndex: 'depth_gst',
            // filters: userTradeMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: 'PH Code',
            dataIndex: 'ph_code',
            // filters: userTradeMaster.map((item: any) => {
            // 	return {
            // 		text: item,
            // 		value: item,
            // 	};
            // }),
            // filterIcon: () => <img src={filterIcon} alt='filter icon' />,
            // filterDropdownOpen: gstList.length > 0,
            // filterSearch: true,
            render(value: any, record: any, index: any) {
                return value ? value : '--'
            },
        },
        {
            title: "Action",
            dataIndex: "sync_status",
            fixed: "right",
            render(value: any, record: any, index: any) {
                return (
                    <Button
                        id="z-common-gst-solution-button"
                        className='assign_btn'
                        onClick={() => { setAssignpopup(true); setPh_partner_code(record.ph_code) }}
                    >
                        Assign to Task
                    </Button>
                )
            }
        },
    ];
    // .........

    // button details props
    const buttondetails: any = []
    // .........


    return (
        <>
            <BootstrapDialog
                onClose={() => { setAssignpopup(false); setTasks([]) }}
                aria-labelledby="customized-dialog-title"
                open={assignpopup}
                id='assign_container'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="pwd">
                    Assign Tasks
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setAssignpopup(false); setTasks([]) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className='dialog-content' style={{ marginTop: '0%' }}>
                    <div className='assign-form-wrap'>
                        <div className="assign-select-container">
                            <FormControl className='dropdown-btn' >
                                <InputLabel id="demo-multiple-checkbox-label">Task</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={task}
                                    onChange={handlechange}
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => (selected as string[]).join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem>
                                        <TextField
                                            autoFocus
                                            placeholder="Search..."
                                            fullWidth
                                            onChange={handleSearchChange}
                                            value={searchTerm}
                                        />
                                    </MenuItem>
                                    {filteredTaskList.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={task.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                    {filteredTaskList.length === 0 && optionloading &&
                                        <MenuItem disabled>
                                            Loading
                                        </MenuItem>}
                                </Select>
                            </FormControl>
                        </div>
                        <Button
                            id="z-common-gst-solution-button"
                            className='theme2'
                            onClick={Assignpartner}
                            disabled={poploading || task.length === 0}
                        >
                            Assign
                        </Button>
                        {poploading &&
                            <Spin spinning={poploading} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute', marginTop: '10vh' }}>
                            </Spin>}
                    </div>
                </DialogContent>
            </BootstrapDialog >
            <div className='users-approval-container'>
                <div style={{ marginTop: '1%' }}>
                    <Breadcrumbs />
                </div>
                <ZTable2 label={'PH Partners'} button={buttondetails} columns={columns} expand={false} hideSelect={true} loading={loading} tabledata={usersList} totalpages={totalpages} />
            </div>
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}