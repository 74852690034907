// react 
import { useRef, useState } from "react";
// ..........

// child components
import { serviceConfig } from "../services";
import { useGlobalState } from "../../Statecontext";
// ..........

// package components
// ........

export const Utils = (selectedGST?: any, selectedNotice?: any) => {

    //  datas states
    const [syncIds, setSyncIds] = useState<any[]>([])
    // ..........

    // alerts and modal states
    // ..........

    // limit & filter states
    const { setDashbordarticle, setIsManagegst, setOverviewDetails, setSorterQuery, setSearchquery, setPage, setTotalpages, setManageactive, setTabview, setSumpopup, setSumdrawer, setRowsPerPage, setChangeview, setChartquery, filterQuery, setFilterQuery, syncedCount, setSyncedCount, setGstSyncModal, syncProcess, setSyncProcess } = useGlobalState();
    // ..........

    // reference states
    const syncProgress = useRef(false)
    const headerMasterLoading = useRef(false)
    // ..........

    // sync init function
    const handlesync = (selectedGST?: any, clear?: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            if (!syncProgress.current) {
                syncProgress.current = true;
                let difference = selectedGST.map((gst: any) => gst.gst ? gst.gst.id : gst.id)
                    .filter((x: any) => !syncIds.includes(x))
                    .concat(syncIds.filter(x => !selectedGST.map((gst: any) => gst.gst ? gst.gst.id : gst.id).includes(x)));
                if (!syncProcess || difference.length > 0) {
                    setSyncProcess(true);
                    syncProgress.current = true;
                    let query: any = { 'clear': clear ? true : false };
                    if (selectedGST.length > 0) {
                        setSyncIds([...syncIds, selectedGST.map((gst: any) => gst.gst ? gst.gst.id : gst.id)])
                        query['gst_no'] = selectedGST.map((gst: any) => gst.gst ? gst.gst.id : gst.id).join(',')
                    }
                    // Get trades
                    serviceConfig.post("gst_sync", true, query, null).then((data: any) => {
                        setFilterQuery(filterQuery)
                        syncProgress.current = false;
                        resolve(data);
                    }, (err: any) => {
                        syncProgress.current = false;
                        reject(err);
                    })
                } else {
                    // setGstSyncModal(true)
                }
            }
        });
    }
    // ..........

    // sync count get function
    const getSyncCount = () => {
        headerMasterLoading.current = true
        serviceConfig.get('get_sync_counts', true, null, null).then((data: any) => {
            setSyncedCount(data.data.sync_count)
            localStorage.setItem('syncedCount', data.data.sync_count)
            document.dispatchEvent(new Event("storage"));
        }, (err: any) => {

        })
    }
    // ..........

    //notice solution ticket init function
    const ticket = (selectedNotice?: any, noticeData?: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            serviceConfig.get("raise_ticket", true, { 'notices': selectedNotice && selectedNotice.map((not: any) => not.id).join(',') || noticeData }, null).then((data: any) => {
                setFilterQuery(filterQuery)
                resolve(data);
            }, (err: any) => {
                reject(err);
            })
        })
    }

    const gst_ticket = (selectedNotice?: any, noticeData?: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            serviceConfig.get("gst_raise_ticket", true, { 'gst_no': selectedNotice && selectedNotice.map((not: any) => not.gst.gst_no).join(',') || noticeData }, null).then((data: any) => {
                setFilterQuery(filterQuery)
                resolve(data);
            }, (err: any) => {
                reject(err);
            })
        })
    }

    const case_ticket = (selectedNotice?: any, noticeData?: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            serviceConfig.get("case_raise_ticket", true, { 'case_arn_no': selectedNotice && selectedNotice.map((not: any) => not.case_arn_no).join(',') || noticeData }, null).then((data: any) => {
                setFilterQuery(filterQuery)
                resolve(data);
            }, (err: any) => {
                reject(err);
            })
        })
    }
    // ..........

    // update solution notice function
    const updateNotice = (id?: any, noticeStatus?: any, amount?: any, remarks?: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            let query: any = {
                id: id,
            }
            if (noticeStatus) {
                query['notice_status'] = noticeStatus;
            }
            if (amount) {
                query['tax_amount'] = amount;
            }
            if (remarks) {
                query['remarks'] = remarks;
            }

            serviceConfig.put("get_notices", true, query, null)
                .then((data: any) => {
                    resolve(data)
                }, (err: any) => {
                    reject(err)
                })
        })
    }
    // ..........

    // update solution notice function
    const MultiNoticeUpdate = (id?: any, noticeStatus?: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            serviceConfig.put("multi_status_update", true, { 'notice_ids': id, 'notice_status': noticeStatus }, null)
                .then((data: any) => {
                    resolve(data)
                }, (err: any) => {
                    reject(err)
                })
        })
    }
    // ..........

    // get last sync details of user
    const getLastSyncStatus = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            serviceConfig.get("gst_sync", true, { 'last': true }, null)
                .then((data: any) => {
                    resolve(data)
                }, (err: any) => {
                    reject(err)
                })
        })
    }
    // ...........

    // menu click function
    const menuClick = (button?: any) => {
        setDashbordarticle({});
        setChangeview(false)
        setIsManagegst(false)
        setManageactive('Active')
        setFilterQuery({});
        setSorterQuery({})
        setSearchquery('')
        setPage(1);
        setTotalpages(0);
        setRowsPerPage(10);
        setTabview(false)
        setOverviewDetails({ gst_no: '', trade_name: '' });
        setChartquery(false)
        setSumdrawer(false);
        setSumpopup(false)
    }
    // ........

    // // Decryption function
    // const decryptData = (ciphertext: string): Promise<any> => {
    //     const secretKey: any = 'quqQNwrZXzj-UvI-3DrKN010s1rZYbqBY5w6M54QDq8=';
    //     const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    //     const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    //     return new Promise((resolve, reject) => {
    //         resolve(decryptedData)
    //     })
    // };
    // ........

    return (
        { handlesync, ticket, gst_ticket, case_ticket, syncedCount, setSyncedCount, getSyncCount, updateNotice, MultiNoticeUpdate, getLastSyncStatus, menuClick }
    )
}